<template>
<div>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> レース一覧  {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}
    </li>
  </ul>
</nav>

<div class="racelist-header">
<span class="prev-container">
<router-link class="prev-dayrace" :to="{name:'DayRaceList',params:{date:printDateStr(prev_date_obj)}}">{{ printDateStr(prev_date_obj).substr(0,4)+'年' + printDateStr(prev_date_obj).substr(4,2)+'月'+ printDateStr(prev_date_obj).substr(6,2)+'日'}}</router-link>
</span>
    <h1 class="racelist-h1">
      <div class="h-date">{{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</div>  レース一覧
    </h1>
<span class="next-container">
<router-link v-if="nextday_ready" class="next-dayrace" :to="{name:'DayRaceList',params:{date:printDateStr(next_date_obj)}}">{{ printDateStr(next_date_obj).substr(0,4)+'年' + printDateStr(next_date_obj).substr(4,2)+'月'+ printDateStr(next_date_obj).substr(6,2)+'日'}}</router-link>
</span>
</div>
<div class="table-scroll">
<table class="shussou-table racelist-table">
  <thead>
      <tr>
      <th>レース場</th>
      <th>グレード</th>
      <th></th>
      <th class="ktitl">タイトル</th>
      <th>日次</th>
      </tr>
  </thead>
  <tbody>
      <tr v-for="rh in headers" :key="rh.jcd">
      <td class="jname">
          <router-link :to="{name:'JoDateIndex',params: {jo:rh.jcd,date:date} }">{{ rh.jname }}</router-link>
          <span v-if="(rh.jcd == 20 && date == '20210811') || (rh.jcd == 8 && date == '20210823') || (rh.jcd == 21 && date == '20210830') || (rh.jcd == 14 && date == '20210918') || (rh.jcd == 3 && date == '20211001')  || (rh.jcd == 1 && date == '20211024') ||
         /* (rh.jcd == 16 && date == '20220918') || */ (rh.jcd == 20 && date == '20220918') || (rh.jcd == 22 && date == '20220918') ||  (rh.jcd == 23 && date == '20220918') ||
          (rh.jcd == 7 && date == '20220919') || (rh.jcd == 9 && date == '20220919') || (rh.jcd == 12 && date == '20220919') ||  (rh.jcd == 13 && date == '20220919') ||
          (rh.jcd == 14 && date == '20220919') || (rh.jcd == 16 && date == '20220919') || (rh.jcd == 20 && date == '20220919') ||  (rh.jcd == 22 && date == '20220919') ||
          (rh.jcd == 23 && date == '20220919')  " class="cancel">中止</span>
      </td>
      <td>
           <span class="grade-label" :class="'g-'+rh.gcd">{{ rh.grade }}</span>
      </td>
      <td>
       <span v-if="isLady(rh)" class="f-hearts-icon" :class="'lgrade-'+rh.gcd"></span>
       <span v-if="rh.nflg > 0" class="n-icon" :class="'type-'+rh.nflg"></span>
     </td>
      <td class="ktitl">
           <router-link :to="{name:'JoDateIndex',params: {jo:rh.jcd,date:date} }">{{ rh.ktitl }}</router-link>
     </td>
     <td>
         {{ formatNj(rh) }}
     </td>
     </tr>
  </tbody>
</table>
</div>
<div class="icon-info">
  <span class="icon-info-item"><span class="emoji-icon"><img class="icon-pict" src="/images/icon_nighter.png" alt="" /></span>ナイター</span>
  <span class="icon-info-item"><span class="emoji-icon"><img class="icon-pict" src="/images/icon_midnight.png" alt="" /></span>ミッドナイト</span>
  <span class="icon-info-item"><span class="emoji-icon"><img class="icon-pict" src="/images/icon_morning.png" alt="" /></span>モーニング</span>
  <span class="icon-info-item"><span class="emoji-icon"><img class="icon-pict" src="/images/icon_summertime.png" alt="" /></span>サマータイム</span>
  <span class="icon-info-item"><span class="c-icon lgrade-6"><img src="/images/icon_alllady.png" alt="" class="icon-pict" /></span>オールレディース</span>
  <span class="icon-info-item"><span class="c-icon lgrade-8"><img src="/images/icon_venus.png" alt="" class="icon-pict" /></span>ヴィーナスシリーズ</span>
</div>
</div>
</template>
<script>
import JO_ID_NAMES from '../mixins/PrefsMixin'
import API_BASE_URL from '../mixins/PrefsMixin'
import pianoSend from '../mixins/PrefsMixin'

export default {
  name: 'DayRaceList',
  mixins: [ JO_ID_NAMES,API_BASE_URL,pianoSend],
  data:function(){
    return {
      date:'',
      prev_date_obj:{},
      next_date_obj:{},
      title:'',
      headers:[],
      jolist:[],
      tommrow:new Date(),
      today:new Date(),
      source:{},
      nextday_ready:false,
      prevday_ready:true
    }
  },
  beforeRouteUpdate(_to, _from, _next) {
    if(_to.name == 'DayRaceList'){
      this.$data.date = _to.params['date'];
      this.$data.title = this.$data.date + '- レース一覧';
 
      this.$data.jolist = [];
      this.$data.headers = [];

  

    let next_date  = new Date(this.$data.date.substr(0,4),Number((this.$data.date.substr(4,2)))-1,this.$data.date.substr(6,2));
    let prev_date  = new Date(this.$data.date.substr(0,4),Number((this.$data.date.substr(4,2)))-1,this.$data.date.substr(6,2));
    next_date.setDate(next_date.getDate()+1);
    prev_date.setDate(prev_date.getDate()-1);
    this.$data.next_date_obj = next_date;
    this.$data.prev_date_obj = prev_date;

    this.pianoSend('レース一覧',this.$data.date);

    this.$data.source.cancel();
    this.$data.source = this.axios.CancelToken.source()

    this.changeDate();
    }
    _next();
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created:function(){
    this.$data.date = this.$route.params['date'];
    this.$data.title = this.$data.date + '- レース一覧';
    this.$data.headers = [];
    this.$emit('updateHead');

    let next_date  = new Date(this.$data.date.substr(0,4),Number((this.$data.date.substr(4,2)))-1,this.$data.date.substr(6,2));
    let prev_date  = new Date(this.$data.date.substr(0,4),Number((this.$data.date.substr(4,2)))-1,this.$data.date.substr(6,2));
    next_date.setDate(next_date.getDate()+1);
    prev_date.setDate(prev_date.getDate()-1);
    this.$data.next_date_obj = next_date;
    this.$data.prev_date_obj = prev_date;
  
    this.pianoSend('レース一覧',this.$data.date);
    this.$data.source = this.axios.CancelToken.source();
    this.getData();
  },

  methods: {
    formatNj(rh){

         if(rh.nj==1){
             return '初日';
         }

         if(rh['nidate'+(rh.nj+1)] == null){
               return '最終日';
         }

         return rh.nj +'日目';
    },
    printDateStr(dateObj){
      
      let datestr = dateObj.getFullYear();
      datestr += ('00' + Number(dateObj.getMonth()+1)).slice(-2);
      datestr += ('00' + dateObj.getDate()).slice(-2);
      
      return datestr
    },

    changeDate:function(){
      this.getData();
    },
    isLady(rh){
     
  
     let gcd = rh.gcd;
     let lgrade = rh.lgrade;
      
      if(gcd == 5 || gcd == 6 || gcd == 8 || lgrade == 'JO'){
        return true;
      }
     
      return false;
    },
    getProp(object,propertyPath){
      if (!object) { return undefined }
      let result = object;
      const propertyArray = propertyPath.split('.');
      for (let i = 0; i <= propertyArray.length - 1; i += 1) {
        if (propertyArray[i] === '' ) { return undefined; }
        if (typeof result[propertyArray[i]] === 'undefined') { return undefined; }
        result = result[propertyArray[i]];
      }
      return result;
    },
    getData() {
      
        let today_racelist_url  =  this.API_BASE_URL + 'kaisailist/' + this.$data.date ;
        
        
          this.axios.get(today_racelist_url ,{cancelToken: this.$data.source.token})
          .then((response) => {
          
              this.$data.headers = response.data.headers;
              

              response.data.headers.sort((a,b)=>{
                const grades = ["ＳＧ","ＧⅠ","ＧⅡ","ＧⅢ","一般"];
                let a_grade = grades.indexOf(a.grade);
                let b_grade = grades.indexOf(b.grade);

                if(a_grade > b_grade ){
                
                  return 1;

                }else if(a_grade == b_grade){
                 if(a.jcd > b.jcd){
                   return 1;
                 }else{
                   return -1;
                 }
                }else{
                  return -1;
                }

              });

            this.$data.nextday_ready = response.data.nextday_ready;
         
            
          })
          .catch((e) => { // eslint-disable-line
           
            this.$router.replace({ name:'NotFound'});
          });

        }
      }
}
</script>
<style lang="scss" scoped>
td.jname,td.ktitl{
    font-weight: bold;
    text-decoration: underline;
    height:48px;
}
.ktitl{
    text-align: left;
}
.racelist-header{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width:800px) { 
    padding:10px;
    grid-template-columns: repeat(2, 1fr);
    .prev-dayrace{
      vertical-align: baseline;
    }

    .racelist-h1{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
      padding-top:15px;
    }

    .next-dayrace{
      vertical-align: baseline;
    }
  }
}
.racelist-h1{
  //display: inline-block;
  text-align: center;
}
.prev-dayrace{
  &::before{
    display:inline-block;
    font-size:13px;
    content:'◀︎';
    background-color:#1579ad;
    color:#FFF;
   width:18px;
   height:18px;
   line-height: 18px;
    text-align: center;
    border-radius: 9px;
    vertical-align: text-top;
    margin-right: 3px;
    
  }
}
.next-container{
    text-align: right;
}
.next-dayrace{

   &::after{
   display:inline-block;
   content:'▶';
   font-size:13px;
   background-color:#1579ad;
   color:#FFF;
   width:18px;
   height:18px;
   text-align: center;
   line-height: 18px;
    border-radius: 9px;
    vertical-align: text-top;
    margin-left: 3px; 
  }
}
.jname{
  position: relative;
}
.jname .cancel{
  display:inline-block;
  font-size:8px;
  color:#FFF;
  background-color:#f53b1a;
  margin-right:5px;
    -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  left:0;
  top:0;
  height: 100%;
}

.n-icon.type-1::before,
.n-icon.type-2::before,
.n-icon.type-3::before,
.n-icon.type-4::before{
@media (max-width: 480px) {
    width:14px;
    height:14px;
  }
}


.f-hearts-icon{
  width:14px;
  height:14px;
  @media (max-width: 480px) {
    width:12px;
    height:12px;
  }
}
</style>