import { Radar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default{
  extends: Radar,
  props:['chartData','options'],
  plugins: [ChartDataLabels],
  mounted () {
    this.renderChart(
    this.chartData,
     {
      scale: {
        angleLines: {
            display: false,
        },
        title:{
          display:false
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 5,
            stepSize:1.0,
            display:false
            
        },
  
      },
      plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
          font: {
            size: 16,
            weight:'bold',
        }
        }
      }
     }
    )
  }
}
