import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios' 
import VueHead from 'vue-head'
import VueChart from 'vue-chartjs'

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import cognito from './cognito'
import store from './store'
import Ads from 'vue-google-adsense'




Vue.config.productionTip = false
Vue.use(VueAxios,axios)
Vue.use(VueHead)
Vue.use(VueChart)
Vue.use(Loading)
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
//Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-3076785709839281' })

new Vue({
  router,
  cognito,
  store,
  render: h => h(App),
}).$mount('#app');
