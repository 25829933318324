<template>
<div>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li><router-link :to="{name:'DayRaceList',params:{date:date}}"> レース一覧 {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> {{ jo_name }}
    </li>
  </ul>
</nav>
  <nav class="jo-date-nav">
    <ul class="menu jo-date-menu" v-if="nidate_length > 0" >
      <li v-for="ni of nidate_length" :key='ni' >
        <span v-if="header.nj == ni"><span class="current button">{{  Number(header['nidate'+ni].substr(5,2))+'月'+  Number(header['nidate'+ni].substr(8,2))+'日'  }}</span></span>
        <span v-else-if="header['nidate'+ni+'_ready']"><router-link class="button" :to="{name:'JoDateIndex',params:{jo:jo,date:header['nidate'+ni].replace(/\-/g,'')}}">{{  Number(header['nidate'+ni].substr(5,2))+'月'+  Number(header['nidate'+ni].substr(8,2))+'日'  }}</router-link></span>
        <span v-else><span class="button" disabled>{{  Number(header['nidate'+ni].substr(5,2))+'月'+  Number(header['nidate'+ni].substr(8,2))+'日'  }}</span></span>
     </li>
    </ul>
  </nav>
<div class="racelist-header">

    <h1 class="racelist-h1">

      レース一覧 <div class="h-date">{{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</div>
      <div class="jo-kaisai"><span v-if="(this.$data.jo == 20 && date == '20210811') || (this.$data.jo == 8 && date == '20210823') || (this.$data.jo == 21 && date == '20210830') 
      || (this.$data.jo == 14 && date == '20210918') || (this.$data.jo == 3 && date == '20211001') || (this.$data.jo == 1 && date == '20211024')" class="cancel">中止</span><span class="jo-name">{{ jo_name }}</span> <span class="grade-label" :class="'g-'+ header.gcd">{{ header.grade }}</span>  {{ header.ktitl }}
      <div class="icons" v-if="header.nflg > 0 || isLady()">
      <span v-if="isLady()" class="f-hearts-icon" :class="'lgrade-'+header.gcd"></span>
      <span v-if="header.nflg > 0" class="n-icon" :class="'type-'+header.nflg"></span>
      </div>
      </div>
      
    </h1>
</div>
<div class="table-scroll">
<table class="shussou-table racelist-table">
  <thead>
    <tr>
      <th class="race" rowspan="2">レース</th>
      <th rowspan="2">締切時刻</th>
      <!--
      <th rowspan="2">出走時刻</th>
      -->
      <th colspan="6">出場レーサー</th>
      <th rowspan="2"></th>
    </tr>
    <tr>
      <th class="waku-1">1</th>
      <th class="waku-2">2</th>
      <th class="waku-3">3</th>
      <th class="waku-4">4</th>
      <th class="waku-5">5</th>
      <th class="waku-6">6</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(race,index) of racelist" :key="race.rno">
      <td class="race">
         <router-link :to="{name:'Shussou',params:{date:date,jo:jo,race:race.rno}}">{{ race.rno }}R</router-link>
      </td>
      <td>{{ race.stime.slice(11,16)  }}</td>
      <!--
      <td>{{ race.htime.slice(11,16) }}</td>
      -->
      <td v-for="sid in 6" :key="sid" >
        <router-link class="profile-link" :to="{name:'RacerProfile',params:{toban:race['toban'+sid],tabname:'kibetsu'}}">   {{ race['name'+sid] }}</router-link>
      <br />
        <span class="sub-item">{{ race['toban'+sid] }} /  {{ race['kyu'+sid] }}</span>
      </td>
      <td>
        <router-link class="button" :to="{name:'Shussou',params:{date:date,jo:jo,race:race.rno}}">出走表</router-link>
        <router-link class="button" v-if="race.tyoku.substr(index,1) == '1'" :to="{name:'BeforeInfo',params:{date:date,jo:jo,race:race.rno}}">直前データ</router-link>
        <router-link class="button" v-if="race.result == 1" :to="{name:'Result',params:{date:date,jo:jo,race:race.rno}}">結果</router-link>
      </td>

    </tr>
  </tbody>
</table>
</div>
<div class="icon-info">
  <span class="icon-info-item"><span class="emoji-icon"><img src="/images/icon_nighter.png" alt="" class="icon-pict" /></span>ナイター</span>
  <span class="icon-info-item"><span class="emoji-icon"><img src="/images/icon_midnight.png" alt="" class="icon-pict" /></span>ミッドナイト</span>
  <span class="icon-info-item"><span class="emoji-icon"><img src="/images/icon_morning.png" alt=""  class="icon-pict"  /></span>モーニング</span>
  <span class="icon-info-item"><span class="emoji-icon"><img src="/images/icon_summertime.png" alt=""  class="icon-pict" /></span>サマータイム</span>
  <span class="icon-info-item"><span class="c-icon lgrade-6"><img src="/images/icon_alllady.png" alt="" class="icon-pict" /></span>オールレディース</span>
  <span class="icon-info-item"><span class="c-icon lgrade-8"><img src="/images/icon_venus.png" alt="" class="icon-pict" /></span>ヴィーナスシリーズ</span>
</div>
</div>

</template>
<script>
import JO_ID_NAMES from '../mixins/PrefsMixin'
import API_BASE_URL from '../mixins/PrefsMixin'
import pianoSend from '../mixins/PrefsMixin'

export default {
  name: 'JoDateIndex',
  mixins: [ JO_ID_NAMES,API_BASE_URL,pianoSend],
  data:function(){
    return {
      date:'',
      jo:'',
      jo_name:'',
      title:'',
      header:'',
      nidate_length:0,
      racelist:[],
      tommrow:new Date(),
      today:new Date()
    }
  },
  beforeRouteUpdate(_to, _from, _next) {
    if(_to.name == 'JoDateIndex'){
      this.$data.date = _to.params['date'];
      this.$data.jo = _to.params['jo'];
      this.$data.jo_name = this.getProp(this.JO_ID_NAMES[Number(this.$data.jo) - 1],'name');
      this.$data.racelist = [];
      this.$data.nidate_length = 0;
      this.changeJo();
    }
    _next();
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created:function(){
    this.$data.date = this.$route.params['date'];
    this.$data.jo = this.$route.params['jo'];
    this.jo_name = this.getProp(this.JO_ID_NAMES[Number(this.$data.jo) - 1],'name');
    this.$data.title = 'レース一覧 - '+ this.$data.jo_name +' '+ this.$data.date;
    this.$emit('updateHead');


    //this.$data.tommrow.setDate(this.$data.tommrow.getDate() + 1);
    this.getData();
  },
  methods: {
    changeJo:function(){
      this.getData();
    },
    getProp(object,propertyPath){
      if (!object) { return undefined }
      let result = object;
      const propertyArray = propertyPath.split('.');
      for (let i = 0; i <= propertyArray.length - 1; i += 1) {
        if (propertyArray[i] === '' ) { return undefined; }
        if (typeof result[propertyArray[i]] === 'undefined') { return undefined; }
        result = result[propertyArray[i]];
      }
      return result;
    },
    isLady(){
     
     if(this.$data.header){
     let gcd = this.$data.header['gcd'];
     let lgrade = this.$data.header['lgrade'];
      if(gcd == 5 || gcd == 6 || gcd == 8 || lgrade == 'JO'){
        return true;
      }
     }
      return false;
    },
    getData() {
      this.$data.nidate_length = 0;
        let today_racelist_url =  this.API_BASE_URL + 'racelist/' + this.$data.date +'/'+ this.$data.jo;
          this.axios.get(today_racelist_url)
          .then((response) => {
            
            this.$data.racelist = response.data.racelist;
            this.$data.header = response.data.header;
            //console.log(response.data);
             this.pianoSend('レース一覧',this.$data.jo_name,this.$data.date,this.$data.header.ktitl);

            for(let i=1;i<=9;i++){
              if(this.$data.header && this.$data.header['nidate'+i]){
                this.$data.nidate_length = i;
              }
            } 
            

          })
          .catch((e) => { // eslint-disable-line
            
            this.$router.replace({ name:'NotFound'});
          });
        }
      }
}
</script>

<style lang="scss" scoped>

.profile-link{
  font-weight: normal;

  
}
.jo-kaisai .icons{
  display: inline-block;
  background-color: #F0F8FF;
  padding:5px 20px;
}
.jo-kaisai .icons .f-hearts-icon{
  font-size:18px;
  width:18px;
  height:18px;
  
  @media (max-width: 480px) {
    width:16px;
    height:16px;
  }
}
.jo-kaisai{
  position: relative;
}
.cancel{
  display:inline-block;

  color:#FFF;
  background-color:#f53b1a;
 
      border-radius: 3px;
    font-size: 1.125rem;
    padding: 0.3125rem !important;
    font-weight: bold;
}

.n-icon.type-1::before,
.n-icon.type-2::before,
.n-icon.type-3::before,
.n-icon.type-4::before{
  width:21px;
  height:21px;
  vertical-align: middle;
  @media (max-width: 480px) {
    width:18px;
    height:18px;
  }
}
</style>