
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from 'vue-gtag'
import store from '../store'
import cognito from '../cognito'

import Index from '../views/Index.vue'

import JoDateIndex from '../views/JoDateIndex.vue'
import Shussou from '../views/Shussou.vue'
import BeforeInfo from '../views/BeforeInfo.vue'
import Result from '../views/Result.vue'
import HochiScope from '../views/HochiScope.vue'
import DayRaceList from '../views/DayRaceList.vue'
import Odds from '../views/Odds.vue'

import RacerProfile from '../views/RacerProfile.vue'
import RacerRanking from '../views/RacerRanking.vue'

import UserRegister from '../views/UserRegister.vue'
import UserConfirm from '../views/UserConfirm.vue'
import UserLogin from '../views/UserLogin.vue'
import UserMyPage from '../views/UserMyPage.vue'
import UserForgetPassword from '../views/UserForgetPassword.vue'
import UserFChPassword from '../views/UserFChPassword.vue'
import UserChEmail from '../views/UserChEmail.vue'
import UserConfirmChEmail from '../views/UserConfirmChEmail.vue'
import UserChAttr from '../views/UserChAttr.vue'
import UserChPassword from '../views/UserChPassword.vue'

import HowToPage from '../views/HowToPage.vue'
import UserdataExternalTransmissionPage from '../views/UserdataExternalTransmissionPage.vue'
import KiyakuPage from '../views/KiyakuPage.vue'


import Article from '../views/Article';
import Information from '../views/Information';

import SpecialBiwako from '../views/SpecialBiwako';
import SpecialMarugame from '../views/SpecialMarugame';
import SpecialMarugameMemorial70 from '../views/SpecialMarugameMemorial70';
import SpecialBiwakoG1 from '../views/SpecialBiwakoG1';
import SpecialBiwako202306 from '../views/SpecialBiwako202306';
import SpecialBiwako202310 from '../views/SpecialBiwako202310';
import SpecialBiwako202402 from '../views/SpecialBiwako202402';
import SpecialBiwako202409 from '../views/SpecialBiwako202409';
import SpecialTokuyama from '../views/SpecialTokuyama';

import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)


const routes = [
  {
    name: 'Index',
    path: '/',
    component:Index 
  },
  {
    name: 'DayRaceList',
    path: '/day_race_list/:date',
    component: DayRaceList
  },
  {
    name: 'JoDateIndex',
    path: '/jo/:jo/:date',
    component: JoDateIndex
  },
  {
    name: 'Shussou',
    path: '/shussou/:date/:jo/:race',
    component: Shussou
  },
  {
    name: 'HochiScope',
    path: '/hochi_scope/:date/:jo/:race',
    component: HochiScope 
  },
  {
    name: 'BeforeInfo',
    path: '/before_info/:date/:jo/:race',
    component: BeforeInfo
  },
  {
    name: 'Odds',
    path: '/odds/:date/:jo/:race/:tabname',
    component: Odds
  },
  {
    name: 'Result',
    path: '/result/:date/:jo/:race',
    component: Result
  },
  {
    name:'RacerProfile',
    path: '/racer/:toban/:tabname',
    component: RacerProfile
  },
  {
    name:'RacerRanking',
    path: '/racerranking/:kbn',
    component: RacerRanking
  },
  {
    name:'UserRegister',
    path:'/register',
    component: UserRegister
  },
  {
    name:'UserConfirm',
    path:'/register_confirm',
    component: UserConfirm,
  },
  {
    name:'UserLogin',
    path:'/login',
    component: UserLogin,
  },
  {
    name:'UserMyPage',
    path:'/user',
    component: UserMyPage,
    meta: { requiredAuth: true }
  },
  {
    name:'UserForgetPassword',
    path:'/forget_password',
    component: UserForgetPassword
  },
  {
    name:'UserFChPassword',
    path:'/fch_password',
    component: UserFChPassword
  },
  {
    name:'UserChEmail',
    path:'/ch_email',
    component: UserChEmail,
    meta: { requiredAuth: true }
  },
  {
    name:'UserConfirmChEmail',
    path:'/confirm_ch_email',
    component: UserConfirmChEmail,
    meta: { requiredAuth: true }
  },
  {
    name:'UserChAttr',
    path:'/ch_profile',
    component: UserChAttr,
    meta: { requiredAuth: true }
  },
  {
    name:'UserChPassword',
    path:'/ch_password',
    component: UserChPassword,
    meta: { requiredAuth: true }
  },
  {
    name: 'HowToPage',
    path: '/howto',
    component: HowToPage
  },
  {
    name: 'KiyakuPage',
    path: '/kiyaku',
    component: KiyakuPage
  },
  {
    name: 'UserdataExternalTransmissionPage',
    path: '/userdata_external_transmission',
    component: UserdataExternalTransmissionPage
  },
  {
    name: 'Article',
    path: '/article/:id',
    component: Article
  },
  {
    name: 'SpecialBiwako',
    path: '/special/biwako/:id',
    component: SpecialBiwako
  },
  {
    name: 'SpecialMarugame',
    path: '/special/marugame/:id',
    component: SpecialMarugame
  },
  {
    name: 'SpecialMarugameMemorial70',
    path: '/special/marugame202408/:id',
    component: SpecialMarugameMemorial70
  },
  {
    name: 'SpecialBiwakoG1',
    path: '/special/biwako_g1/:id',
    component: SpecialBiwakoG1
  },
  {
    name: 'SpecialBiwako202306',
    path: '/special/biwako202306/:id',
    component: SpecialBiwako202306
  },
  {
    name: 'SpecialBiwako202310',
    path: '/special/biwako202310/:id',
    component: SpecialBiwako202310
  },
  {
    name: 'SpecialBiwako202402',
    path: '/special/biwako202402/:id',
    component: SpecialBiwako202402
  },
  {
    name: 'SpecialBiwako202409',
    path: '/special/biwako202409/:id',
    component: SpecialBiwako202409
  },
  {
    name: 'SpecialTokuyama',
    path: '/special/tokuyama/:id',
    component: SpecialTokuyama
  },

  {
    name: 'Information',
    path: '/information/:id',
    component: Information
  },
  {
    path: '/blog',
    name: 'external_blog',
    beforeEnter() {
      window.location = location.href+"/"
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router.beforeEach((to, from, next) => {
//   if(to.name !== 'UserLogin'){

//   }else{
//     next();
//   }
// })

//router.push('/').catch(err => {console.log(err)})

Vue.use(VueGtag, {
  config: {id:'G-KCGR4M5C6Y'}
}, router);

router.beforeResolve(async(to, from, next) => {
  // Get signin session.

  cognito.isAuthenticated()
    .then(session => { // eslint-disable-line
      const token = session.idToken.jwtToken
      // get attribute.
      cognito.getAttribute()
        .then(result => {
          var user = {}
          for(var v of result) {
            user[v.getName()] = v.getValue()
          }
          user['token'] = token
          store.commit('setUser', user)
        })
        .catch(error => {  // eslint-disable-line
          store.commit('setUser', null)
         
          //signout(to, from, next)
        })
      // if (to.matched.some(record => record.meta.notRequiredAuth)) {
      //   next({
      //     path: '/',
      //   })
      // }
    })
    .catch(error => { // eslint-disable-line
      store.commit('setUser', null)
      if (to.matched.some(record => record.meta.requiredAuth)) {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    })
  next()
})

export default router