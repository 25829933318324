var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),(_vm.information_list != null && Array.isArray(_vm.information_list) && _vm.information_list.length > 0)?_c('div',{staticClass:"information-list-wrapper"},[_c('h2',[_vm._v("お知らせ")]),_c('ul',{staticClass:"information-list"},_vm._l((_vm.information_list),function(item){return _c('li',{key:item.id},[_c('router-link',{class:item.important?'important':'',attrs:{"to":{name:'Information',params:{id:item.id}}}},[_vm._v(" "+_vm._s(item.title)+" "),_c('time',[_vm._v("("+_vm._s(item.datetime)+")")])])],1)}),0)]):_vm._e(),_c('div',{staticClass:"parts-container"},[_c('div',{staticClass:"day-jo-list-header"},[_c('span',{staticClass:"prev-container"},[_c('a',{staticClass:"prev-dayrace",on:{"click":function($event){return _vm.changeRaceDate(_vm.prev_date_str)}}},[_vm._v(_vm._s(_vm.prev_date_str.substr(0,4)+'年' + _vm.prev_date_str.substr(4,2)+'月'+ _vm.prev_date_str.substr(6,2)+'日'))])]),_c('h2',{staticClass:"parts-title day-jo-list-title"},[_vm._v(_vm._s(_vm.date_str.substr(0,4)+'年' + _vm.date_str.substr(4,2)+'月'+ _vm.date_str.substr(6,2))+"日のレース")]),_c('span',{staticClass:"next-container"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.nextday_ready),expression:"nextday_ready"}],staticClass:"next-dayrace",on:{"click":function($event){return _vm.changeRaceDate(_vm.next_date_str)}}},[_vm._v(_vm._s(_vm.next_date_str.substr(0,4)+'年' + _vm.next_date_str.substr(4,2)+'月'+ _vm.next_date_str.substr(6,2)+'日'))])])]),_c('ul',{staticClass:"day-jo-list grid-x"},_vm._l((_vm.jo_active),function(active,index){return _c('li',{key:index,staticClass:"cell small-4 medium-2"},[(active)?_c('span',[_c('router-link',{staticClass:"button",attrs:{"to":{ name: 'JoDateIndex', params: {jo:_vm.getProp(_vm.JO_ID_NAMES[index],'id'),date:_vm.date_str}}}},[((index == 19 && _vm.date_str == '20210811') ||
          (index == 7 && _vm.date_str == '20210823') ||  (index == 20 && _vm.date_str == '20210830') || (index == 13 && _vm.date_str == '20210918') ||
           (index == 2 && _vm.date_str == '20211001') || (index == 0 && _vm.date_str == '20211024')  || 
          /* (index == 15 && date_str == '20220918') || */ (index == 19 && _vm.date_str == '20220918') || (index == 21 && _vm.date_str == '20220918') ||  (index == 22 && _vm.date_str == '20220918') ||
          (index == 6 && _vm.date_str == '20220919') || (index == 8 && _vm.date_str == '20220919') || (index == 11 && _vm.date_str == '20220919') ||  (index == 12 && _vm.date_str == '20220919') ||
          (index == 13 && _vm.date_str == '20220919') || (index == 15 && _vm.date_str == '20220919') || (index == 19 && _vm.date_str == '20220919') ||  (index == 21 && _vm.date_str == '20220919') ||
          (index == 22 && _vm.date_str == '20220919') )?_c('span',{staticClass:"cancel"},[_vm._v("中止")]):_vm._e(),_vm._v(_vm._s(_vm.getProp(_vm.JO_ID_NAMES[index],'name'))+" "),(_vm.isGrade(index))?_c('span',{staticClass:"grade-label",class:'g-'+_vm.jo_headers[index].gcd},[_vm._v(_vm._s(_vm.jo_headers[index].grade))]):_vm._e(),(_vm.jo_headers[index].nflg > 0)?_c('span',{staticClass:"n-icon",class:'type-'+_vm.jo_headers[index].nflg}):_vm._e(),(_vm.isLady(index))?_c('span',{staticClass:"f-hearts-icon",class:'lgrade-'+_vm.jo_headers[index].gcd}):_vm._e()])],1):_c('span',[_c('span',{staticClass:"button disabled"},[_vm._v(_vm._s(_vm.getProp(_vm.JO_ID_NAMES[index],'name')))])])])}),0),_vm._m(3)]),(_vm.date_str == _vm.today_str)?_c('router-link',{staticClass:"button dayrace-button",attrs:{"to":{name:'DayRaceList',params:{date:_vm.today_str}}}},[_vm._v("本日のレース")]):_c('router-link',{staticClass:"button dayrace-button",attrs:{"to":{name:'DayRaceList',params:{date:_vm.date_str}}}},[_vm._v(_vm._s(_vm.date_str.substr(0,4)+'年'+_vm.date_str.substr(4,2)+'月'+ _vm.date_str.substr(6,2) +'日')+"のレース")]),(_vm.article_list != null && Array.isArray(_vm.article_list) && _vm.article_list.length > 0)?_c('div',{staticClass:"news-wrapper"},[_c('h2',[_vm._v("ニュース")]),_c('ul',{staticClass:"article-list"},_vm._l((_vm.article_list),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"to":{name:'Article',params:{id:item.id}}}},[_vm._v(" "+_vm._s(item.title)+" "),_c('time',[_vm._v("("+_vm._s(item.datetime)+")")])])],1)}),0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-section"},[_c('div',{staticClass:"hero-section-text"},[_c('h1',[_c('img',{attrs:{"src":"/images/large_logo.png","alt":"BOATNAVI報知"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site-catch"},[_c('img',{attrs:{"src":"/images/catch.svg","alt":"全場全レースデータ掲載 AI予想×データ解析で舟券攻略"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site-description"},[_c('p',[_vm._v("スポーツ報知が運営するボートレース専門サイト「ボートナビ報知」。全場、全レースで予想に必須なデータのほか、ＡＩ予想やスポーツ報知が開発した分析データ報知スコープがご覧頂けます。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-info"},[_c('span',{staticClass:"icon-info-item"},[_c('span',{staticClass:"emoji-icon"},[_c('img',{staticClass:"icon-pict",attrs:{"src":"/images/icon_nighter.png","alt":""}})]),_vm._v("ナイター")]),_c('span',{staticClass:"icon-info-item"},[_c('span',{staticClass:"emoji-icon"},[_c('img',{staticClass:"icon-pict",attrs:{"src":"/images/icon_midnight.png","alt":""}})]),_vm._v("ミッドナイト")]),_c('span',{staticClass:"icon-info-item"},[_c('span',{staticClass:"emoji-icon"},[_c('img',{staticClass:"icon-pict",attrs:{"src":"/images/icon_morning.png","alt":""}})]),_vm._v("モーニング")]),_c('span',{staticClass:"icon-info-item"},[_c('span',{staticClass:"emoji-icon"},[_c('img',{staticClass:"icon-pict",attrs:{"src":"/images/icon_summertime.png","alt":""}})]),_vm._v("サマータイム")]),_c('span',{staticClass:"icon-info-item"},[_c('span',{staticClass:"c-icon lgrade-6"},[_c('img',{staticClass:"icon-pict",attrs:{"src":"/images/icon_alllady.png","alt":""}})]),_vm._v("オールレディース")]),_c('span',{staticClass:"icon-info-item"},[_c('span',{staticClass:"c-icon lgrade-8"},[_c('img',{staticClass:"icon-pict",attrs:{"src":"/images/icon_venus.png","alt":""}})]),_vm._v("ヴィーナスシリーズ")])])
}]

export { render, staticRenderFns }