
<template>
<div>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li><router-link :to="{name:'DayRaceList',params:{date:date}}"> レース一覧 {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</router-link></li>
    <li><router-link :to="{name:'JoDateIndex',params:{date:date,jo:jo}}"> {{ jo_name }} </router-link></li>
    <li><span class="show-for-sr">Current: </span> {{race.rno}}R 直前データ</li>
  </ul>
</nav>
<nav class="jo-date-nav">
  <ul class="menu jo-date-menu" v-if="nidate_length > 0" >
    <li v-for="ni of nidate_length" :key='ni' >
      <span v-if="header['nidate'+ni+'_ready']"><router-link class="button" :class="race.nj == ni ? 'current':''" :to="{name:'JoDateIndex',params:{jo:jo,date:race['nidate'+ni].replace(/\-/g,'')}}">{{  Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</router-link></span>
      <span v-else><span class="button" disabled>{{   Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</span></span>
    </li>
  </ul>
</nav>
<nav class="race-nav">
  <ul class="menu race-num-menu" v-if="nidate_length > 0" >
    <li v-for="ri in 12" :key='ri' >
      <router-link class="button" v-if="ri != race.rno" :to="{name:'BeforeInfo',params:{date:date,jo:jo,race:ri}}">{{ ri }}R</router-link>
      <span class="button current" v-else>{{ ri }}R</span>
    </li>
  </ul>
</nav>
  <div class="race-header">
    <h1 class="race-h1">
      <div class="jo-name">{{ jo_name }}</div>
      <div class="race-number">{{ race.rno }}R</div>
      <div class="race-date"> {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</div>
      <div class="race-ktitl">{{ race.ktitl }}</div>
    </h1>

    <div class="rmei-rsname">
      <span class="race-rmei">{{ race.rmei }}</span> <span class="race-rsname">{{ race.rsname }} <span :class="race.kyori < 1800 ?'genshu':'' ">{{ race.kyori }}m</span> <span v-if="race.kyori < 1800">減周回</span></span>
    </div>
  </div>
  <div class="race-sh-times">
    <div>締切時刻 {{ race.stime ? race.stime.split('T')[1].slice( 0, -3 ):'-' }}</div>
        <!-- <div>出走時刻 {{ race.htime ? race.htime.split('T')[1].slice( 0, -3 ):'' }}</div> -->
  </div>
<Racenav :current = "'before_info'" :racedate = "date" :joid= "jo" :rno = "race_num" :is_result_available = "race.is_result_available"></Racenav>
<div class="table-scroll">
<table class="shussou-table">
  <thead>
  <tr>
    <th class="my-sirusi">My印</th>
    <th class="waku">枠</th>
    <th>前<br />日</th>
    <th>直<br />前</th>
    <th>氏名<br /> 登録番号</th>
    <th>体重<br />調整重量</th>
    <th>展示タイム</th>
    <th>ST</th>
     <th>進入</th>
     <th>プロペラ</th>
     <th>チルト</th>
     <th>部品交換</th>
  </tr>
  </thead>
  <tbody>
     <template v-for="(binfo,index) in before_info">
       <tr :key="`m_${binfo.name}`" :class="(binfo.sjiko == 'K' /* || (binfo.scs == null || binfo.sst == null)*/ )?'kjo' :''">
         <td class="my-sirusi">
          <div v-if="$store.getters.user !=null ">
          <a class="open-sirusi-button" v-on:click="openMySirusiSelecor(index+1)" :class=" printMySirusi(my_sirusi[index]) == 'ー' ? 'notset':''">{{  printMySirusi(my_sirusi[index]) }}</a>
          <div :id="'my_sirusi_selector_'+index" class="sirusi-slector" v-show="my_sirusi_selector[index]">
            <a v-on:click="setMySirusi(index+1,0)" :class="(my_sirusi[index] == null || my_sirusi[index] <=  0) ? 'current':''">ー</a>
            <a v-on:click="setMySirusi(index+1,1)" :class="(my_sirusi[index] == 1) ? 'current':''">◎</a>
            <a v-on:click="setMySirusi(index+1,2)" :class="(my_sirusi[index] == 2) ? 'current':''">◯</a>
            <a v-on:click="setMySirusi(index+1,3)" :class="(my_sirusi[index] == 3) ? 'current':''">▲</a>
            <a v-on:click="setMySirusi(index+1,4)" :class="(my_sirusi[index] == 4) ? 'current':''">△</a>
          </div>
          </div>
                <div v-else class="locked" >	
<img src="/images/icon_lock.svg" alt="" />
</div>
         </td>
         <td class="waku" :class="'waku-'+(index+1)"> 
          <span v-if="binfo.sjiko == 'K'">欠</span>
          <span v-else>{{ index+1 }}</span>
        </td>
        <td>{{ getSirusi(index+1) }}</td>
        <td>{{ getChokuSirusi(index+1) }}</td>
        <td>
          <router-link class="profile-link" :to="{name:'RacerProfile',params:{toban:binfo.toban,tabname:'kibetsu'}}">{{ binfo.name }}</router-link><br />
           <span class="sub-item"><span class="f-hearts-icon" v-if="binfo.seibetsu==2">&hearts;</span>{{binfo.toban}}</span>
        </td>
        <td>
           {{ binfo.taiju }}kg<br />
            {{ binfo.ctaiju }}
        </td>
        <td>
           {{ binfo.ttime }}
        </td>
         <td>
           <span v-if="binfo.sjiko == 'F'" style="#CC0000" >F {{ binfo.sst }}</span>
           <span v-else>{{ binfo.sst }}</span>
        </td>
        <td>
           {{ binfo.scs }}
        </td>
        <td>
           {{ getPropeller(binfo.propeller) }}
        </td>
        <td>
           {{ binfo.tiltc }}
        </td>
        <td>
          <template v-for="bnum in 8">
            <span class="buhin" :key="`b_${bnum}`" v-if="binfo['bcd'+ bnum]">
              {{ getBuhin(binfo['bcd'+ bnum]) }}{{ binfo['bsu'+ bnum] > 1 ? ' x '+binfo['bsu'+ bnum]:''}}
            </span>
          </template>
        </td>
       </tr>
     </template>
  </tbody>
</table>
</div>
  <a v-if="isMobile" class="button tohyo-button" target="blank" :href="'https://www.boatrace.jp/owsp/VoteBridgeSP.jsp?authAfterTrans=stay&amp;voteTagId=voteTag&amp;_hd='+ date +'&amp;_jcd='+ race.jcd + '&amp;_rno='+ race_num ">投票</a>
  <a v-else class="button tohyo-button" target="blank" :href="'https://www.boatrace.jp/owpc/VoteConfirm.jsp?authAfterTrans=stay&amp;voteTagId=vote&amp;_hd='+ date +'&amp;_jcd='+ race.jcd + '&amp;_rno='+ race_num ">投票</a>
<div style="text-align:center;margin:40px auto;">
<Adsense
    class="adsbygoogle"
    data-ad-client="ca-pub-3076785709839281"
    data-ad-slot="7503353487"
    data-ad-format="auto"
    data-full-width-responsive="true">
</Adsense>
</div>


<div class="grid-x grid-margin-x">
  <div class="cell  kaime-grid  small-12 medium-4">
     <h2 class="grid-header">スタート展示</h2>
    <StCourseZu :st_list="st_list" ></StCourseZu>
  </div>
  <div class="grid-x kaime-grid cell small-12 medium-4">
    <h2 class="cell small-12 grid-header">買い目(前日)</h2>
    <div class="cell small-6">
    <div v-for="z2rt in race.zenjitsu_2rentan_kaime" :key="z2rt">
      <span class="kaime-item" v-html="splitKaime(z2rt)"></span>
    </div>
    </div>
    <div class="cell small-6">
    <div v-for="z3rt in race.zenjitsu_3rentan_kaime" :key="z3rt">
      <span class="kaime-item" v-html="splitKaime(z3rt)"></span>
    </div>
    </div>
  </div>
  <div class="grid-x kaime-grid cell small-12 medium-4">
    <h2 class="cell small-12 grid-header">買い目(展示)</h2>
    <div class="cell small-6">
    <div v-for="z2rt in race.chokuzen_2rentan_kaime" :key="z2rt">
      <span class="kaime-item" v-html="splitKaime(z2rt)"></span>
    </div>
    </div>
    <div class="cell small-6">
    <div v-for="z3rt in race.chokuzen_3rentan_kaime" :key="z3rt">
      <span class="kaime-item" v-html="splitKaime(z3rt)"></span>
    </div>
    </div>
  </div>
</div>

</div>
</template>
<script>
import JO_ID_NAMES from '../mixins/PrefsMixin';
import API_BASE_URL  from '../mixins/PrefsMixin';
import Racenav from '../components/Racenav';
import StCourseZu from '../components/StCourseZu';


export default {
  mixins: [ JO_ID_NAMES,API_BASE_URL ],
  components: {
    Racenav,
    StCourseZu
  },
  name: 'BeforeInfo',
  data:function(){
    return {
    date:'',
    jo:'',
    race_num:0,
    race:{},
    header:{},
    before_info:[],
    nidate_length:0,
    jo_name:'',
    title:'直前データ',
    tommrow:new Date(),
    today:new Date(),
    st_list:[],
    my_sirusi_current_teiban:-1,
    my_sirusi_selector:[0,0,0,0,0,0],
    my_sirusi:[null,null,null,null,null,null],
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  computed:{
        isMobile:function(){
          if((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
              return true;
          }
          return false;
        }
  },
  created:function(){
    this.$data.date = this.$route.params['date'];
    this.$data.race_num = this.$route.params['race'];
    this.$data.jo = this.$route.params['jo'];
    this.$data.st_list = [];
    //this.$data.tommrow.setDate(this.$data.tommrow.getDate() + 1);
    this.getData();
  },
  beforeRouteUpdate(_to, _from, _next) {
    if(_to.name == 'BeforeInfo'){
      this.$data.date = _to.params['date'];
      this.$data.race_num = _to.params['race'];
      this.$data.jo = _to.params['jo'];
      this.$data.st_list = [];
      this.$data.my_sirusi = [null,null,null,null,null,null],
      this.changeRace();
    }
    _next();
  },
  
   methods: {
         splitKaime(kaime){
      if(kaime == null){
        return '';
      }
      return kaime.replace(/\d/g,"<span class=\"waku-$&\">$&</span>");
    },
    getSirusi(teiban){
      if(this.$data.race.zenjitsu_sirusi && this.$data.race.zenjitsu_sirusi.length > 0){
        let sban = this.$data.race.zenjitsu_sirusi.indexOf(teiban);
        if(sban < 4){
          return ['◎','◯','▲','△'][sban];
        } 
      }
      return '';
    },
    getChokuSirusi(teiban){
      if(this.$data.race.chokuzen_sirusi && this.$data.race.chokuzen_sirusi.length > 0){
        let sban = this.$data.race.chokuzen_sirusi.indexOf(teiban);
        if(sban < 4){
          return ['◎','◯','▲','△'][sban];
        } 
      }
      return '';
    },
    getPropeller(propeller){
      if(propeller >= 6 && propeller <= 9){
        return '新';
      }
      return ''
    },
    getBuhin(buhin){
      if(buhin == 14){
       return '電気一式'; 
      }else if(buhin == 21){
        return 'キャブレタ'; 
      }else if(buhin == 30){
        return 'ピストン'; 
      }else if(buhin == 31){
        return 'ピストンリング'; 
      }else if(buhin == 33){
        return 'シリンダ'; 
      }else if(buhin == 36){
        return 'クランクシャフト'; 
      }else if(buhin == 55){
        return 'プロペラ'; 
      }else if(buhin == 56){
        return 'ギヤケース'; 
      }else if(buhin == 58){
        return 'キャリアボデー'; 
      }

      return '';
    },
    changeRace(){
      this.$data.race ={};
      this.$data.shussou_senshu=[];
      this.$data.nidate_length=0;
      this.getData();
    },
    getData() {
          this.axios.get(this.API_BASE_URL + 'before_info/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num)
          .then((response) => { 
           //console.log(response.data);
          
            this.$data.before_info = response.data.before_info;
            this.$data.race =response.data.race;
            this.$data.header = response.data.header;
        
            if(this.$data.race == null){
              this.$router.replace({ name:'NotFound'});
              return;
            }

            this.$data.jo_name = this.JO_ID_NAMES[Number(this.$data.jo) - 1].name;


          let st_list = [];
          for(let i=0;i<this.$data.before_info.length;i++){
            let sst = this.$data.before_info[i].sjiko == 'F' ? -this.$data.before_info[i].sst : this.$data.before_info[i].sst;
            let scs = this.$data.before_info[i].scs;
            //console.log(this.$data.before_info[i])
            if(Number.isInteger(scs)){
              st_list[scs-1] = {teiban:i+1,sst:sst};
            }
          }
          this.$data.st_list = st_list;
      
      
            

            this.$data.title = '直前データ - '+ this.$data.jo_name +' '+ this.$data.race.rno +'R '+  this.$data.race.hdate + ' ' + this.$data.race.ktitl; 
            this.$emit('updateHead')
            
            this.pianoSend('直前データ',this.$data.jo_name,this.$data.race.rno +'R',this.$data.race.hdate,this.$data.race.ktitl);

            for(let i=1;i<=9;i++){
              if(this.$data.race['nidate'+i]){
                this.$data.nidate_length = i;
              }
            }

          })
          .catch((e) => { // eslint-disable-line
            
            this.$router.replace({ name:'NotFound'});
          });


                   if(this.$store.getters.uesr){
           this.getMySirusi(this.$store.getters.uesr.token) 
          return;
         }
          
         this.$cognito.isAuthenticated()
          .then(session => { // eslint-disable-line
            const token = session.idToken.jwtToken
            // get attribute.
            this.$cognito.getAttribute()
              .then(() => {
              
                this.getMySirusi(token) 
              })
              .catch(error => {  // eslint-disable-line
               
                this.$store.commit('setUser', null)
              })
          })
          .catch(error => { // eslint-disable-line
          
            this.$store.commit('setUser', null)
           })
      },
          openMySirusiSelecor(teiban){
      this.$data.my_sirusi_current_teiban = teiban;
      this.$data.my_sirusi_selector= [0,0,0,0,0,0];
      this.$data.my_sirusi_selector[teiban - 1] = 1;
      document.addEventListener('click',this.closeMySirusiSelecor,true);
    },
            getMySirusi(token){
         
          let headers = {headers: {"Authorization": token}};
          this.axios.get(this.API_BASE_URL + 'user/sirusi/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num,
          headers,
          {}
          ) .then((response) => {
            if(response.data && response.data.user_sirusi){
              this.$data.my_sirusi = response.data.user_sirusi.sirusi;
            }
          
          }).catch((e) => { // eslint-disable-line
            
          });
      },
      printMySirusi(sirusi){
      if(sirusi != null && sirusi-1 >= 0 && sirusi-1 <= 3){
        return ['◎','◯','▲','△'][sirusi-1];
      }
      return 'ー';
     },
       beforeRouteLeave(_to, _from, _next) {
        _from;
        try{
          document.removeEventListener('click',this.closeMySirusiSelecor,true);
        }catch(err){
          err;
        }
        _next();
      
      },
          setMySirusi(teiban,sirusi){
      this.$data.my_sirusi_selector= [0,0,0,0,0,0];
  
  
        
      this.$data.my_sirusi[teiban-1]= sirusi ;
   
      document.removeEventListener('click',this.closeMySirusiSelecor,true);

      let data = {};
      data['sirusi'+teiban] = sirusi;


      let headers = {headers: {"Authorization": this.$store.getters.user.token }};
          this.axios.post(this.API_BASE_URL + 'user/sirusi/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num,
          data, 
          headers
          ) .then((response) => { // eslint-disable-line
            
          }).catch((e) => { // eslint-disable-line
           
            
            
          });
    },
        closeMySirusiSelecor(evt){

        if(evt.target.closest('#my_sirusi_selector_'+(this.$data.my_sirusi_current_teiban-1))){
          
          return true;
        }
        
                if(evt.preventdefault){
          evt.preventdefault();
        }

        if(evt.stopPropagation){
          evt.stopPropagation();
        }

        this.$data.my_sirusi_selector= [0,0,0,0,0,0];
        
        document.removeEventListener('click',this.closeMySirusiSelecor,true);
        return false;
    },

   }


}
</script>
<style lang="scss" scoped>
.sirusi-slector{
  padding:10px;
  background:#EFEFEF;
  position:absolute;
  z-index:2;
  height:48px;
  top:50%;
  margin-top:-24px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.13), 0 1px 5px 0 rgba(0,0,0,0.08);
  a{
    display:inline-block;
    width:48px;
    height:28px;
    border:1px solid #CDCDCD;
    line-height:28px;
    margin:0 5px;
    &:first-child{
      color:#aaa;
    }
    &.current{
       background-color: #f3ff86; 
    }
        &:hover,&:focus{
      background-color: #f3ff86;
    }
     &:active{
      background-color: #f3ff86;
       box-shadow: 0 0px 0px 0 rgba(0,0,0,0.13), 0 1px 5px 0 rgba(0,0,0,0.08);
    }
  }
}
td.my-sirusi{
  position: relative;
  .open-sirusi-button{
        display:inline-block;
    width:28px;
    height:28px;
    border:1px solid #CDCDCD;
    line-height:28px;
    background-color: #EFEFEF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.13), 0 1px 5px 0 rgba(0,0,0,0.08);
    &:hover,&:focus{
      background-color: #f3ff86;
    }
     &:active{
      background-color: #f3ff86;
       box-shadow: 0 0px 0px 0 rgba(0,0,0,0.13), 0 1px 5px 0 rgba(0,0,0,0.08);
    }
    &.notset{
      color:#ccc;
    }
  }
    .locked{
    img{
      width:24px;
      opacity: 0.2;
    }
  }

}
td.my-sirusi,
th.my-sirusi{
  width:47px;
}
</style>
