<template>
<div>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
     <li><router-link :to="{name:'DayRaceList',params:{date:date}}"> レース一覧 {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</router-link></li>
    <li><router-link :to="{name:'JoDateIndex',params:{date:date,jo:jo}}">{{ jo_name }}</router-link></li>
    <li><span class="show-for-sr">Current: </span> {{ race.rno }}R スコープ</li>
  </ul>
</nav>
<nav class="jo-date-nav">
  <ul class="menu jo-date-menu" v-if="nidate_length > 0" >
    <li v-for="ni of nidate_length" :key='ni' >
      <span v-if="header['nidate'+ni+'_ready']"><router-link class="button" :class="race.nj == ni ? 'current':''" :to="{name:'JoDateIndex',params:{jo:jo,date:race['nidate'+ni].replace(/\-/g,'')}}">{{  Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</router-link></span>
      <span v-else><span class="button" disabled>{{   Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</span></span>
    </li>
  </ul>
</nav>
<nav class="race-nav">
  <ul class="menu race-num-menu" v-if="nidate_length > 0" >
    <li v-for="ri in 12" :key='ri' >
      <router-link class="button" v-if="ri != race.rno" :to="{name:'HochiScope',params:{date:date,jo:jo,race:ri}}">{{ ri }}R</router-link>
      <span class="button current" v-else>{{ ri }}R</span>
    </li>
  </ul>
</nav>
<div class="race-header">
    <h1 class="race-h1">
      <div class="jo-name">{{ jo_name }}</div>
      <div class="race-number">{{ race.rno }}R</div>
      <div class="race-date"> {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</div>
      <div class="race-ktitl">{{ race.ktitl }}</div>
    </h1>

    <div class="rmei-rsname">

         <span class="race-rmei">{{ race.rmei }}</span> <span class="race-rsname">{{ race.rsname }} <span :class="race.kyori < 1800 ?'genshu':'' ">{{ race.kyori }}m</span> <span v-if="race.kyori < 1800">減周回</span></span>
    </div>

</div>
<div class="race-sh-times">
  <div>締切時刻 {{ race.stime ? race.stime.split('T')[1].slice( 0, -3 ):'-' }}</div>
  <!-- <div>出走時刻 {{ race.htime ? race.htime.split('T')[1].slice( 0, -3 ):'' }}</div> -->
</div>


<Racenav :current = "'hochi_scope'" :racedate = "date" :joid= "jo" :rno = "race_num" :is_result_available = "race.is_result_available"></Racenav>

<div class="grid-x scope-grid">
    <div class="cell small-12 medium-6 large-4 scope-grid__item grid-x" v-for="scope_item in hscope" :key="`s_${scope_item.teiban}`">
        <div :class="'waku-'+ scope_item.teiban" class="waku cell small-1">{{ scope_item.teiban }}</div>
        <div class="name-toban cell small-11"><router-link class="profile-link" :to="{name:'RacerProfile',params:{toban:scope_item.toban,tabname:'kibetsu'}}">{{ scope_item.name }}</router-link> <span class="toban"><span class="f-hearts-icon" v-if="scope_item.seibetsu==2">&hearts;</span>{{ scope_item.toban }} / {{ scope_item.kyu }}</span></div>
        <div class="rchart cell small-12">
            <ScopeChart :chart-data="{
                labels: ['エンジン','コース','展開','選手']
              ,datasets:formatGraphData(scope_item)}"></ScopeChart>
        </div>
    </div>
</div>


  <a v-if="isMobile" class="button tohyo-button" target="blank" :href="'https://www.boatrace.jp/owsp/VoteBridgeSP.jsp?authAfterTrans=stay&amp;voteTagId=voteTag&amp;_hd='+ date +'&amp;_jcd='+ race.jcd + '&amp;_rno='+ race_num ">投票</a>
  <a v-else class="button tohyo-button" target="blank" :href="'https://www.boatrace.jp/owpc/VoteConfirm.jsp?authAfterTrans=stay&amp;voteTagId=vote&amp;_hd='+ date +'&amp;_jcd='+ race.jcd + '&amp;_rno='+ race_num ">投票</a>

</div>
</template>

<script>
import JO_ID_NAMES from '../mixins/PrefsMixin';
import API_BASE_URL  from '../mixins/PrefsMixin';
import pianoSend from '../mixins/PrefsMixin';
import Racenav from '../components/Racenav';
import ScopeChart from '../components/ScopeChart';


export default {
  mixins: [ JO_ID_NAMES,API_BASE_URL,pianoSend],
  components: {
    Racenav,
    ScopeChart
  },
  name: 'HochiScope',
  data:function(){
    return {
    date:'',
    jo:'',
    race_num:0,
    race:{},
    hscope:[],
    nidate_length:0,
    jo_name:'',
    header:{},
    title:'報知スコープ',
    tommrow:new Date(),
    today:new Date()
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  computed:{
        isMobile:function(){
          if((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
              return true;
          }
          return false;
        }
  },
  created:function(){
    this.$data.date = this.$route.params['date'];
    this.$data.race_num = this.$route.params['race'];
    this.$data.jo = this.$route.params['jo'];
    //this.$data.tommrow.setDate(this.$data.tommrow.getDate() + 1);
   
    this.getData();
  },
  beforeRouteUpdate(_to, _from, _next) {
 
    if(_to.name == 'HochiScope'){


      this.$data.date = _to.params['date'];
      this.$data.race_num = _to.params['race'];
      this.$data.jo = _to.params['jo'];
    

      this.changeRace();
    }
    _next();
    
  },
  methods: {
    changeRace(){
      this.$data.race ={};
      this.$data.shussou_senshu=[];
      this.$data.nidate_length=0;
      this.$data.hscope=[];
      this.getData();
    },
    formatGraphData(item){
      let zenjitsu_data = {label: '前日',
                  data: [item.motor_zenjitsu_point,item.course_zenjitsu_point,item.tenkai_zenjitsu_point,item.senshu_zenjitsu_point],
                  fill: true,
           backgroundColor: 'rgba(54, 162, 235, 0.2)',
    borderColor: 'rgb(54, 162, 235)',
    pointBackgroundColor: 'rgb(54, 162, 235)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(54, 162, 235)',
          datalabels: {
                color: '#05354e',
                anchor: 'end', 
                align: 'start', 
            }
                  }

   
      let chokuzen_data  = {label: '展示',
                  data: [item.motor_chokuzen_point,item.course_chokuzen_point,item.tenkai_chokuzen_point,item.senshu_chokuzen_point],
                  fill: false,
                  backgroundColor: 'rgba(255, 99, 132, 0.2)',
                  borderColor: 'rgb(255, 99, 132)',
                  pointBackgroundColor: 'rgb(255, 99, 132)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgb(255, 99, 132)',
                   datalabels: {
                color: '#f52954',
                anchor: 'start', 
                align: 'end', 
            }
                  }

      if(item.motor_chokuzen_point != null ){
        return [chokuzen_data,zenjitsu_data];
      }

       return [zenjitsu_data];
    },
    getProp(object,propertyPath){
      if (!object) { return undefined }
      let result = object;
      const propertyArray = propertyPath.split('.');
      for (let i = 0; i <= propertyArray.length - 1; i += 1) {
        if (propertyArray[i] === '' ) { return undefined; }
        if (typeof result[propertyArray[i]] === 'undefined') { return undefined; }
        result = result[propertyArray[i]];
      }
      return result;
    },
      getData() {
          this.axios.get(this.API_BASE_URL + 'hochi_scope/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num)
          .then((response) => {
          
           
            this.$data.race =response.data.race;
            this.$data.hscope = response.data.hochi_scope;
            this.$data.header = response.data.header;
            //console.log(this.$data.hscope)
              
            if(this.$data.race == null){
              this.$router.replace({ name:'NotFound'});
              return;
            }

            this.$data.jo_name = this.JO_ID_NAMES[Number(this.$data.jo) - 1].name;
            

            this.$data.title = '報知スコープ - '+ this.$data.jo_name +' '+ this.$data.race.rno +'R '+  this.$data.race.hdate + ' ' + this.$data.race.ktitl; 
            this.$emit('updateHead');
            this.pianoSend('報知スコープ',this.$data.jo_name,this.$data.jo_name+'R',this.$data.race.hdate,this.$data.race.ktitl);


            for(let i=1;i<=9;i++){
              if(this.$data.race['nidate'+i]){
                this.$data.nidate_length = i;
              }
            }

          })
          .catch((e) => { // eslint-disable-line
           
            this.$router.replace({ name:'NotFound'});
          });
      }
  }
}
</script>