<template>
<div class="user-my-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <router-link :to="{name:'UserMyPage'}"> ユーザー {{ $store.getters.user != null ? $store.getters.user.nickname :'' }} </router-link>
    </li>
    <li>
      <span class="show-for-sr">Current: </span>  パスワード変更
    </li>
  </ul>
</nav>
  <div class="register-form">
  <h1>パスワード変更</h1>

<div v-if="!send_done">
    <div v-if="error_message != ''" class="callout alert">
        <p>{{ error_message }}</p>
    </div>
  <form @submit.prevent="send">
  
  <label>現在のパスワード
  <input type="password" placeholder="現在のパスワード" v-model="old_password" required>
  </label>
  

  <label>新しいパスワード
  <input type="password" placeholder="新しいパスワード" v-model="new_password" required>
  </label>
  <p class="help-text" id="passwordHelpText">8文字以上で、アルファベット大文字、小文字、数字、次の特殊文字
「= + - ^ $ * . [ ] { } ( ) ? " ! @ # % &amp; / \ , &gt; &lt; ' : ; | _ ~ `」から、それぞれ1文字以上使用してください。</p>

  <label>新しいパスワード 確認
  <input type="password" placeholder="新しいパスワード" v-model="confirm_new_password" required>
  </label>

  <button class="button" type="submit">変更</button>
  </form>
</div>
<div v-else>
  <div class="callout success">
    <p>パスワードを変更しました。</p>
  </div>
</div>


  </div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserChEmail',
  mixins: [pianoSend],
  data:function(){
    return{
    old_password:'',
    new_password:'',
    confirm_new_password:'',
      error_message:'',
      message:'',
      send_done:false,
      title:'パスワード変更'
    }
  },

  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },

  created:function(){
    
    this.$emit('updateHead');
    this.pianoSend('ユーザーページ','パスワード変更');
  },
 
  methods: {

    send:function(){
        if(this.$data.new_password != this.$data.confirm_new_password){
            this.$data.error_message = 'パスワードが一致しません。';
            return
        }

        let loader = this.$loading.show({});
        this.$cognito.updatePassword(this.$data.old_password,this.$data.new_password).then((result) =>{
            //console.log(result);
            loader.hide();
            if(result){
                this.$data.send_done = true;
                this.$router.replace('/user');
            }

        }).catch((err)=>{ // eslint-disable-line
            //console.log(err);
            loader.hide();
            if(err.name=='InvalidPasswordException'){
                this.$data.error_message = 'パスワードは8文字以上で、アルファベット大文字、小文字、数字、次の特殊文字「= + - ^ $ * . [ ] { } ( ) ? " ! @ # % &; / \\ ; , > < \' : ; | _ ~ `」からそれぞれ1文字以上使用してください。';
            }else{
                this.$data.error_message = 'エラーが発生しました';
            }
            
        }) 
    }

  }
}
</script>
<style scoped>
.callout.user-info{
    border:none;
    background-color: #EFEFEE;
    margin-bottom:30px;
}
</style>