<template>
<div>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li><router-link :to="{name:'DayRaceList',params:{date:date}}"> レース一覧 {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</router-link></li>
    <li><router-link :to="{name:'JoDateIndex',params:{date:date,jo:jo}}"> {{ jo_name }}</router-link></li>
    <li><span class="show-for-sr">Current: </span> {{ race.rno }}R 出走表</li>
  </ul>
</nav>
<nav class="jo-date-nav">
  <ul class="menu jo-date-menu" v-if="nidate_length > 0" >
    <li v-for="ni of nidate_length" :key='ni' >
      <span v-if="header['nidate'+ni+'_ready']"><router-link class="button" :class="race.nj == ni ? 'current':''" :to="{name:'JoDateIndex',params:{jo:jo,date:race['nidate'+ni].replace(/\-/g,'')}}">{{  Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</router-link></span>
      <span v-else><span class="button" disabled>{{   Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</span></span>
    </li>
  </ul>
</nav>

<nav class="race-nav">
  <ul class="menu race-num-menu" v-if="nidate_length > 0" >
    <li v-for="ri in 12" :key='ri' >
      <router-link class="button" v-if="ri != race.rno" :to="{name:'Shussou',params:{date:date,jo:jo,race:ri}}">{{ ri }}R</router-link>
      <span class="button current" v-else>{{ ri }}R</span>
    </li>
  </ul>
</nav>

<div class="race-header">
    <h1 class="race-h1">
      <div class="jo-name">{{ jo_name }}</div>
      <div class="race-number">{{ race.rno }}R</div>
      <div class="race-date">{{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</div>
      <div class="race-ktitl">{{ race.ktitl }}</div>
    </h1>

    <div class="rmei-rsname">
      <span class="race-rmei">{{ race.rmei }}</span> <span class="race-rsname">{{ race.rsname }} <span :class="race.kyori < 1800 ?'genshu':'' ">{{ race.kyori }}m</span> <span v-if="race.kyori < 1800">減周回</span></span>
    </div>

</div>
<div class="race-sh-times">
  <div>締切時刻 {{ race.stime ? race.stime.split('T')[1].slice( 0, -3 ):'-' }}</div>
      <!-- <div>出走時刻 {{ race.htime ? race.htime.split('T')[1].slice( 0, -3 ):'' }}</div> -->
</div>


<Racenav :current = "'shussou'" :racedate = "date" :joid= "jo" :rno = "race_num" :is_result_available = "race.is_result_available" ></Racenav>


<div class="table-scroll">
<table class="shussou-table">
  <thead>
  <tr>
    <th class="my-sirusi" rowspan="2">My印</th>
    <th class="waku" rowspan="2">枠</th>
    <th rowspan="2">印</th>
    <th rowspan="2"></th>
    <th rowspan="2">氏名<br /> 登録番号/級別</th>
    <th rowspan="2">支部/出身地<br />年齢/体重</th>
    <th rowspan="2">F数<br />L数<br />平均ST</th>
    <th rowspan="2">全国<br />勝率<br />2連率<br />3連率</th>
    <th rowspan="2">当地<br />勝率<br />2連率<br />3連率</th>
    <th rowspan="2">モーター<br />No<br />2連率</th>
    <th rowspan="2">ボート<br />No<br />2連率</th>
    <th rowspan="2">進入予想</th>
    <th colspan="6">進入確率(%)</th>
    <th class="konsetsu-s" :colspan="nidate_length*4">
      今節成績 <span style="font-weight:normal;margin-left:20px;"> レースNo （艇番色）| 進入コース | STタイミング | 成績 </span>
    </th>
    <th rowspan="2">早見</th>
  </tr>
  <tr>
    <th class="sinnyu">1</th>
    <th class="sinnyu">2</th>
    <th class="sinnyu">3</th>
    <th class="sinnyu">4</th> 
    <th class="sinnyu">5</th>
    <th class="sinnyu">6</th>

    <th class="konsetsu-day" v-for="index in nidate_length" :key="index" colspan="4">
        {{ index == 1 ? '初日':(index == nidate_length ? '最終日' :index+'日目')}}
    </th>
  </tr>
  </thead>
  <tbody>
  <template v-for="(senshu,index) in shussou_senshu">
  <tr :key="`m_${senshu.name}`" :class="(senshu.kjo == 1)?'kjo':''">
    <td rowspan="2"  class="my-sirusi">
      <div v-if="$store.getters.user !=null ">
      <a class="open-sirusi-button" v-on:click="openMySirusiSelecor(index+1)" :class=" printMySirusi(my_sirusi[index]) == 'ー' ? 'notset':''">{{  printMySirusi(my_sirusi[index]) }}</a>
      <div :id="'my_sirusi_selector_'+index" class="sirusi-slector" v-show="my_sirusi_selector[index]">
        <a v-on:click="setMySirusi(index+1,0)" :class="(my_sirusi[index] == null || my_sirusi[index] <=  0) ? 'current':''">ー</a>
        <a v-on:click="setMySirusi(index+1,1)" :class="(my_sirusi[index] == 1) ? 'current':''">◎</a>
        <a v-on:click="setMySirusi(index+1,2)" :class="(my_sirusi[index] == 2) ? 'current':''">◯</a>
        <a v-on:click="setMySirusi(index+1,3)" :class="(my_sirusi[index] == 3) ? 'current':''">▲</a>
        <a v-on:click="setMySirusi(index+1,4)" :class="(my_sirusi[index] == 4) ? 'current':''">△</a>
      </div>
      </div>
      <div v-else class="locked" >	
<img src="/images/icon_lock.svg" alt="" />
</div>
    </td>
    <td rowspan="2"  class="waku" :class="'waku-'+(index+1)"> 
      {{ index+1 }}
     <span v-if="senshu.kjo == 1"><br />欠</span>
    </td>
    <td rowspan="2">
      {{ getSirusi(index+1) }}
    </td>
    <td rowspan="2" style="padding:0;">
      <img :src="RACER_PHOTO_BASE_URL + senshu.toban+'.jpg'" class="racer-photo" />
    </td>
    <td rowspan="2">
      <router-link class="profile-link" :to="{name:'RacerProfile',params:{toban:senshu.toban,tabname:'kibetsu'}}">{{ senshu.name }}</router-link>
    <br />
      <span class="sub-item"><span class="f-hearts-icon" v-if="senshu.seibetsu==2">&hearts;</span>{{ senshu.toban }} / {{ senshu.kyu }} </span>
    </td>
    <td rowspan="2">
      {{ senshu.syusin }} / {{ senshu.syussinchi }} <br />
      {{ senshu.nenrei ? senshu.nenrei : '- ' }}歳 / {{ senshu.taiju ? senshu.taiju: '- '}}kg
    </td>
     <td rowspan="2">
      F{{ senshu.fsu }} <br />
      L{{ senshu.lsu }} <br />
      {{ senshu.p_st }}
    </td>
    <td rowspan="2">
        <span :class="senshu.zsyo_rank == 1 ?'n1':''">{{ senshu.zsyo }}</span> <br />
        <span :class="senshu.z2ren_rank == 1 ?'n1':''">{{ senshu.z2ren }}</span> <br />
        <span :class="senshu.z3ren_rank == 1 ?'n1':''">{{ senshu.z3ren }}</span>
    </td>
     <td rowspan="2">
        <span :class="senshu.jsyo_rank == 1 ?'n1':''">{{ senshu.jsyo }}</span> <br />
        <span :class="senshu.j2ren_rank == 1 ?'n1':''">{{ senshu.j2ren }}</span> <br />
        <span :class="senshu.j3ren_rank == 1 ?'n1':''">{{ senshu.j3ren }}</span>
    </td>
    <td rowspan="2">
        {{ Number(senshu.mno) }} <br />
        <span :class="senshu.m2ren_rank == 1 ?'n1':''">{{ senshu.m2ren }}</span>
    </td>
    <td rowspan="2">
        {{ Number(senshu.bno) }} <br />
        <span :class="senshu.b2ren_rank == 1 ?'n1':''">{{ senshu.b2ren }}</span>
    </td>
     <td rowspan="2">
        {{ senshu.cose }}
    </td>
    <td class="sinnyu" rowspan="2">
        {{ senshu.sinnyu1*10 }}
    </td>
    <td class="sinnyu" rowspan="2">
        {{ senshu.sinnyu2*10 }}
    </td>
    <td class="sinnyu" rowspan="2">
        {{ senshu.sinnyu3*10 }}
    </td>
    <td class="sinnyu" rowspan="2">
        {{ senshu.sinnyu4*10 }}
    </td>
    <td class="sinnyu" rowspan="2">
        {{ senshu.sinnyu5*10 }}
    </td>
    <td class="sinnyu" rowspan="2">
        {{ senshu.sinnyu6*10 }}
    </td>
    <template v-for="index in nidate_length">
      <td class="konsetsu-s" :class="'waku-'+getProp(senshu,'konsetsu_seiseki.'+index+'1'+'.k_waku')" :key="`f_rno-${senshu.name}-${index}`">{{ getProp(senshu,'konsetsu_seiseki.'+index+'1'+'.k_rno') }}</td>
      <td class="konsetsu-s" :key="`f_cs-${senshu.name}-${index}`">{{ getProp(senshu,'konsetsu_seiseki.'+index+'1'+'.k_cs') }}</td>
      <td class="konsetsu-s" :key="`f_st-${senshu.name}-${index}`">{{ getProp(senshu,'konsetsu_seiseki.'+index+'1'+'.k_st') }}</td>
      <td class="konsetsu-s" :key="`f_chk-${senshu.name}-${index}`">{{ getProp(senshu,'konsetsu_seiseki.'+index+'1'+'.k_chk') }}</td>
    </template>
    <td rowspan="2">
        <router-link v-if='senshu.haya' :to="{ name: 'Shussou', params: { date:date,jo:jo,race:Number(senshu.haya) }}">{{ Number(senshu.haya) }}R</router-link>
    </td>
  </tr>
  <tr :key="`s_${senshu.name}`" :class="(senshu.kjo == 1)?'kjo':''">
    <template v-for="index in nidate_length">
      <td class="konsetsu-s" :class="'waku-'+getProp(senshu,'konsetsu_seiseki.'+index+'2'+'.k_waku')" :key="`s_rno-${senshu.name}-${index}`">{{ getProp(senshu,'konsetsu_seiseki.'+index+'2'+'.k_rno') }}</td>
      <td class="konsetsu-s" :key="`s_cs-${senshu.name}-${index}`">{{ getProp(senshu,'konsetsu_seiseki.'+index+'2'+'.k_cs') }}</td>
      <td class="konsetsu-s" :key="`s_st-${senshu.name}-${index}`">{{ getProp(senshu,'konsetsu_seiseki.'+index+'2'+'.k_st') }}</td>
      <td class="konsetsu-s" :key="`s_chk-${senshu.name}-${index}`">{{ getProp(senshu,'konsetsu_seiseki.'+index+'2'+'.k_chk') }}</td>
    </template>
  </tr>
  </template>
  </tbody>
</table>
</div>

  <a v-if="isMobile" class="button tohyo-button" target="blank" :href="'https://www.boatrace.jp/owsp/VoteBridgeSP.jsp?authAfterTrans=stay&amp;voteTagId=voteTag&amp;_hd='+ date +'&amp;_jcd='+ race.jcd + '&amp;_rno='+ race_num ">投票</a>
  <a v-else class="button tohyo-button" target="blank" :href="'https://www.boatrace.jp/owpc/VoteConfirm.jsp?authAfterTrans=stay&amp;voteTagId=vote&amp;_hd='+ date +'&amp;_jcd='+ race.jcd + '&amp;_rno='+ race_num ">投票</a>

<div style="text-align:center;margin:40px auto;">
<Adsense
    class="adsbygoogle"
    data-ad-client="ca-pub-3076785709839281"
    data-ad-slot="7503353487"
    data-ad-format="auto"
    data-full-width-responsive="true">
</Adsense>
</div>

<div class="grid-x grid-margin-x">
  <div class="cell  kaime-grid  small-12 medium-4">
     <h2 class="cell small-12 grid-header">進入予想</h2>
    <ShinnyuZu :course ="course" ></ShinnyuZu>
  </div>
  <div class="grid-x kaime-grid cell small-12 medium-4">
    <h2 class="cell small-12 grid-header">買い目(前日)</h2>
    <div class="cell small-6">
    <div v-for="z2rt in race.zenjitsu_2rentan_kaime" :key="z2rt">
      <span class="kaime-item" v-html="splitKaime(z2rt)"></span>
    </div>
    </div>
    <div class="cell small-6">
    <div v-for="z3rt in race.zenjitsu_3rentan_kaime" :key="z3rt">
      <span class="kaime-item" v-html="splitKaime(z3rt)"></span>
    </div>
    </div>
  </div>
  <div class="grid-x kaime-grid cell small-12 medium-4">
    <h2 class="cell small-12 grid-header">買い目(展示)</h2>
    <div class="cell small-6">
    <div v-for="z2rt in race.chokuzen_2rentan_kaime" :key="z2rt">
      <span class="kaime-item" v-html="splitKaime(z2rt)"></span>
    </div>
    </div>
    <div class="cell small-6">
    <div v-for="z3rt in race.chokuzen_3rentan_kaime" :key="z3rt">
      <span class="kaime-item" v-html="splitKaime(z3rt)"></span>
    </div>
    </div>
  </div>
</div>

</div>
</template>

<script>
import JO_ID_NAMES from '../mixins/PrefsMixin';
import API_BASE_URL  from '../mixins/PrefsMixin';
import Racenav from '../components/Racenav';
import ShinnyuZu from '../components/ShinnyuZu';
import pianoSend from '../mixins/PrefsMixin'


export default {
  mixins: [ JO_ID_NAMES,API_BASE_URL,pianoSend],
  components: {
    Racenav,
    ShinnyuZu
  },
  name: 'Shussou',
  data:function(){
    return {
    date:'',
    jo:'',
    race_num:0,
    race:{},
    header:{},
    shussou_senshu:[],
    nidate_length:0,
    jo_name:'',
    title:'出走表',
    course:[],
    tommrow:new Date(),
    today:new Date(),
    my_sirusi_current_teiban:-1,
    my_sirusi_selector:[0,0,0,0,0,0],
    my_sirusi:[null,null,null,null,null,null],
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  computed:{
        isMobile:function(){
          if((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
              return true;
          }
          return false;
        }
  },

   mounted:function(){
   
  },

  created:function(){
    this.$data.date = this.$route.params['date'];
    this.$data.race_num = this.$route.params['race'];
    this.$data.jo = this.$route.params['jo'];
    this.$data.tommrow.setDate(this.$data.tommrow.getDate() + 1);
    this.$data.course =[];
    this.getData();
  },
  beforeRouteUpdate(_to, _from, _next) {


    if(_to.name == 'Shussou'){

      this.$data.date = _to.params['date'];
      this.$data.race_num = _to.params['race'];
      this.$data.jo = _to.params['jo'];
      this.$data.my_sirusi = [null,null,null,null,null,null],
      this.changeRace();
    }
    _next();
    
  },
  beforeRouteLeave(_to, _from, _next) {
    _from;
    try{
      document.removeEventListener('click',this.closeMySirusiSelecor,true);
    }catch(err){
      err;
    }
    _next();
   
  },
  methods: {
    // getMySirusi(teiban){
    //     let sban = this.$data.race.zenjitsu_sirusi.indexOf(teiban);
    //     if(sban < 4){
    //       return ['◎','◯','▲','△'][sban];
    //     } 
    //     return '-';
    // },

    closeMySirusiSelecor(evt){

        if(evt.target.closest('#my_sirusi_selector_'+(this.$data.my_sirusi_current_teiban-1))){
          
          return true;
        }
        
       if(evt.preventdefault){
          evt.preventdefault();
        }

        if(evt.stopPropagation){
          evt.stopPropagation();
        }

        this.$data.my_sirusi_selector= [0,0,0,0,0,0];
        
        document.removeEventListener('click',this.closeMySirusiSelecor,true);
        return false;
    },

    setMySirusi(teiban,sirusi){
      this.$data.my_sirusi_selector= [0,0,0,0,0,0];
   
      this.$data.my_sirusi[teiban-1]= sirusi ;
   
      document.removeEventListener('click',this.closeMySirusiSelecor,true);

      let data = {};
      data['sirusi'+teiban] = sirusi;
     

      let headers = {headers: {"Authorization": this.$store.getters.user.token }};
          this.axios.post(this.API_BASE_URL + 'user/sirusi/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num,
          data, 
          headers
          ) .then((response) => {
            response;
          
          }).catch((e) => { // eslint-disable-line
          
            
            
          });
    },

    changeRace(){
      this.$data.race ={};
      this.$data.shussou_senshu=[];
      this.$data.nidate_length=0;
      this.$data.course =[];
      this.getData();
    },

    splitKaime(kaime){
      if(kaime == null){
        return '';
      }
      return kaime.replace(/\d/g,"<span class=\"waku-$&\">$&</span>");
    },

    getSirusi(teiban){
      if(this.$data.race.zenjitsu_sirusi && this.$data.race.zenjitsu_sirusi.length > 0){
        let sban = this.$data.race.zenjitsu_sirusi.indexOf(teiban);
        if(sban < 4){
          return ['◎','◯','▲','△'][sban];
        } 
      }
      return '';
    },

    getProp(object,propertyPath){
  
      if (!object) { return undefined }
      let result = object;
      const propertyArray = propertyPath.split('.');
      for (let i = 0; i <= propertyArray.length - 1; i += 1) {
        if (propertyArray[i] === '' ) { return undefined; }
        if (typeof result[propertyArray[i]] === 'undefined') { return undefined; }
        result = result[propertyArray[i]];
      }
      
      return result;
    },

      getData() {
          this.axios.get(this.API_BASE_URL + 'shussou/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num)
          .then((response) => {

            
            this.$data.shussou_senshu = response.data.shussou_senshu;
          
            
            this.$data.race = response.data.race;
            this.$data.header = response.data.header;
            if(this.$data.race == null){
              this.$router.replace({ name:'NotFound'});
              return;
            }
            
         
           
            this.$data.jo_name = this.JO_ID_NAMES[Number(this.$data.jo) - 1].name;
            

            this.$data.title = '出走表 - '+ this.$data.jo_name +' '+ this.$data.race.rno +'R '+  this.$data.race.hdate + ' ' + this.$data.race.ktitl; 
            this.$emit('updateHead');
            this.pianoSend('出走表',this.$data.jo_name,this.$data.race.rno +'R',this.$data.race.hdate,this.$data.race.ktitl);


            for(let i=1;i<=9;i++){
              if(this.$data.race['nidate'+i]){
                this.$data.nidate_length = i;
              }
            }
          
           let senshu_length = this.$data.shussou_senshu.length;
           let course = [];

           for(let i=0;i<senshu_length ;i++){
              if(Number.isInteger(this.$data.shussou_senshu[i].cose)){
                course[this.$data.shussou_senshu[i].cose-1] =(i+1);
              }
            }
            
            this.$data.course = course;



          })
          .catch((e) => {  // eslint-disable-line
            
            this.$router.replace({ name:'NotFound'});
          });

         if(this.$store.getters.uesr){
           this.getMySirusi(this.$store.getters.uesr.token) 
          return;
         }
          
         this.$cognito.isAuthenticated()
          .then(session => { // eslint-disable-line
            const token = session.idToken.jwtToken
            // get attribute.
            this.$cognito.getAttribute()
              .then(() => {
              
                this.getMySirusi(token) 
              })
              .catch(error => { // eslint-disable-line
               
                this.$store.commit('setUser', null)
              })
          })
          .catch(error => { // eslint-disable-line
        
            this.$store.commit('setUser', null)
           })
      },
          openMySirusiSelecor(teiban){
      this.$data.my_sirusi_current_teiban = teiban;
      this.$data.my_sirusi_selector= [0,0,0,0,0,0];
      this.$data.my_sirusi_selector[teiban - 1] = 1;
      document.addEventListener('click',this.closeMySirusiSelecor,true);
    },
      getMySirusi(token){
        
          let headers = {headers: {"Authorization": token}};
          this.axios.get(this.API_BASE_URL + 'user/sirusi/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num,
          headers,
          {}
          ) .then((response) => {
            if(response.data && response.data.user_sirusi){
              this.$data.my_sirusi = response.data.user_sirusi.sirusi;
            }
          
          }).catch((e) => { // eslint-disable-line
            
          });
      },
      printMySirusi(sirusi){
      if(sirusi != null && sirusi-1 >= 0 && sirusi-1 <= 3){
        return ['◎','◯','▲','△'][sirusi-1];
      }
      return 'ー';
     }
  }
}
</script>
<style lang="scss" scoped>

.n1{
   color:#e02a2a; 
}
img.racer-photo{
  max-width:none;
  height:73px;
}
@media screen and (max-width:375px) { 
  img.racer-photo{
  height:56px;
  }
}

.sirusi-slector{
  padding:10px;
  background:#EFEFEF;
  position:absolute;
  z-index:2;
  height:48px;
  top:50%;
  margin-top:-24px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.13), 0 1px 5px 0 rgba(0,0,0,0.08);
  a{
    display:inline-block;
    width:48px;
    height:28px;
    border:1px solid #CDCDCD;
    line-height:28px;
    margin:0 5px;
    &:first-child{
      color:#aaa;
    }
    &.current{
       background-color: #f3ff86; 
    }
        &:hover,&:focus{
      background-color: #f3ff86;
    }
     &:active{
      background-color: #f3ff86;
       box-shadow: 0 0px 0px 0 rgba(0,0,0,0.13), 0 1px 5px 0 rgba(0,0,0,0.08);
    }
  }
}
td.my-sirusi{
  position: relative;
  .open-sirusi-button{
        display:inline-block;
    width:28px;
    height:28px;
    border:1px solid #CDCDCD;
    line-height:28px;
    background-color: #EFEFEF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.13), 0 1px 5px 0 rgba(0,0,0,0.08);
    &:hover,&:focus{
      background-color: #f3ff86;
    }
     &:active{
      background-color: #f3ff86;
       box-shadow: 0 0px 0px 0 rgba(0,0,0,0.13), 0 1px 5px 0 rgba(0,0,0,0.08);
    }
    &.notset{
      color:#ccc;
    }
  }
  .locked{
    img{
      width:24px;
      opacity: 0.2;
    }
  }

}
td.my-sirusi,
th.my-sirusi{
  width:47px;
}
</style>