//const _api_base_url = 'https://boatnavi.hochi.co.jp/api/';
//const _api_base_url = 'https://d2mb8ud92e09lo.cloudfront.net/api/';
//console.log(process.env.VUE_APP_BOAT_API_BASE_URL);
const _api_base_url = process.env.VUE_APP_BOAT_API_BASE_URL;
const _racer_photo_base_url = 'https://www.boatrace.jp/racerphoto/';


const _jo_id_names= [
{'id':'01','name':'桐　生'},
{'id':'02','name':'戸　田'},
{'id':'03','name':'江戸川'},
{'id':'04','name':'平和島'},
{'id':'05','name':'多摩川'},
{'id':'06','name':'浜名湖'},
{'id':'07','name':'蒲　郡'},
{'id':'08','name':'常　滑'},
{'id':'09','name':'　津　'},
{'id':'10','name':'三　国'},
{'id':'11','name':'びわこ'},
{'id':'12','name':'住之江'},
{'id':'13','name':'尼　崎'},
{'id':'14','name':'鳴　門'},
{'id':'15','name':'丸　亀'},
{'id':'16','name':'児　島'},
{'id':'17','name':'宮　島'},
{'id':'18','name':'徳　山'},
{'id':'19','name':'下　関'},
{'id':'20','name':'若　松'},
{'id':'21','name':'芦　屋'},
{'id':'22','name':'福　岡'},
{'id':'23','name':'唐　津'},
{'id':'24','name':'大　村'}
]

let __piano_first_call = true;

export default {
    data () {
      return {
        JO_ID_NAMES: _jo_id_names,
        API_BASE_URL: _api_base_url,
        RACER_PHOTO_BASE_URL: _racer_photo_base_url,
      }
    },
    methods:{

      pianoSend:function(...theArgs){
        window.tp = window.tp || [];
        let tp = window.tp;

        if(__piano_first_call){
          __piano_first_call = false;
          let args =  theArgs|| []
          window.PianoESPConfig= {id:40};
          tp.push(["setTags",(["ボートレース"]).concat(args)]);
          tp.push(["setAid",'oYdNX56vpj']);
          tp.push(["setCxenseSiteId", "1144233404304068691"]);
          tp.push(["setSandbox", false]);
          tp.push(["setUseTinypassAccounts", false]);
          tp.push(["setUsePianoIdUserProvider", false ]);
          tp.push(["setUsePianoIdLiteUserProvider", true]);
          tp.push(["setEndpoint", 'https://buy-ap.piano.io/api/v3']);
          tp.push(["setPianoIdUrl", 'https://id-ap.piano.io']);
          tp.push(["setEspEndpoint", 'https://api-esp-ap.piano.io']);
          tp.push(["init", function() {window.tp.experience.init();}]);
          (function(a){var b=document.createElement("script");b.type="text/javascript";b.async=!0;b.src=a;a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(b,a)})("//code.piano.io/api/tinypass.min.js");
        }
        
        if(window.cxSendEvents){
          window.cxSendEvents();
        }
    
        tp.push(["init", function() {window.tp.experience.execute();}]);


        }
      }

      
 }
                     