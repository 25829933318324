<template>
<div class="user-my-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <router-link :to="{name:'UserMyPage'}"> ユーザー {{ $store.getters.user != null ? $store.getters.user.nickname:'' }}</router-link>
    </li>
    <li>
      <span class="show-for-sr">Current: </span>  ニックネーム変更
    </li>
  </ul>
</nav>
  <div class="register-form">
  <h1>ニックネーム変更</h1>

  <div class="callout user-info nickname">
  <h2 class="user-info__title">現在のニックネーム</h2>
  {{ $store.getters.user != null ? $store.getters.user.nickname:'' }}
 </div>

<p><strong>新しいニックネーム入力して下さい。</strong></p>

    <div v-if="error_message != ''" class="callout alert">
        <p>{{ error_message }}</p>
    </div>

  <form @submit.prevent="send">
  
  <label>新しいニックネーム
  <input type="text" placeholder="ニックネーム" v-model="nickname" required>
  </label>
  

  <button class="button" type="submit">変更</button>
  </form>
</div>
</div>

</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserChAttr',
  mixins: [pianoSend],
  data:function(){
    return{
      nickname:'',
      error_message:'',
      message:'',
      send_done:false,
      title:'ニックネーム変更'
    }
  },

  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },

  created:function(){
    this.$emit('updateHead');
    this.pianoSend('ユーザーページ','ニックネーム変更');
  },
 
  methods: {
    send:function(){
      let loader = this.$loading.show({});
        let attrs = {'nickname':this.$data.nickname};
        this.$cognito.updateAttributes(attrs).then((result) =>{
          loader.hide();
            if(result){
                this.$data.send_done = true;
                this.$router.replace('/user');
            }
        }).catch((err)=>{  // eslint-disable-line
          loader.hide();
            
            this.$data.error_message = 'エラーが発生しました。';
        }) 
    }
  }
}
</script>
<style scoped>
.callout.user-info{
    border:none;
    background-color: #EFEFEE;
    margin-bottom:30px;
}
</style>