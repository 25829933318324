<template>
<div class="user-register-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> パスワード再設定
    </li>
  </ul>
</nav>
  <div class="register-form">
    <h1>{{ title }}</h1>
    <p>パスワードの再設定を行うことができます。<br />ご登録のメールアドレスを入力し、送信ボタンを押してください。</p>
    <div v-if="!send_done">
        <div v-show="error_message != ''" class="callout alert">
            <p>{{ error_message }}</p>
        </div>
    <form @submit.prevent="send">

    <label>メールアドレス
    <input type="email" placeholder="メールアドレス" v-model="mail" required>
    </label>

    <button class="button" type="submit">送信</button>
    </form>
    </div>
    <div class="callout success" v-else>
        <p>ご登録のメールアドレスにパスワード再設定用の確認コードをお送りしました。</p>
        <p>確認コードをお確かめのうえ、下記のリンクのページからパスワードを変更してください。</p>
        <p><strong style="text-decoration:underline;"><router-link :to="{name:'UserFChPassword'}">▶︎パスワード再設定ページ</router-link></strong></p>
        
    </div>
  </div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserForgetPassword',
    mixins: [pianoSend],
  data:function(){
    return{
      mail:'',
      title:'パスワード再設定',
      error_message:'',
      send_done:false
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },

  created:function(){
    this.$emit('updateHead')
    this.pianoSend('ユーザーページ','パスワード再設定 メール送信');
  },
     
  methods: {
    send:function(){
      if(this.$data.mail != ''){
          let loader = this.$loading.show({});
          this.$cognito.forgetPassword(this.$data.mail).then((result)=>{
              loader.hide();
              if(result){
                this.$data.send_done = true;
              }
          }).catch((err)=>{ // eslint-disable-line
             loader.hide();
             this.$data.error_message = 'エラーが発生しました。';
          })
      }else{
          this.$data.error_message = 'メールアドレスを入力してください。';
      }
    }
  }
}
</script>
