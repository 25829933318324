<template>
<div id="app">
        <!-- Start Top Bar -->
        <header class="global-header top-bar">
          <div class="top-bar-left">
            <ul class="menu">
               <li><router-link :to="{ name: 'Index'}"><img class="header-logo" src="/images/large_logo.svg" alt="BOATNAVI報知"></router-link></li>
            </ul>
          </div>
          <div class="top-bar-center">
          <ul class="menu">
          <li><router-link :to="{ name: 'RacerRanking',params:{kbn:'all'} }" class="ranking-link">賞金ランキング</router-link></li>
          </ul>
          </div>
          <div class="top-bar-right">
            
            <ul class="dropdown menu">
              <li v-if="$store.getters.user !=null "><a class="user-menu"><span>{{ $store.getters.user.nickname }}さん</span></a>
                   <ul class="menu">
                      <li><router-link  :to="{ name: 'UserMyPage'}" ><span>マイページ</span></router-link></li>
                      <li><a v-on:click="logout()"><span>ログアウト</span></a></li>
                    </ul>
              </li>
              <li v-else><router-link :to="{ name: 'UserLogin'}" class="login-button"><span>ログイン</span></router-link></li>
            </ul>
          </div>
        </header>
        <!-- End Top Bar -->
<div class="main-wrapper">
<router-view></router-view> 
</div>

    <!-- built files will be auto injected -->
  <footer class="callout large global-footer">
          <ul class="menu">
            <li><a href="https://www.hochi.co.jp/policy/" target="_blank">サイトポリシー</a></li>
            <li><a href="https://www.hochi.co.jp/privacy/" target="_blank">個人情報について</a></li>
            <li><router-link :to="{name:'UserdataExternalTransmissionPage'}" >データ収集と利用について</router-link></li>
            <li><router-link :to="{name:'HowToPage'}" >ボートナビの使い方</router-link></li>
            <li><router-link :to="{name:'KiyakuPage'}" >会員規約</router-link></li>
          </ul>
        <div class="copyright">
          見出し、記事、写真の無断転載を禁じます。Copyright © 2021 The Hochi Shimbun.
        </div>
    </footer>
</div>
</template>

<script>
export default {
  data:function(){
    return{
      user:null
    }
  },
  head: {
   title: function(){
     return{
      inner: 'BOATNAVI報知 - ボートレース専門サイト　ボートナビ報知'
     }
   },
  meta: function () {
    return [
      { property: 'og:title', content: 'BOATNAVI報知 - ボートレース専門サイト　ボートナビ報知' },
      { property: 'og:description', content: 'スポーツ報知が運営するボートレース専門サイト「ボートナビ報知」。全場、全レースで予想に必須なデータのほか、ＡＩ予想やスポーツ報知が開発した分析データ報知スコープがご覧頂けます。' },
      { name: 'description', content:  'スポーツ報知が運営するボートレース専門サイト「ボートナビ報知」。全場、全レースで予想に必須なデータのほか、ＡＩ予想やスポーツ報知が開発した分析データ報知スコープがご覧頂けます。' }
    ]
  }
  },

  created:function(){
   
  },
  mounted:function(){

  },

  computed:{
  },
  watch:{
  },
  methods:{
     logout:function(){
       this.$cognito.logout();
       this.$store.commit('setUser', null);
       if(this.$route.name == 'UserMyPage'||
       this.$route.name == 'UserConfirmChEmail'||
       this.$route.name == 'UserChEmail'||
       this.$route.name == 'UserChPassword'||
       this.$route.name == 'UserChAttr'){
         this.$router.replace('/login')
       }
     }
  },

  
}
</script>

<style lang="scss">
@import "./assets/scss/_settings.scss";
@import "./assets/scss/app.scss";   
</style>

