<template>
<div class="shinnyuzu">
<div class="pylon-box"><img src="/images/boat_icons/pylon.svg" alt="" class="pylon" /> IN</div>
<img v-for="(teiban,index) of course" :key="teiban" v-show="teiban!=null" class="boat-icon" :src="'/images/boat_icons/b'+teiban+'.svg'" :alt="'コース'+(index+1)+' 艇番'+teiban"   />
</div>
</template>
<script>
export default{
  props: {
    course: {
      type: Array,
      required: true,
    },
  },
  methods:{
  },
  mounted () {
  }
}
</script>
<style scoped>
.boat-icon{
  display:block;
  width:60px;
  height:25px;
  margin:15px auto;
}
.pylon-box{
  text-align:center;
  color:#FFF;
  font-size:12px;
  font-weight: bold;
  margin-top:16px;
}
.pylon{
   width:40px;
   display: inline-block;
   margin-left: 25px;
}

.shinnyuzu{
  background-image: linear-gradient(180deg, #35C2FF 0%, #A9F1FF 100%);
  padding:20px;
  min-height:350px;
}
</style>