<template>
<div>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li><router-link :to="{name:'DayRaceList',params:{date:date}}"> レース一覧 {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</router-link></li>
    <li><router-link :to="{name:'JoDateIndex',params:{date:date,jo:jo}}">{{ jo_name }}</router-link></li>
    <li><span class="show-for-sr">Current: </span>{{race.rno}}R 結果</li>
  </ul>
</nav>
<nav class="jo-date-nav">
  <ul class="menu jo-date-menu" v-if="nidate_length > 0" >
    <li v-for="ni of nidate_length" :key='ni' >
      <span v-if="header['nidate'+ni+'_ready']"><router-link class="button" :class="race.nj == ni ? 'current':''" :to="{name:'JoDateIndex',params:{jo:jo,date:race['nidate'+ni].replace(/\-/g,'')}}">{{  Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</router-link></span>
      <span v-else><span class="button" disabled>{{   Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</span></span>
    </li>
  </ul>
</nav>
<nav class="race-nav">
  <ul class="menu race-num-menu" v-if="nidate_length > 0" >
    <li v-for="ri in 12" :key='ri' >
      <router-link class="button" v-if="ri != race.rno" :to="{name:'Result',params:{date:date,jo:jo,race:ri}}">{{ ri }}R</router-link>
      <span class="button current" v-else>{{ ri }}R</span>
    </li>
  </ul>
</nav>
  <div class="race-header">
    <h1 class="race-h1">
      <div class="jo-name">{{ jo_name }}</div>
      <div class="race-number">{{ race.rno }}R</div>
      <div class="race-date">{{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</div>
       <div class="race-ktitl">{{ race.ktitl }}</div>
    </h1>

    <div class="rmei-rsname">
      <span class="race-rmei">{{ race.rmei }}</span>
    </div>
  </div>
  <div class="race-sh-times">
    <div>締切時刻 {{ race.stime ? race.stime.split('T')[1].slice( 0, -3 ):'-' }}</div>
    <!-- <div>出走時刻 {{ race.htime ? race.htime.split('T')[1].slice( 0, -3 ):'' }}</div> -->
  </div>

<Racenav :current = "'result'" :racedate = "date" :joid= "jo" :rno = "race_num" :is_result_available = "1" ></Racenav>
<div class="grid-x grid-padding-x result-grid"  v-if="race.rstop == 0">
<div class="cell small-12 large-6">  
<div class="table-scroll">
<table class="shussou-table">
<thead>
  <tr>
    <th>着</th>
    <th>枠</th>
    <th>レーサー</th>
    <th>レースタイム</th>
    <th>進入</th>
    <th>ST</th>
    <th>決まり手</th>
  </tr>
</thead>
<tbody>
  <template v-for="item in chakujun">
  <tr :key="`m_${item.name}`">
    <td>{{ item.chk_text }}</td>
    <td :class="'waku-'+item.teiban">{{ item.teiban }}</td>
    <td><router-link class="profile-link" :to="{name:'RacerProfile',params:{toban:item.toban,tabname:'kibetsu'}}">{{ item.name }}</router-link><br /> <span class="sub-item"><span class="f-hearts-icon" v-if="item.seibetsu==2">&hearts;</span>{{ item.toban }}</span></td>
    <td>{{ item.rtime_text }}</td>
    <td>{{ item.cs }}</td>
    <td>{{ item.chk_text=='Ｆ'?item.chk_text:'' }}{{ item.st }}</td>
    <td>{{ item.kim }}</td>

  </tr>
  </template>
</tbody>
</table>
</div>
</div>

<div class="cell small-12 large-6">  
<div class="table-scroll">
<table class="shussou-table">
  <thead>
  <tr>
    <th>勝式</th>
    <th>組番</th>
    <th>払戻金</th>
    <th>人気</th>
  </tr>
  </thead>
  <tbody v-if="Object.keys(haraimodoshi).length">
  <tr>
    <td :rowspan="2">3連単</td>
    <td class="h-item" v-html="formatKumi(haraimodoshi['rt3'][0].kumi,'-')"></td>
    <td class="h-item">{{ formatKingaku(haraimodoshi['rt3'][0].haraimodosi) }}</td>
    <td class="h-item">{{ haraimodoshi['rt3'][0].ninkijun ? haraimodoshi['rt3'][0].ninkijun:'' }}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['rt3'][1] ? formatKumi(haraimodoshi['rt3'][1].kumi,'-')  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['rt3'][1] ? formatKingaku(haraimodoshi['rt3'][1].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['rt3'][1] && haraimodoshi['rt3'][1].ninkijun ? haraimodoshi['rt3'][1].ninkijun:'' }}</td>
  </tr>

  <tr>
    <td :rowspan="2">3連複</td>
    <td class="h-item" v-html="formatKumi(haraimodoshi['rf3'][0].kumi,'=')"></td>
    <td class="h-item">{{ formatKingaku(haraimodoshi['rf3'][0].haraimodosi) }}</td>
    <td class="h-item">{{ haraimodoshi['rf3'][0].ninkijun ? haraimodoshi['rf3'][0].ninkijun : ''}}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['rf3'][1] ? formatKumi(haraimodoshi['rf3'][1].kumi,'=')  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['rf3'][1] ? formatKingaku(haraimodoshi['rf3'][1].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['rf3'][1] && haraimodoshi['rf3'][1].ninkijun ? haraimodoshi['rf3'][1].ninkijun:'' }}</td>
  </tr>
  
  <tr>
    <td :rowspan="2">2連単</td>
    <td class="h-item" v-html="formatKumi(haraimodoshi['rt'][0].kumi,'-')"></td>
    <td class="h-item">{{ formatKingaku(haraimodoshi['rt'][0].haraimodosi) }}</td>
    <td class="h-item">{{ haraimodoshi['rt'][0].ninkijun ? haraimodoshi['rt'][0].ninkijun : ''}}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['rt'][1] ? formatKumi(haraimodoshi['rt'][1].kumi,'-')  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['rt'][1] ? formatKingaku(haraimodoshi['rt'][1].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['rt'][1] && haraimodoshi['rt'][1].ninkijun ? haraimodoshi['rt'][1].ninkijun:'' }}</td>
  </tr>
  <tr>
    <td :rowspan="2">2連複</td>
    <td class="h-item" v-html="formatKumi(haraimodoshi['rf'][0].kumi,'=')"></td>
    <td class="h-item">{{ formatKingaku(haraimodoshi['rf'][0].haraimodosi) }}</td>
    <td class="h-item">{{ haraimodoshi['rf'][0].ninkijun ? haraimodoshi['rf'][0].ninkijun:'' }}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['rf'][1] ? formatKumi(haraimodoshi['rf'][1].kumi,'=')  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['rf'][1] ? formatKingaku(haraimodoshi['rf'][1].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['rf'][1] && haraimodoshi['rf'][1].ninkijun ? haraimodoshi['rf'][1].ninkijun:'' }}</td>
  </tr>

  <tr>
    <td :rowspan="5">拡連複</td>
    <td class="h-item" v-html="formatKumi(haraimodoshi['krf'][0].kumi,'=')"></td>
    <td class="h-item">{{ formatKingaku(haraimodoshi['krf'][0].haraimodosi) }}</td>
    <td class="h-item">{{ haraimodoshi['krf'][0].ninkijun ? haraimodoshi['krf'][0].ninkijun : ''}}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['krf'][1] ? formatKumi(haraimodoshi['krf'][1].kumi,'=')  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['krf'][1] ? formatKingaku(haraimodoshi['krf'][1].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['krf'][1] &&  haraimodoshi['krf'][1].ninkijun ? haraimodoshi['krf'][1].ninkijun:'' }}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['krf'][2] ? formatKumi(haraimodoshi['krf'][2].kumi,'=')  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['krf'][2] ? formatKingaku(haraimodoshi['krf'][2].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['krf'][2] && haraimodoshi['krf'][2].ninkijun ? haraimodoshi['krf'][2].ninkijun:'' }}</td>
  </tr>
   <tr>
    <td class="h-item" v-html="haraimodoshi['krf'][3] ? formatKumi(haraimodoshi['krf'][3].kumi,'=')  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['krf'][3] ? formatKingaku(haraimodoshi['krf'][3].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['krf'][3] && haraimodoshi['krf'][3].ninkijun ? haraimodoshi['krf'][3].ninkijun:'' }}</td>
  </tr>
   <tr>
    <td class="h-item" v-html="haraimodoshi['krf'][4] ? formatKumi(haraimodoshi['krf'][4].kumi,'=')  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['krf'][4] ? formatKingaku(haraimodoshi['krf'][4].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['krf'][4] && haraimodoshi['krf'][1].ninkijun ? haraimodoshi['krf'][1].ninkijun:'' }}</td>
  </tr>
  <tr>
    <td :rowspan="2">単勝</td>
    <td class="h-item" v-html="formatKumi(haraimodoshi['t'][0].kumi)"></td>
    <td class="h-item">{{ formatKingaku(haraimodoshi['t'][0].haraimodosi) }}</td>
    <td class="h-item">{{ haraimodoshi['t'][0].ninkijun ? haraimodoshi['t'][0].ninkijun:''}}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['t'][1] ? formatKumi(haraimodoshi['t'][1].kumi)  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['t'][1] ? formatKingaku(haraimodoshi['t'][1].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['t'][1] && haraimodoshi['t'][1].ninkijun ? haraimodoshi['t'][1].ninkijun:'' }}</td>
  </tr>
  <tr>
    <td :rowspan="3">複勝</td>
    <td class="h-item" v-html="formatKumi(haraimodoshi['f'][0].kumi)"></td>
    <td class="h-item">{{ formatKingaku(haraimodoshi['f'][0].haraimodosi) }}</td>
    <td class="h-item">{{ haraimodoshi['f'][0].ninkijun ? haraimodoshi['f'][0].ninkijun : ''}}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['f'][1] ? formatKumi(haraimodoshi['f'][1].kumi)  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['f'][1] ? formatKingaku(haraimodoshi['f'][1].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['f'][1] && haraimodoshi['f'][1].ninkijun ? haraimodoshi['f'][1].ninkijun:'' }}</td>
  </tr>
  <tr>
    <td class="h-item" v-html="haraimodoshi['f'][2] ? formatKumi(haraimodoshi['f'][2].kumi)  :'' "></td>
    <td class="h-item" >{{ haraimodoshi['f'][2] ? formatKingaku(haraimodoshi['f'][2].haraimodosi):'' }}</td>
    <td class="h-item" >{{ haraimodoshi['f'][2] && haraimodoshi['f'][2].ninkijun ? haraimodoshi['f'][2].ninkijun:'' }}</td>
  </tr>

  </tbody>
</table>
</div>
</div>
<div class="cell small-12 medium-4">
  <div class="henkan-block start-info-block">
  <div class="henkan-block__title">スタート</div>
  <StCourseZu :st_list="st_list" :kimarite ="kimarite"></StCourseZu>
  </div>
  </div>
<div class="cell small-12 medium-4">
<div class="kisyo-block">
  <div class="grid-x">
  
  <dl class="small-4">
  <dt>天候</dt>
  <dd>{{ race.tenko }}</dd>
  </dl>

  <dl class="small-4">
  <dt>気温</dt>
  <dd>{{ race.kion }}℃</dd>
  </dl>

  <dl class="small-4">
  <dt>風向</dt>
  <dd>{{ race.fuko1 }}({{ race.fuko2 }})</dd>
  </dl>

  <dl class="small-4">
  <dt>風速</dt>
  <dd>{{ race.fusoku }}m</dd>
  </dl>

  <dl class="small-4">
  <dt>水温</dt>
  <dd>{{ race.suion }}℃</dd>
  </dl>

   <dl  class="small-4">
  <dt>波高</dt>
  <dd>{{ race.hako }}cm</dd>
  </dl>
  </div>
</div>
</div>

<div class="cell small-12 medium-4">  
<div class="henkan-block">
<div class="henkan-block__title">返還</div>
<div class="henkan-block__content">
<span v-if="race.henkan != '00000000'">
  <template v-for="(tei,index) in Array.from(race.henkan)">
    <div :key="`t_${index}`" v-if="tei == 1">艇番 {{index+1}}</div>
  </template>
</span>
 </div>
</div>
</div>
</div>
<div v-else>
  <div class="rstop-name" v-if="race.rstop_name">レース中止{{ race.rstop_name }}</div>
</div>
</div>
</template>
<script>
import JO_ID_NAMES from '../mixins/PrefsMixin';
import API_BASE_URL  from '../mixins/PrefsMixin';
import Racenav from '../components/Racenav';
import StCourseZu from '../components/StCourseZu'

export default {
  mixins: [ JO_ID_NAMES,API_BASE_URL ],
  components: {
    Racenav,
    StCourseZu
  },
  name: 'Result',
    data:function(){
    return {
    date:'',
    jo:'',
    header:{},
    race_num:0,
    race:{},
    chakujun:[],
    nidate_length:0,
    jo_name:'',
    title:'結果',
    haraimodoshi:{},
    tommrow:new Date(),
    today:new Date(),
    st_list:[],
    kimarite:[]
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created:function(){
    this.$data.date = this.$route.params['date'];
    this.$data.race_num = this.$route.params['race'];
    this.$data.jo = this.$route.params['jo'];
    this.$data.st_list = [];
    this.$data.kimarite = [];
    //this.$data.tommrow.setDate(this.$data.tommrow.getDate() + 1);

    this.getData();
  },
  beforeRouteUpdate(_to, _from, _next) {
    if(_to.name == 'Result'){
      this.$data.date = _to.params['date'];
      this.$data.race_num = _to.params['race'];
      this.$data.jo = _to.params['jo'];
      this.$data.st_list = [];
      this.$data.kimarite = [];
      this.changeRace();
    }
    _next();
  },
   methods: {
    formatKumi(kumi,kugiri=''){
      let kumi_array = Array.from(kumi);
      if(kumi[0] == 'M'){
        switch(kumi[1]){
          case '1':
            return '特払い'
             case '2':
            return '全艇返還'
             case '3':
            return '取扱外'
           case '4':
            return '発売なし' 
          case '5':
            return '不成立'
          case '6':
            return '同着多数'
          default:
            return ''
        }
      }
 
      let h_html ='';
      for(var i=0;i<kumi_array.length;i++){
        h_html += '<span class="haraimodoshi-waku waku-'+ kumi_array[i] +'">'+ kumi_array[i] +'</span>';
        if(i < kumi_array.length-1 ){
           h_html += kugiri;
        }
      }

      return h_html;

    },
    formatKingaku(kingaku){
      if(kingaku == 0){
        return '';
      }
     return '¥'+ kingaku.toLocaleString();
    },
    getProp(object,propertyPath){
  
      if (!object) { return undefined }
      let result = object;
      const propertyArray = propertyPath.split('.');
      for (let i = 0; i <= propertyArray.length - 1; i += 1) {
        if (propertyArray[i] === '' ) { return undefined; }
        if (typeof result[propertyArray[i]] === 'undefined') { return undefined; }
        result = result[propertyArray[i]];
      }
      
      return result;
    },
    changeRace(){
      this.$data.race ={};
      this.$data.shussou_senshu=[];
      this.$data.nidate_length=0;
      this.getData();
    },
      getData() {
          this.axios.get(this.API_BASE_URL + 'result/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num)
          .then((response) => {
          
            //console.log(response.data)
            this.$data.race = response.data.race;
            this.$data.header = response.data.header;
            
            if(this.$data.race == null){
              this.$router.replace({ name:'Shussou', parmes: { date: this.$data.date,jo:this.$data.jo,race:this.$data.race_num}});
              return;
            }

            this.$data.chakujun = response.data.chakujun;
            this.$data.jo_name = this.JO_ID_NAMES[Number(this.$data.jo) - 1].name
            this.$data.title = '結果 - '+ this.$data.jo_name +' '+ this.$data.race.rno +'R '+  this.$data.race.hdate +' '+this.$data.race.ktitl
            this.$emit('updateHead')
            this.pianoSend('結果', this.$data.jo_name,this.$data.race.rno +'R',this.$data.race.hdate,this.$data.race.ktitl);

            let st_list = [];
            let kimarite = [];
            for(let i=0;i<this.$data.chakujun.length;i++){
              let sst = this.$data.chakujun[i].chk_text == 'Ｆ'? -this.$data.chakujun[i].st : this.$data.chakujun[i].st;
              let scs = this.$data.chakujun[i].cs;
              let teiban = this.$data.chakujun[i].teiban;
              let kim = this.$data.chakujun[i].kim;
              if(Number.isInteger(scs)){
                st_list[scs-1] = {teiban:teiban,sst:sst};
                kimarite[scs-1] = kim;
              }
            }
            this.$data.st_list = st_list;
            this.$data.kimarite =  kimarite; 


            for(let i=1;i<=9;i++){
              if(this.$data.race['nidate'+i]){
                this.$data.nidate_length = i;
              }
            }

            let haraimodoshi = response.data.haraimodosi;
            if(haraimodoshi.length > 0){
              let haraimoshi_obj = {};
              for(var hi = 0;hi<haraimodoshi.length;hi++){
                haraimodoshi[hi].kakesiki;
                if(!haraimoshi_obj[haraimodoshi[hi].kakesiki]){
                  haraimoshi_obj[haraimodoshi[hi].kakesiki] = []
                }
                  haraimoshi_obj[haraimodoshi[hi].kakesiki].push(
                    {
                      'kumi':haraimodoshi[hi].kumi,
                      'haraimodosi':haraimodoshi[hi].haraimodosi,
                      'ninkijun':haraimodoshi[hi].ninkijun
                    }
                  );
              }
              this.$data.haraimodoshi =  haraimoshi_obj;
              //console.log(this.$data.haraimodoshi );
            }

          })
          .catch((e) => { // eslint-disable-line
           
            this.$router.replace({ name:'NotFound'});
          });
      }
   }

}
</script>
<style scoped>

.rstop-name{
  text-align:center;
  color:#CC3300;
  padding:50px 0;
  font-size:21px;
}
.start-info-block{
  margin-bottom:40px;
}

</style>