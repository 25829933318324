<template>
<div class="user-my-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
        <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <router-link :to="{name:'UserMyPage'}"> ユーザー  </router-link>
    </li>
    <li>
      <router-link :to="{name:'UserChEmail'}">メールアドレス変更</router-link>
    </li>
    <li>
      <span class="show-for-sr">Current: </span>  確認
    </li>
  </ul>
</nav>
  <div class="register-form">
  <h1>メールアドレス変更 確認</h1>

<div v-if="!send_done">
  <div class="callout user-info mail">
  <h2 class="user-info__title">メールアドレス</h2>
  {{ $store.getters.user.email }}
 </div>

<p><strong>上記のアドレスにお送りした、確認コードを入力してください。</strong></p>

    <div v-if="error_message != ''" class="callout alert">
        <p>{{ error_message }}</p>
    </div>

  <form @submit.prevent="send">
  
  <label>確認コード
  <input type="text" placeholder="確認コード" v-model="confirm_code" required>
  </label>
  
  <button class="button" type="submit">送信</button>
  </form>
</div>
<div v-else>
    <div class="callout success">
        <p>確認が完了しました。</p>
    </div>
</div>    
  </div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserConfirmChEmail',
    mixins: [pianoSend],
  data:function(){
    return{
      confirm_code:'',
      error_message:'',
      message:'',
      send_done:false,
      title:'メールアドレス変更 確認'
    }
  },

  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },

  created:function(){
    
    this.$emit('updateHead');
    this.pianoSend('ユーザーページ','メールアドレス変更 確認')
  },
 
  methods: {

    send:function(){
       let loader = this.$loading.show({});
        this.$cognito. verifyAttribute(this.$data.confirm_code).then((result) =>{
           loader.hide();
            if(result){
                this.$data.send_done = true;
                this.$router.replace('/user');
            }
        }).catch((err)=>{ // eslint-disable-line
            //console.log(err);
            loader.hide();
            if(err.name == 'CodeMismatchException'){
                this.$data.error_message = '確認コードが正しくありません。もう一度ご入力下さい。'
            }else{
                this.$data.error_message = 'エラーが発生しました。確認コードをもう一度ご入力下さい。'
            }
        }) 
    }

  }
}
</script>
<style scoped>
.callout.user-info{
    border:none;
    background-color: #EFEFEE;
    margin-bottom:30px;
}
</style>