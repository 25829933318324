<template>
<div class="infomation-page">
<nav class="global-nav" role="navigation">
  <ul>
    <li>
      <router-link class="button dayrace-button" :to="{name:'Index'}">トップ</router-link>
    </li>
    <li>
      <router-link class="button dayrace-button" :to="{name:'DayRaceList',params:{date:today_str}}">本日のレース</router-link>
    </li>
  </ul>
</nav>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> お知らせ: {{ title }}
    </li>
  </ul>
</nav>    
<div class="article-wrapper information-wrapper" v-html="content"></div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name :'Information',
  mixins: [pianoSend],
  data:function(){
    return {
      date:'',
      title:'',
      content:'',
      today:'',
      today_str:'',
      id:''
    }
  },
   head:function(){
    return {
      title: function(){
        return {
          inner:  this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created(){
      this.$data.id = this.$route.params['id'];
      this.getData();

      this.$data.today = new Date();
      this.$data.today_str = '' + this.$data.today.getFullYear() + ('0'+(this.$data.today.getMonth()+1)).slice(-2) + ('0'+this.$data.today.getDate()).slice(-2);
  
  },
  beforeRouteUpdate(_to, _from, _next) {
      
        if(_to.name == 'Article' && _from.name == 'Article'){
            this.$data.id =  _to.params['id'];
            this.getData();
        }

      _next();
  },
  methods:{
     getData(){
         let loader = this.$loading.show({});
        this.axios.get('/information_contents/'+this.$data.id+'.html')
          .then((response) => {
             loader.hide();
            this.$data.content = response.data;

            let content_dom = document.createElement('div');
            content_dom.innerHTML = this.$data.content;
            this.$data.title = content_dom.getElementsByTagName('h1')[0].innerText;

            this.$emit('updateHead');
            this.pianoSend('お知らせ',this.$data.title);
          })
          .catch((e) => { // eslint-disable-line
            
             loader.hide();
            this.$router.replace({ name:'NotFound'});
          });
     }
  }
}
</script>
<style lang="scss">
.article-wrapper.information-wrapper{

     .article-header{
        padding:20px 10px 10px 10px;
        border-bottom:1px solid #c5f2fd;
        background:none;
        @media screen and (max-width:428px) { 
            background:none;
            padding-left:10px;
            padding-right:10px;
        }
     }

    .article-date{
        padding:15px 10px;
        @media screen and (max-width:428px) { 
            padding:15px 10px;
        }
    }


    .article-body{
        padding:20px 10px;
        clear:both;
    }


    .image-wrapper{
        
        width:100%;
        clear:both;

        figure{

            img{
               
                max-height:none;
                margin:0 auto;
                 @media screen and (max-width:428px) { 
                     max-height:none;
                 }
            }

        }

         &.left{
            width:300px;
            float:left;
            margin-right:30px;
            figure{
                img{
                    max-height:300px;
                    @media screen and (max-width:428px) { 
                        max-height:300px;
                    }
                }
            }
            @media screen and (max-width:428px) { 
              float:none;
               width:100%;
               margin-right:0;
            }


 
        }
        &.right{
            width:300px;
            margin-left:30px;
            float:right;
            @media screen and (max-width:428px) { 
              float:none;
               width:100%;
              margin-left:0;
            }
            
            figure{
                img{
                    max-height:300px;
                        @media screen and (max-width:428px) { 
                        max-height:300px;
                    }
                }
            }
        }

         margin-bottom:30px;
        @media screen and (max-width:428px) { 
            width:100%;
             margin-bottom:20px;
        }
       


    }
}

    
</style>