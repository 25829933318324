<template>
<div class="user-my-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <router-link :to="{name:'UserMyPage'}"> ユーザー {{ $store.getters.user.nickname }} </router-link>
    </li>
    <li>
      <span class="show-for-sr">Current: </span>  メールアドレス変更
    </li>
  </ul>
</nav>
  <div class="register-form">
  <h1>メールアドレス変更</h1>

<div v-if="!send_done">
  <div class="callout user-info mail">
  <h2 class="user-info__title">現在のメールアドレス</h2>
  {{ $store.getters.user.email }}
 </div>

<p><strong>新しいメールアドレスを入力して下さい。</strong></p>

    <div v-if="error_message != ''" class="callout alert">
        <p>{{ error_message }}</p>
    </div>
  <form @submit.prevent="send">
  
  <label>新しいメールアドレス
  <input type="email" placeholder="メールアドレス" v-model="mail" required>
  </label>
  

  <button class="button" type="submit">変更</button>
  </form>
</div>
<div v-else>
  <div class="callout success">
    <p>新しく入力されたメールアドレスに確認コードをお送りしました。確認コードを下記のページよりご入力下さい。</p>
    <p><router-link :to="{name:'UserConfirmChEmail'}">▶︎確認コード入力</router-link></p>
  </div>
</div>


  </div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserChEmail',
    mixins: [pianoSend],
  data:function(){
    return{
      mail:'',
      error_message:'',
      message:'',
      send_done:false,
      title:'メールアドレス変更'
    }
  },

  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },

  created:function(){
    
    this.$emit('updateHead');
    this.pianoSend('ユーザーページ','メールアドレス変更');
  },
 
  methods: {

    send:function(){
      let loader = this.$loading.show({});
        this.$cognito.updateEmailAddress (this.$data.mail).then((result) =>{
            if(result){
                this.$data.send_done = true;
                loader.hide();
            }
        }).catch((err)=>{ // eslint-disable-line
            //console.log(err);
            loader.hide();
            if(err.name == 'AliasExistsException'){
                this.$data.error_message = 'すでに登録されているアドレスです。';
            }else{
                this.$data.error_message = 'エラーが発生しました。';
            }
        }) 
    }

  }
}
</script>
<style scoped>
.callout.user-info{
    border:none;
    background-color: #EFEFEE;
    margin-bottom:30px;
}
</style>