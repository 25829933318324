<template>
<div class="user-register-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> ユーザー登録 確認
    </li>
  </ul>
</nav>
  <div class="register-form">
  <h1>{{ title }}</h1>
  
  
  <div v-if="!send_done">
  <p>登録の確認用メールをお送りしました。メールに記載されている確認コードを入力して登録を完了してください。</p>
    <div v-if="error_message != ''" class="callout alert">
        <p>{{ error_message }}</p>
    </div>
    <form @submit.prevent="confirm">
    <label>メールアドレス
    <input type="text" placeholder="メールアドレス" v-model="mail" required>
    </label>
    <label>確認コード
    <input type="text" placeholder="確認コード" v-model="confirm_code" required>
    </label>
    <button class="button" type="submit">登録</button>
    </form>
  </div>
  <div v-else>
    <div class="callout success">
        <p>登録が完了しました。ログインページよりログインしてください。</p>
        <p><router-link :to="{name:'UserLogin'}">▶︎ログインページ</router-link></p>
    </div>
  </div>
  </div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserConfirm',
    mixins: [pianoSend],
  data:function(){
    return{
      mail:'',
      confirm_code:'',
      title:'ユーザー登録 確認コード入力',
      error_message:'',
      send_done:false
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
mounted () {
    this.$emit('updateHead');
},
  created:function(){
    this.$emit('updateHead');
    this.pianoSend('ユーザー登録','確認コード入力');
  },
 
  methods: {
    confirm:function(){
      let loader = this.$loading.show({});

      this.$cognito.confirmation(this.$data.mail,this.$data.confirm_code).then(( result )=>{ // eslint-disable-line
          //console.log(result);
          loader.hide();
          this.$data.send_done = true;
      }).catch((err) => { // eslint-disable-line
          //console.log(err)
          loader.hide();
          if(err.name == 'ExpiredCodeException' && err.name == 'CodeMismatchException' || err.name == 'InvalidParameterException'){
             this.$data.error_message = 'メールアドレスまたは確認コードが正しくありません。'
          }else if(err.name == 'ExpiredCodeException'){
              this.$data.error_message = '確認コードが期限切れです。もう一度登録してください。'
            }else{
             this.$data.error_message = 'エラーが発生しました。'
          }
         
      })
    }
  }
}
</script>

<style scoped>
.register-form{
  max-width:600px;
  margin:40px auto;
  padding:20px;
}
</style>