<template>
<div class="ranking-page">
<nav class="global-nav" role="navigation">
  <ul>
    <li>
      <router-link class="button dayrace-button" :to="{name:'Index'}">トップ</router-link>
    </li>
  </ul>
</nav>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      獲得賞金ランキング 
    </li>
    <li>
      <span class="show-for-sr">Current: </span> {{ kbn_name }}
    </li>
  </ul>
</nav>
<div class="ranking-wrapper">
<h1 class="ranking-title">獲得賞金ランキング {{ kbn_name }}</h1>
<ul class="tabs ranking-contents-tabs">
<li class="tabs-title" :class="kbn=='all' ? 'is-active':''"><router-link :to="{name:'RacerRanking',params:{kbn:'all'}}">総合</router-link></li>
<li class="tabs-title" :class="kbn=='danshi' ? 'is-active':''"><router-link :to="{name:'RacerRanking',params:{kbn:'danshi'}}">男子</router-link></li>
<li class="tabs-title" :class="kbn=='jyoshi' ? 'is-active':''"><router-link :to="{name:'RacerRanking',params:{kbn:'jyoshi'}}">女子</router-link></li>
</ul>  
<div class="term">{{ term }}</div>

<table v-if="racer_details.length > 0">
<thead>
  <tr>
  <th class="rank">順位</th>
  <th></th>
  <th class="sensyu-name">選手名<br />登番</th>
  <th class="sibu">支部</th>
  <th class="kyu">級別</th>
  <th class="prize">獲得賞金額</th>
  </tr>
</thead>
<tbody>
  <tr v-for="racer_detail in racerDetailKeys" :key="racer_detail.toban" >
    <td class="rank">
      <span class="rank-num">{{ racer_detail.rank }}</span>
    </td>
    <td class="photo">
       <router-link :to="{name:'RacerProfile',params:{toban:racer_detail.toban,tabname:'kibetsu'}}">
      <span class="racer-photo-wrapper"><img :src= "RACER_PHOTO_BASE_URL + racer_detail.toban+'.jpg'" class="racer-photo"  loading="lazy" alt="" /></span>
       </router-link>
    </td>
    <td class="sensyu-name">
      <router-link :to="{name:'RacerProfile',params:{toban:racer_detail.toban,tabname:'kibetsu'}}">
      <span class="name">{{ racer_detail.name }}</span><br />
      <span class="toban">{{ racer_detail.toban }}</span>
      </router-link>
    </td>
    <td class="sibu">
       {{ racer_detail.sibu }}
    </td>
    <td class="kyu">
      {{ racer_detail.kyu }}
    </td>
    <td class="prize">
     ¥{{ Number(racer_detail.syokin).toLocaleString() }} 
    </td>
  </tr>
</tbody>
</table>
</div>
</div>

</template>
<script>
import API_BASE_URL  from '../mixins/PrefsMixin'
import pianoSend from '../mixins/PrefsMixin'
const Buffer = require('buffer/').Buffer

export default {
  mixins: [API_BASE_URL,pianoSend],
  name: 'RacerRanking',

  data:function(){
    return{
      ranking:[],
      term:'',
      kbn:'all',
      kbn_name:'',
      racer_details:[]
    }
  }, 
  head:function(){
    return {
      title: function(){
        return {
          inner:  '賞金ランキング',
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  computed:{
     racerDetailKeys:function(){
        return this.racer_details.filter(function (item) {
          return  item.toban  !== null;
        })
     }
  },
  created(){
      this.$data.kbn = this.$route.params['kbn'];
      this.$data.kbn_name = {
        'all':'全選手',
        'danshi':'男子',
        'jyoshi':'女子'
      }[this.$data.kbn];
     
    //console.log(Buffer.from('こんにちは！', 'utf8'));

      this.getData();
     
  },

  beforeRouteUpdate(_to, _from, _next) {
    if(_to.name == 'RacerRanking' && this.$data.kbn !=  _to.params['kbn']){
      this.$data.kbn = _to.params['kbn'];
      this.$data.term ='';
      this.$data.kbn_name = {
        'all':'全選手',
        'danshi':'男子',
        'jyoshi':'女子'
      }[this.$data.kbn];
      this.$data.ranking = [];
      this.$data.racer_details =[];
      this.getData();
    }
    _next();
  },
   methods:{
     getData(){
        
      let iconv = require('iconv-lite'); 
      let {parse} = require('csv-parse/lib/sync');
    
      
      let loader = this.$loading.show({});
      let csv_file_name = 'all.csv';
      let ts = Math.floor(Number(Date.now())/1000/60);
   
        if(this.$data.kbn == 'danshi'){
          csv_file_name = 'danshi.csv?ts='+ts;
        }else if(this.$data.kbn == 'jyoshi'){
          csv_file_name = 'jyoshi.csv?ts='+ts;
        }else if(this.$data.kbn == 'all'){
          csv_file_name = 'all.csv?ts='+ts;
        }else{
          loader.hide();
          this.$router.replace({ name:'NotFound'});
        }

      
      
        this.axios.get('/ranking_data/'+csv_file_name,{responseType: 'arraybuffer'})
          .then((response) => {
         
              loader.hide();
              let body = iconv.decode(Buffer.from(response.data), 'windows-31j')
              this.term = parse(body, {columns: false,from_line: 3,to_line:3})[0][0];
              this.ranking = parse(body, {columns: true,from_line: 4});
            
              this.ranking.forEach(racer => {
              this.getRacerDetail(racer['登録番号'],racer['順位'],racer['獲得賞金額']);
                });
        }).catch((e) => { // eslint-disable-line
          loader.hide();
          this.$router.replace({ name:'NotFound'});
          });
        },
        getRacerDetail(toban,rank,syokin){
          this.axios.get(this.API_BASE_URL + 'racer_profile/'+ toban).then((response) => {
            let detail  = {...({'rank':rank,'syokin':syokin}) ,...response.data.profile};
            this.$set(this.racer_details, Number(rank)-1,detail);
        });
     }
  }
}


</script>

<style scoped>
.ranking-title{
  margin-left:10px;
}

.ranking-contents-tabs .tabs-title a{
  background-color:rgb(204, 228, 241);
  color:#333;
}


.ranking-contents-tabs .tabs-title.is-active{
  background-color:rgb(108 135 176);
}
.ranking-contents-tabs .tabs-title a:hover{
  background-color:rgb(218, 237, 248);
}

.ranking-contents-tabs .tabs-title.is-active a{
  color:#FFF;
  font-weight: bold;
  background-color:#003049;
}
.ranking-contents-tabs .tabs-title.is-active a:hover{
  color:#FFF;
  background-color:#003049;
}

.rank{
  text-align:center;
  width:32px;
}

.rank .rank-num{
  display:inline-block;
  width:32px;
  height:32px;
  line-height:32px;
  background-color: rgb(182, 179, 179);
  color: #000;
}
.sibu{
  text-align:center;
}
.kyu{
  text-align:center;
}
.prize{
  text-align:right;
  padding-right:10px;
}

.sensyu-name .name{
  font-weight:bold;
}

tbody tr:nth-child(-n + 10) td.rank .rank-num{
  background-color:#FF0000;
  color: #FFF;
  font-weight:bold; 
}

tbody tr:nth-child(n + 11) td.rank .rank-num{
  background-color:#ff9900;
  color: #FFF;
  font-weight:bold; 
}

tbody tr:nth-child(n + 21) td.rank .rank-num{
  background-color: rgb(182, 179, 179);
  color: #000;
  font-weight:normal;
}
.photo{
  width:64px;
}

.racer-photo-wrapper{
  width:64px;
  height:64px;
  display:block;
  position: relative;
  overflow:hidden;
}

.term{
  font-size:14px;
  margin:10px;
}

@media screen and (max-width:375px) { 
  .racer-photo-wrapper{
    width:40px;
    height:40px;
  }
.photo{
  width:48px;
}
  .racer-photo{
    width:100%;
    display:block;
    position: absolute;
  }
}
</style>