<template>
<div class="article-page">
<nav class="global-nav" role="navigation">
  <ul>
    <li>
      <router-link class="button dayrace-button" :to="{name:'Index'}">トップ</router-link>
    </li>
    <li>
      <router-link class="button dayrace-button" :to="{name:'DayRaceList',params:{date:today_str}}">本日のレース</router-link>
    </li>
  </ul>
</nav>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> 記事: {{ title }}
    </li>
  </ul>
</nav>    
<div class="article-wrapper" v-html="content"></div>
<div style="text-align:center;margin:40px auto;">
<Adsense
    class="adsbygoogle"
    data-ad-client="ca-pub-3076785709839281"
    data-ad-slot="9132319872"
    data-ad-format="rectangle"
    data-full-width-responsive="true">
</Adsense>
</div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name :'Article',
  mixins: [pianoSend],
  data:function(){
    return {
      date:'',
      title:'',
      content:'',
      today:'',
      today_str:'',
      id:''
    }
  },
   head:function(){
    return {
      title: function(){
        return {
          inner:  this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created(){
      this.$data.id = this.$route.params['id'];
      this.getData();

      this.$data.today = new Date();
      this.$data.today_str = '' + this.$data.today.getFullYear() + ('0'+(this.$data.today.getMonth()+1)).slice(-2) + ('0'+this.$data.today.getDate()).slice(-2);
  
  },
  beforeRouteUpdate(_to, _from, _next) {
      
        if(_to.name == 'Article' && _from.name == 'Article'){
            this.$data.id =  _to.params['id'];
            this.getData();
        }

      _next();
  },
  methods:{
     getData(){
        let loader = this.$loading.show({});
        this.axios.get('/article_contents/'+this.$data.id+'.html')
          .then((response) => {
             loader.hide();
            this.$data.content = response.data;

            let content_dom = document.createElement('div');
            content_dom.innerHTML = this.$data.content;
            this.$data.title = content_dom.getElementsByTagName('h1')[0].innerText;

            this.$emit('updateHead');
            this.pianoSend('記事',this.$data.title);
       
          })
          .catch((e) => { // eslint-disable-line
           
             loader.hide();
            this.$router.replace({ name:'NotFound'});
          });
     }
  }
}
</script>
<style lang="scss">
.article-wrapper{

    margin:30px auto;
    max-width:800px;
    @media screen and (max-width:428px) { 
        margin:15px auto;
    }

    .article-header{
        padding:20px 10px 35px 10px;
        //border-bottom:1px solid #02006d;
        background-image:url(/images/bg_article_title.svg);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: contain;
        @media screen and (max-width:428px) { 
            padding-bottom:30px;
            background-image:url(/images/sp_bg_article_title.svg);
        }

        h1{
            font-size:21px;
            font-weight:bold;
            @media screen and (max-width:428px) { 
              font-size:18px;
            }
        }
    }

    .article-date{
        margin-top:-5px;
        padding:10px;
        color:#666;
        //background-image: linear-gradient(180deg, #D2E5FF 0%, #FFFFFF 100%);
    }

    .article-body{
        padding:20px 10px;
        clear:both;
    }

            p{
            font-size:16px;
             @media screen and (max-width:428px) { 
              font-size:14px;
            }

        }
    .image-wrapper{
        
        width:100%;
        clear:both;

        figure{
            width:100%;
            background-color: #f4ffff;
            overflow: hidden;
            img{
                display:block;
                max-height:500px;
                margin:0 auto;
                 @media screen and (max-width:428px) { 
                     max-height:300px;
                 }
            }
            figcaption{
                background-color:#f7feff;
                border-top:1px solid #FFF;
                padding:10px;
                font-size:12px;
               display:block;
            }
        }

        &.left{
            width:300px;
            float:left;
            margin-right:30px;
            figure{
                img{
                    max-height:300px;
                    @media screen and (max-width:428px) { 
                        max-height:300px;
                    }
                }
            }
            @media screen and (max-width:428px) { 
              float:none;
               width:100%;
               margin-right:0;
            }


 
        }
        &.right{
            width:300px;
            margin-left:30px;
            float:right;
            @media screen and (max-width:428px) { 
              float:none;
               width:100%;
              margin-left:0;
            }
            
            figure{
                img{
                    max-height:300px;
                        @media screen and (max-width:428px) { 
                        max-height:300px;
                    }
                }
            }
        }
         margin-bottom:30px;
        @media screen and (max-width:428px) { 
            width:100%;
             margin-bottom:20px;
        }
       


    }
}
</style>