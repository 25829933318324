<template>
<div>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li><router-link :to="{name:'DayRaceList',params:{date:date}}"> レース一覧 {{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</router-link></li>
    <li><router-link :to="{name:'JoDateIndex',params:{date:date,jo:jo}}"> {{ jo_name }}</router-link></li>
    <li><span class="show-for-sr">Current: </span> {{ race.rno }}R オッズ</li>
  </ul>
</nav>
<nav class="jo-date-nav">
  <ul class="menu jo-date-menu" v-if="nidate_length > 0" >
    <li v-for="ni of nidate_length" :key='ni' >
      <span v-if="header['nidate'+ni+'_ready']"><router-link class="button" :class="race.nj == ni ? 'current':''" :to="{name:'JoDateIndex',params:{jo:jo,date:race['nidate'+ni].replace(/\-/g,'')}}">{{  Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</router-link></span>
      <span v-else-if="race['nidate'+ni] != null"><span class="button" disabled>{{   Number(race['nidate'+ni].substr(5,2))+'月'+  Number(race['nidate'+ni].substr(8,2))+'日'  }}</span></span>
    </li>
  </ul>
</nav>

<nav class="race-nav">
  <ul class="menu race-num-menu" v-if="nidate_length > 0" >
    <li v-for="ri in 12" :key='ri' >
      <router-link class="button" v-if="ri != race.rno" :to="{name:'Odds',params:{date:date,jo:jo,race:ri}}">{{ ri }}R</router-link>
      <span class="button current" v-else>{{ ri }}R</span>
    </li>
  </ul>
</nav>

<div class="race-header">
    <h1 class="race-h1">
      <div class="jo-name">{{ jo_name }}</div>
      <div class="race-number">{{ race.rno }}R</div>
      <div class="race-date">{{ date.substr(0,4)+'年' + date.substr(4,2)+'月'+ date.substr(6,2)+'日'}}</div>
      <div class="race-ktitl">{{ race.ktitl }}</div>
    </h1>

    <div class="rmei-rsname">
      <span class="race-rmei">{{ race.rmei }}</span> <span class="race-rsname">{{ race.rsname }} <span :class="race.kyori < 1800 ?'genshu':'' ">{{ race.kyori }}m</span> <span v-if="race.kyori < 1800">減周回</span></span>
    </div>

</div>
<div class="race-sh-times">
  <div>締切時刻 {{ race.stime ? race.stime.split('T')[1].slice( 0, -3 ):'-' }}</div>

      <!-- <div>出走時刻 {{ race.htime ? race.htime.split('T')[1].slice( 0, -3 ):'' }}</div> -->
</div>


<Racenav :current = "'odds'" :racedate = "date" :joid= "jo" :rno = "race_num" :is_result_available = "race.is_result_available" ></Racenav>
<div class="odds-update-info" v-if="odds != null">
  <span v-if="(new Date(odds.stime))>(new Date(odds.utime))">
    オッズ更新時間 {{  odds.utime.split('T')[1].slice( 0, -3) }}
    <a class="button odds-update-button" v-on:click="oddsReload">更新</a>
  </span>
  <span v-else-if="odds.stime != null" class="simekiri-odds">
    締切時オッズ
  </span>
</div>   

<ul class="tabs odds-type-tabs" v-show="odds != null">
  <li class="tabs-title" :class="tabname=='rt3' ? 'is-active':''"><router-link :to="{name:'Odds',params:{date:date,jo:jo,race:race_num,tabname:'rt3'}}">3連単</router-link></li>
  <li class="tabs-title" :class="tabname=='rf3' ? 'is-active':''"><router-link :to="{name:'Odds',params:{date:date,jo:jo,race:race_num,tabname:'rf3'}}">3連複</router-link></li>
  <li class="tabs-title" :class="tabname=='rt' ? 'is-active':''"><router-link :to="{name:'Odds',params:{date:date,jo:jo,race:race_num,tabname:'rt'}}">2連単</router-link></li>
  <li class="tabs-title" :class="tabname=='rf' ? 'is-active':''"><router-link :to="{name:'Odds',params:{date:date,jo:jo,race:race_num,tabname:'rf'}}">2連複</router-link></li>
  <li class="tabs-title" :class="tabname=='rfk' ? 'is-active':''"><router-link :to="{name:'Odds',params:{date:date,jo:jo,race:race_num,tabname:'rfk'}}">拡連複</router-link></li>
  <li class="tabs-title" :class="tabname=='tf' ? 'is-active':''"><router-link :to="{name:'Odds',params:{date:date,jo:jo,race:race_num,tabname:'tf'}}">単勝・複勝</router-link></li>
</ul>

<div v-if="odds !=null">

<div v-if="tabname == 'rt3'">
<h2 class="tab-content-title">3連単</h2>

<nav>
  <ul class="menu odds-view-menu">
    <li><a v-on:click="rmode='ninnki'" :class="rmode=='ninnki' ? 'is-active':''">人気順</a></li>
    <li><a v-on:click="rmode='odds_table'" :class="rmode=='odds_table' ? 'is-active':''">オッズ表</a></li>
  </ul>
</nav>
<div v-if="rmode=='ninnki'">

<div class="grid-x grid-margin-x grid-padding-x ninnki-grid">

<div class="cell small-12 medium-12 large-6">
<dl class="odds-ninnki-list" v-for="(rt3_item,index) in ([].concat(odds_3rentan)).slice(0,25)" :key="rt3_item.kaime">
  <dt>{{ index+1 }}</dt>
  <dd><span class="kaime" v-html="formatKaime(rt3_item.kaime,0)"></span></dd>
  <dd class="odds">{{ rt3_item.odds.toFixed(1) }}</dd>
</dl>
</div>
<div class="cell small-12 medium-12 large-6">
<dl class="odds-ninnki-list" v-for="(rt3_item,index) in ([].concat(odds_3rentan)).slice(25,50)" :key="rt3_item.kaime">
  <dt>{{ index+26 }}</dt>
  <dd><span class="kaime" v-html="formatKaime(rt3_item.kaime,0)"></span></dd>
  <dd class="odds">{{ rt3_item.odds.toFixed(1) }}</dd>
</dl>
</div>

</div>
</div>
<div v-else-if="rmode=='odds_table'">

  <div class="input-group">
 <span class="input-group-label">軸</span>
  <select class="input-group-field select-racer" v-model="curennt_jiku">
    <option value="1">1. {{ odds['name1'] }}</option>
    <option value="2">2. {{ odds['name2'] }}</option>
    <option value="3">3. {{ odds['name3'] }}</option>
    <option value="4">4. {{ odds['name4'] }}</option>
    <option value="5">5. {{ odds['name5'] }}</option>
    <option value="6">6. {{ odds['name6'] }}</option>
  </select>

  <select class="input-group-field select-rank" v-model="rt3j">
    <option value="1">1着</option>
    <option value="2">2着</option>
  </select>
</div>



<div v-if="rt3j==1" class="table-scroll">
<table class="odds-table">
  <tbody>
    <tr>
        <th colspan="2">1着</th>
        <th colspan="5" class="waku" :class="'waku-'+curennt_jiku">{{ curennt_jiku }}</th>
    </tr>
     <tr class="hwaku">
        <th colspan="2">2着</th>
        <th v-for="t2 in noJikuNum" :key="t2" class="waku" :class="'waku-'+t2">{{ t2 }}</th>   
      </tr>
      <tr v-for="t3 in noJikuNum" :key="t3">
          <th v-if="(t3 == 1 && t3 != curennt_jiku) || (curennt_jiku == 1 && t3 == 2 )" rowspan="5">3着</th>
          <th class="waku" :class="'waku-'+t3">{{ t3 }}</th>
          <td v-for="t4 in noJikuNum" :key="t4" :class="t4 == t3 ? 'no-val':''">{{ odds_3rentan_matrix[curennt_jiku-1][t4-1][t3-1]!= null ? odds_3rentan_matrix[curennt_jiku-1][t4-1][t3-1].toFixed(1):'' }}</td>
      </tr>
  </tbody>
</table>
</div>

<div v-else-if="rt3j==2" class="table-scroll">
<table class="odds-table">
  <tbody>
     <tr class="hwaku">
        <th colspan="2">1着</th>
        <th v-for="t1 in noJikuNum" :key="t1" class="waku" :class="'waku-'+t1">{{ t1 }}</th>   
      </tr>
      <tr>
        <th colspan="2">2着</th>
        <th colspan="5" class="waku" :class="'waku-'+curennt_jiku">{{ curennt_jiku }}</th>
      </tr>
      <tr v-for="t3 in noJikuNum" :key="t3">
          <th v-if="(t3 == 1 && t3 != curennt_jiku) || (curennt_jiku == 1 && t3 == 2 )" rowspan="5">3着</th>
          <th class="waku" :class="'waku-'+t3">{{ t3 }}</th>
          <td v-for="t4 in noJikuNum" :key="t4" >{{ odds_3rentan_matrix[curennt_jiku-1][t4-1][t3-1]!= null ?  odds_3rentan_matrix[t4 - 1][curennt_jiku - 1][t3 - 1].toFixed(1):'' }}</td>
      </tr>
  </tbody>
</table>
</div>
</div>
</div>

<div v-else-if="tabname == 'rf3'">
<h2 class="tab-content-title">3連複</h2>
<div>
<nav>
  <ul class="menu odds-view-menu">
    <li><a v-on:click="rmode='ninnki'" :class="rmode=='ninnki' ? 'is-active':''">人気順</a></li>
    <li><a v-on:click="rmode='odds_table'" :class="rmode=='odds_table' ? 'is-active':''">オッズ表</a></li>
  </ul>
</nav>

<div v-if="rmode=='ninnki'">


<div class="grid-x grid-margin-x grid-padding-x ninnki-grid">
<div class="cell small-12 ">
<dl class="odds-ninnki-list" v-for="(rf3_item,index) in ([].concat(odds_3renpuku))" :key="rf3_item.kaime">
  <dt>{{ index+1 }}</dt>
  <dd><span class="kaime" v-html="formatKaime(rf3_item.kaime,1)"></span></dd>
  <dd class="odds">{{ rf3_item.odds.toFixed(1) }}</dd>
</dl>
</div>
</div>
</div>
<div v-else-if="rmode=='odds_table'">

  <div class="input-group">
 <span class="input-group-label">軸</span>
  <select class="input-group-field select-racer" v-model="curennt_jiku">
    <option value="1">1. {{ odds['name1'] }}</option>
    <option value="2">2. {{ odds['name2'] }}</option>
    <option value="3">3. {{ odds['name3'] }}</option>
    <option value="4">4. {{ odds['name4'] }}</option>
    <option value="5">5. {{ odds['name5'] }}</option>
    <option value="6">6. {{ odds['name6'] }}</option>
  </select>
</div>
<div class="table-scroll">
<table class="odds-table">
  <tbody>
    <tr>
        <th colspan="2">1人目</th>
        <th colspan="5" class="waku" :class="'waku-'+curennt_jiku">{{ curennt_jiku }}</th>
    </tr>
      <tr class="hwaku">
        <th colspan="2">2人目</th>
        <th v-for="t2 in noJikuNum" :key="t2" class="waku"  :class="'waku-'+t2">{{ t2 }}</th>   
      </tr>
      <tr v-for="t3 in noJikuNumRf3" :key="t3">
          <th v-if="(t3 == noJikuNumRf3[0])" rowspan="5">3人目</th>
          <th class="waku" :class="'waku-'+t3">{{ t3 }}</th>
          <td v-for="t4 in noJikuNum" :key="t4" :class="(t3<=t4)?'no-val':''" >
            {{ (t3>t4) ? getRf3Odds(curennt_jiku,t4,t3).toFixed(1):'' }}
          </td>
      </tr>
  </tbody>
</table>
</div>
</div>
</div>
</div>

<div v-else-if="tabname == 'rt'">
<h2 class="tab-content-title">2連単</h2>
<nav>
  <ul class="menu odds-view-menu">
    <li><a v-on:click="rmode='ninnki'" :class="rmode=='ninnki' ? 'is-active':''">人気順</a></li>
    <li><a v-on:click="rmode='odds_table'" :class="rmode=='odds_table' ? 'is-active':''">オッズ表</a></li>
  </ul>
</nav>
<div v-if="rmode == 'ninnki'">


<div class="grid-x grid-margin-x grid-padding-x ninnki-grid">
<div class="cell small-12 medium-12 large-6">
<dl class="odds-ninnki-list" v-for="(rt2_item,index) in ([].concat(odds_2rentan)).slice(0,15)" :key="rt2_item.kaime">
  <dt>{{ index+1 }}</dt>
  <dd><span class="kaime" v-html="formatKaime(rt2_item.kaime,0)"></span></dd>
  <dd class="odds">{{ rt2_item.odds.toFixed(1) }}</dd>
</dl>
</div>
<div class="cell small-12 medium-12 large-6">
<dl class="odds-ninnki-list" v-for="(rt2_item,index) in ([].concat(odds_2rentan)).slice(15,30)" :key="rt2_item.kaime">
  <dt>{{ index+16 }}</dt>
  <dd><span class="kaime" v-html="formatKaime(rt2_item.kaime,0)"></span></dd>
  <dd class="odds">{{ rt2_item.odds.toFixed(1) }}</dd>
</dl>
</div>
</div>

</div>
<div v-else-if="rmode == 'odds_table'">

<table class="odds-table rt2">
  <tbody>
    <tr class="hwaku">
        <th colspan="2">1着</th>
        <th v-for="t1 in 6" :key="t1" class="waku" :class="'waku-'+t1">{{ t1 }}</th> 
    </tr>
      <tr v-for="t2 in 6" :key="t2">
          <th v-if="(t2 == 1)" rowspan="6">2着</th>
          <th class="waku" :class="'waku-'+t2">{{ t2 }}</th>
          <td v-for="t3 in 6" :key="t3" :class="t2 == t3 ? 'no-val':''">{{ odds_2rentan_matrix[t3-1][t2-1] != null ? odds_2rentan_matrix[t3-1][t2-1].toFixed(1):'' }}</td>
      </tr>
  </tbody>
</table>
</div>
</div>


<div v-else-if="tabname == 'rf'">
<h2 class="tab-content-title">2連複</h2>
<nav>
  <ul class="menu odds-view-menu">
    <li><a v-on:click="rmode='ninnki'" :class="rmode=='ninnki' ? 'is-active':''">人気順</a></li>
    <li><a v-on:click="rmode='odds_table'" :class="rmode=='odds_table' ? 'is-active':''">オッズ表</a></li>
  </ul>
</nav>
<div v-if="rmode == 'ninnki'">



<div class="grid-x grid-margin-x grid-padding-x ninnki-grid">
<div class="cell small-12 medium-12 large-12">
<dl class="odds-ninnki-list" v-for="(rf2_item,index) in ([].concat(odds_2renpuku))" :key="rf2_item.kaime">
  <dt>{{ index+1 }}</dt>
  <dd><span class="kaime" v-html="formatKaime(rf2_item.kaime,1)"></span></dd>
  <dd class="odds">{{ rf2_item.odds.toFixed(1) }}</dd>
</dl>
</div>
</div>
</div>
<div v-else-if="rmode == 'odds_table'">

<table class="odds-table rt2">
  <tbody>
    <tr class="hwaku">
        <th colspan="2">1人目</th>
        <th v-for="t1 in 6" :key="t1" class="waku" :class="'waku-'+t1">{{ t1 }}</th> 
    </tr>
      <tr v-for="t2 in [2,3,4,5,6]" :key="t2">
          <th v-if="(t2 == 2)" rowspan="6">2人目</th>
          <th class="waku" :class="'waku-'+t2">{{ t2 }}</th>
          <td v-for="t3 in 6" :key="t3" :class="(t2<=t3)?'no-val':''" >
            {{ (t2>t3 && odds_2renpuku_matrix[t3-1][t2-2] != null ) ? odds_2renpuku_matrix[t3-1][t2-2].toFixed(1):'' }}
          </td>
      </tr>
  </tbody>
</table>
</div>
</div>

<div v-else-if="tabname == 'rfk'">
<h2 class="tab-content-title">拡連複</h2>
<nav>
  <ul class="menu odds-view-menu">
    <li><a v-on:click="rmode='ninnki'" :class="rmode=='ninnki' ? 'is-active':''">人気順</a></li>
    <li><a v-on:click="rmode='odds_table'" :class="rmode=='odds_table' ? 'is-active':''">オッズ表</a></li>
  </ul>
</nav>
<div v-if="rmode == 'ninnki'">



<div class="grid-x grid-margin-x grid-padding-x ninnki-grid">
<div class="cell small-12 medium-12 large-12">
<dl class="odds-ninnki-list" v-for="(rfk_item,index) in ([].concat(odds_kakurenpuku))" :key="rfk_item.kaime">
  <dt>{{ index+1 }}</dt>
  <dd><span class="kaime" v-html="formatKaime(rfk_item.kaime,1)"></span></dd>
  <dd class="odds">{{ rfk_item.odds.low.toFixed(1) }} - {{ rfk_item.odds.high.toFixed(1) }}</dd>
</dl>
</div>
</div>
</div>
<div v-else-if="'odds_table'">

  <table class="odds-table rt2">
  <tbody>
    <tr class="hwaku">
        <th colspan="2">1人目</th>
        <th v-for="t1 in 6" :key="t1" class="waku" :class="'waku-'+t1">{{ t1 }}</th> 
    </tr>
      <tr v-for="t2 in [2,3,4,5,6]" :key="t2">
          <th v-if="(t2 == 2)" rowspan="6">2人目</th>
          <th class="waku" :class="'waku-'+t2">{{ t2 }}</th>
          <td v-for="t3 in 6" :key="t3" :class="(t2<=t3)?'no-val':''" >
            {{ (t2>t3 && odds_kakurenpuku_matrix[t3-1][t2-2] != null ) ? odds_kakurenpuku_matrix[t3-1][t2-2]['low'].toFixed(1) +'-'+ odds_kakurenpuku_matrix[t3-1][t2-2]['high'].toFixed(1):'' }}
          </td>
      </tr>
  </tbody>
  </table>
</div> 
</div>

<div v-else-if="tabname == 'tf'">
<h2 class="tab-content-title">単勝・複勝</h2>
<table class="odds-tan-table">
  <thead>
    <tr>
      <th></th><th>ボートレーサー</th><th class="odds">単勝</th><th class="odds">複勝</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(o,index) in odds_tan" :key="index">
      <th class="waku" :class="'waku-'+(index+1)">{{ index+1 }}</th>
      <td>{{ odds['name'+(index+1)] }}</td>
      <td class="odds">{{ o != null ? o.toFixed(1): ''}}</td>
      <td class="odds">{{ odds_fuku[index] != null ? odds_fuku[index].low.toFixed(1)+'-'+ odds_fuku[index].high.toFixed(1) : ''}}</td>
    </tr>
  </tbody>
</table>

</div>  
</div>
<div v-else-if="odds == null">
  <h2 class="tab-content-title no-data">※オッズのデータはありません。</h2>
</div>  

  <a v-if="isMobile" class="button tohyo-button" target="blank" :href="'https://www.boatrace.jp/owsp/VoteBridgeSP.jsp?authAfterTrans=stay&amp;voteTagId=voteTag&amp;_hd='+ date +'&amp;_jcd='+ race.jcd + '&amp;_rno='+ race_num ">投票</a>
  <a v-else class="button tohyo-button" target="blank" :href="'https://www.boatrace.jp/owpc/VoteConfirm.jsp?authAfterTrans=stay&amp;voteTagId=vote&amp;_hd='+ date +'&amp;_jcd='+ race.jcd + '&amp;_rno='+ race_num ">投票</a>

</div>
</template>

<script>
import JO_ID_NAMES from '../mixins/PrefsMixin';
import API_BASE_URL  from '../mixins/PrefsMixin';
import pianoSend from '../mixins/PrefsMixin'
import Racenav from '../components/Racenav';


export default {
  mixins: [ JO_ID_NAMES,API_BASE_URL,pianoSend],
  components: {
    Racenav
  },
  name: 'Odds',
  data:function(){
    return {
    date:'',
    jo:'',
    tabname:'rt3',
    rmode:'ninnki',
    rt3j:1,
    race_num:0,
    race:{},
    header:{},
    odds:{},
    odds_3rentan:[],
    odds_3rentan_matrix:[],
    odds_3renpuku:[],
    odds_3renpuku_matrix:[],
    odds_2rentan:[],
    odds_2rentan_matrix:[],
    odds_2renpuku:[],
    odds_2renpuku_matrix:[],
    odds_kakurenpuku:[],
    odds_kakurenpuku_matrix:[],
    odds_tan:[],
    odds_fuku:[],
    curennt_jiku :1,
    shussou_senshu:[],
    nidate_length:0,
    jo_name:'',
    title:'オッズ',
    sub_title:{'rt3':'3連単','rf3':'3連複','rt':'2連単','rf':'2連複','rfk':'拡連複','tf':'単勝・複勝'},
    tommrow:new Date(),
    today:new Date()
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  computed:{
        isMobile:function(){
          if((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
              return true;
          }
          return false;
        },

        noJikuNum:function(){
            let wakus = [1,2,3,4,5,6];

            return  wakus.filter((v)=>{
                return this.$data.curennt_jiku != v
            })
            
        },

      noJikuNumRf3:function(){
            let wakus = [1,2,3,4,5,6];

            let r = wakus.filter((v)=>{
                return this.$data.curennt_jiku != v
            })

            r.sort();
            r.shift();
            return r;
            
        }


  },

  //  mounted:function(){
  // },

  created:function(){
    this.$data.date = this.$route.params['date'];
    this.$data.race_num = this.$route.params['race'];
    this.$data.jo = this.$route.params['jo'];
    this.$data.tommrow.setDate(this.$data.tommrow.getDate() + 1); 


    this.$data.tabname = this.$route.params['tabname'];
    

    this.$data.odds_3rentan_matrix= new Array(6);
    for(let i=0;i<6;i++){
        this.$data.odds_3rentan_matrix[i] = new Array(6);
        for(let j=0;j<6;j++){
            this.$data.odds_3rentan_matrix[i][j] =new Array(6);
        }
    }

    this.$data.odds_3renpuku_matrix= new Array(4);
    for(let i=0;i<4;i++){
        this.$data.odds_3renpuku_matrix[i] = new Array(4);
        for(let j=0;j<4;j++){
            this.$data.odds_3renpuku_matrix[i][j] =new Array(4);
        }
    }

    this.$data.odds_2rentan_matrix= new Array(6);
    for(let i=0;i<6;i++){
        this.$data.odds_2rentan_matrix[i] = new Array(6);
    }

    this.$data.odds_2renpuku_matrix= new Array(6);
    for(let i=0;i<6;i++){
        this.$data.odds_2renpuku_matrix[i] = new Array(5);
    }

    this.$data.odds_kakurenpuku_matrix= new Array(6);
    for(let i=0;i<6;i++){
        this.$data.odds_kakurenpuku_matrix[i] = new Array(5);
    }

    this.getData();
  },
  beforeRouteUpdate(_to, _from, _next) {

    if(_to.name == 'Odds' &&  
    (this.$data.date != _to.params['date'] || this.$data.race_nume != _to.params['race'] || this.$data.jo != _to.params['jo'])){
     
      this.$data.date == _to.params['date']
      this.$data.race_num = _to.params['race'];
      this.$data.jo = _to.params['jo'];
      this.$data.tabname = _to.params['tabname'];

   
      

      this.changeRace();

    

    }else if(_to.name == 'Odds' &&  this.$data.tabname != _to.params['tabname'] ){
       this.$data.tabname = _to.params['tabname'];
       this.$data.title = 'オッズ '+this.$data.sub_title[this.$data.tabname]+' - ' +this.$data.jo_name +' '+ this.$data.race.rno +'R '+  this.$data.race.hdate + ' ' + this.$data.race.ktitl; 
        this.$emit('updateHead');
       
        this.pianoSend('オッズ',this.$data.sub_title[this.$data.tabname],this.$data.jo_name,this.$data.race.rno +'R',this.$data.race.hdate,this.$data.race.ktitl)
    }
    


    _next();
    
},
//   beforeRouteLeave(_to, _from, _next) {
//     _from;
//     try{
//       document.removeEventListener('click',this.closeMySirusiSelecor,true);
//     }catch(err){
//       err;
//     }
//     _next();
   
//   },
  methods: {
    formatKaime(kaime,fuku = 0){
       const kaime_items = kaime.split('');
       const split_str = fuku == 1 ? '=':'-';

       const kl =  kaime_items.length;
       let kaime_html = "";
       for(let i=0;i<kl;i++){
          kaime_html += '<span class="waku-'+ kaime_items[i] +'">'+ kaime_items[i] +'</span>';
          if(i < kl-1){
               kaime_html += '<span class="split_str">'+split_str+'</span>';
          }
       }

       return kaime_html;
       
    },
    
    getRf3Odds(x,y,z){
      let keys = [x,y,z];
      keys.sort();
      return this.$data.odds_3renpuku_matrix[keys[0]-1][keys[1]-2][keys[2]-3];
    },

    oddsReload(){
      //console.log('reload');
      this.$data.odds_3rentan = [];
      this.$data.odds_3renpuku = [];
      this.$data.odds_2rentan = [];
      this.$data.odds_2renpuku = [];
      this.$data.odds_kakurenpuku = [];
      this.$data.odds_tan = [];
      this.$data.odds_fuku = [];

      this.$data.odds_3rentan_matrix= new Array(6);
      for(let i=0;i<6;i++){
        this.$data.odds_3rentan_matrix[i] = new Array(6);
        for(let j=0;j<6;j++){
            this.$data.odds_3rentan_matrix[i][j] =new Array(6);
        }
      }

      this.$data.odds_3rentan_matrix= new Array(6);
      for(let i=0;i<6;i++){
          this.$data.odds_3rentan_matrix[i] = new Array(6);
          for(let j=0;j<6;j++){
              this.$data.odds_3rentan_matrix[i][j] =new Array(6);
          }
      }

      this.$data.odds_2rentan_matrix= new Array(6);
      for(let i=0;i<6;i++){
          this.$data.odds_2rentan_matrix[i] = new Array(6);
      }

      this.$data.odds_2renpuku_matrix= new Array(6);
      for(let i=0;i<6;i++){
          this.$data.odds_2renpuku_matrix[i] = new Array(5);
      }

      this.$data.odds_kakurenpuku_matrix= new Array(6);
      for(let i=0;i<6;i++){
          this.$data.odds_kakurenpuku_matrix[i] = new Array(5);
      }
    
      this.getData();
    },

    changeRace(){
      this.$data.race ={};

      this.$data.odds_3rentan = [];
      this.$data.odds_3renpuku = [];
      this.$data.odds_2rentan = [];
      this.$data.odds_2renpuku = [];
      this.$data.odds_kakurenpuku = [];
      this.$data.odds_tan = [];
      this.$data.odds_fuku = [];

      this.$data.odds_3rentan_matrix= new Array(6);
      for(let i=0;i<6;i++){
        this.$data.odds_3rentan_matrix[i] = new Array(6);
        for(let j=0;j<6;j++){
            this.$data.odds_3rentan_matrix[i][j] =new Array(6);
        }
      }

      this.$data.odds_3rentan_matrix= new Array(6);
      for(let i=0;i<6;i++){
          this.$data.odds_3rentan_matrix[i] = new Array(6);
          for(let j=0;j<6;j++){
              this.$data.odds_3rentan_matrix[i][j] =new Array(6);
          }
      }

      this.$data.odds_2rentan_matrix= new Array(6);
      for(let i=0;i<6;i++){
          this.$data.odds_2rentan_matrix[i] = new Array(6);
      }

      this.$data.odds_2renpuku_matrix= new Array(6);
      for(let i=0;i<6;i++){
          this.$data.odds_2renpuku_matrix[i] = new Array(5);
      }

      this.$data.odds_kakurenpuku_matrix= new Array(6);
      for(let i=0;i<6;i++){
          this.$data.odds_kakurenpuku_matrix[i] = new Array(5);
      }
    
      this.getData();

    },


      getData() {
          this.axios.get(this.API_BASE_URL + 'odds/'+ this.$data.date + '/'+ this.$data.jo  +'/'+ this.$data.race_num)
          .then((response) => {

            //console.log(response.data);

            this.$data.race = response.data.race;
            this.$data.odds = response.data.odds;
            this.$data.header = response.data.header;



            this.$data.jo_name = this.JO_ID_NAMES[Number(this.$data.jo) - 1].name;
            for(let i=1;i<=9;i++){
              if(this.$data.race['nidate'+i]){
                this.$data.nidate_length = i;
              }
            }
          
            
            if(this.$data.odds != null){
            for(let key in this.$data.odds.rt3 ) {
                if( this.$data.odds.rt3[key]  != null) {
                   let odds = Number(this.$data.odds.rt3[key]);
                   this.$data.odds_3rentan.push({'kaime':key,'odds':odds})
                   let kaime_items = key.split(''); 
                   this.$data.odds_3rentan_matrix[parseInt(kaime_items[0])-1][parseInt(kaime_items[1])-1][parseInt(kaime_items[2])-1] = odds;
                }
            }

           

            this.$data.odds_3rentan.sort((a,b)=>{
                  if (a.odds > b.odds) {
                        return 1;
                    } else {
                        return -1;
                    }
            })

           //console.log(this.$data.odds_3rentan) ;
           
           for(let key in this.$data.odds.rf3 ) {
              if( this.$data.odds.rf3[key]  != null) {
                 let odds = Number(this.$data.odds.rf3[key]);
                 this.$data.odds_3renpuku.push({'kaime':key,'odds':odds})
                 let kaime_items = key.split(''); 
                 this.$data.odds_3renpuku_matrix[parseInt(kaime_items[0])-1][parseInt(kaime_items[1])-2][parseInt(kaime_items[2])-3] = odds;
               }
            }
           
            this.$data.odds_3renpuku.sort((a,b)=>{
                  if (a.odds > b.odds) {
                        return 1;
                    } else {
                        return -1;
                    }
            })
          
            for(let key in this.$data.odds.rt) {
              if( this.$data.odds.rt[key] != null ) {
                 let odds = Number(this.$data.odds.rt[key]);
                 this.$data.odds_2rentan.push({'kaime':key,'odds':odds})
                 let kaime_items = key.split(''); 
                 this.$data.odds_2rentan_matrix[parseInt(kaime_items[0])-1][parseInt(kaime_items[1])-1] = odds;
               }
            }

            //console.log(this.$data.odds_2rentan_matrix)
            

           this.$data.odds_2rentan.sort((a,b)=>{
                  if (a.odds > b.odds) {
                        return 1;
                    } else {
                        return -1;
                    }
            })
          

            for(let key in this.$data.odds.rf) {
              if( this.$data.odds.rf[key] != null ) {
                 let odds = Number(this.$data.odds.rf[key]);
                 this.$data.odds_2renpuku.push({'kaime':key,'odds':odds})
                 let kaime_items = key.split(''); 
                 this.$data.odds_2renpuku_matrix[parseInt(kaime_items[0])-1][parseInt(kaime_items[1])-2] = odds;
               }
            }
            //console.log(this.$data.odds_2renpuku_matrix)
            

           this.$data.odds_2renpuku.sort((a,b)=>{
                  if (a.odds > b.odds) {
                        return 1;
                    } else {
                        return -1;
                    }
            })

            let odds_set = {};
            for(let key in this.$data.odds.rfk){
              if(this.$data.odds.rfk[key] != null){
                 let kaime = ''+key.charAt(0)+key.charAt(1);
                 if(!odds_set[kaime]){
                   odds_set[kaime] = {};
                 }
      
                 if(key.includes('high')){
                   odds_set[kaime]['high'] = Number(this.$data.odds.rfk[key]);
                 }else if(key.includes('low')){
                   odds_set[kaime]['low'] = Number(this.$data.odds.rfk[key]);
                 }

                 if( odds_set[kaime]['high'] != null && odds_set[kaime]['high'] != null){
                   this.$data.odds_kakurenpuku.push({'kaime':kaime,'odds':odds_set[kaime]});
                   this.$data.odds_kakurenpuku_matrix[parseInt(key.charAt(0))-1][parseInt(key.charAt(1))-2] = odds_set[kaime];
                 }
                
              }
            }
         
          //console.log(this.$data.odds_kakurenpuku)

          this.$data.odds_kakurenpuku.sort((a,b)=>{
                if (a.odds.low > b.odds.low) {
                      return 1;
                  } else {
                      return -1;
                  }
          })

          for(let key in this.$data.odds.t){
              if(this.$data.odds.t[key]!=null){
                this.$data.odds_tan[parseInt(key.charAt(0))-1] = this.$data.odds.t[key];
              }
          }
 
         for(let key in this.$data.odds.f){
              if(this.$data.odds.f[key] != null){
                if(!this.$data.odds_fuku[parseInt(key.charAt(0))-1]){
                  this.$data.odds_fuku[parseInt(key.charAt(0))-1] = {};
                }   

                if(key.includes('high')){
                   this.$data.odds_fuku[parseInt(key.charAt(0))-1]['high'] = Number(this.$data.odds.f[key]);
                 }else if(key.includes('low')){
                   this.$data.odds_fuku[parseInt(key.charAt(0))-1]['low'] = Number(this.$data.odds.f[key]);
                 }
              }
          }
            }

           
            
            
          
            this.$data.title = 'オッズ '+this.$data.sub_title[this.$data.tabname]+' - ' +this.$data.jo_name +' '+ this.$data.race.rno +'R '+  this.$data.race.hdate + ' ' + this.$data.race.ktitl; 
            this.$emit('updateHead');
           
            this.pianoSend('オッズ',this.$data.sub_title[this.$data.tabname],this.$data.jo_name,this.$data.race.rno +'R',this.$data.race.hdate,this.$data.race.ktitl)







          })
          .catch((e) => { // eslint-disable-line
           
            //this.$router.replace({ name:'NotFound'});
          });

 
          
   
      },



  }
}
</script>
<style lang="scss">
.odds-ninnki-list{
  span.kaime{
  display:inline-block;
  
  >.split_str{
    display: inline-block;
    margin:0 5px;
  }
  >.waku-1,
  >.waku-2,
  >.waku-3,
  >.waku-3,
  >.waku-4,
  >.waku-5,
  >.waku-6{
    display: inline-block;
    width:30px;
    text-align: center;
  }
  }
}
</style>
<style lang="scss" scoped>

.n1{
   color:#e02a2a; 
}
.tab-content-title{
  margin:20px 0;
  padding:0 10px;
}
h3{
   margin:20px 0;
  padding:0 10px;
}

.odds-type-tabs{
  border:none;
  background-color: #f1faff;
  padding-top:5px;
  white-space: nowrap;
  overflow-x:auto;
  -webkit-overflow-scrolling : touch ;
  
   .tabs-title{
     float:none;
     display: inline-block;
     border-radius: 5px 5px  0 0;
     >a{
       padding:15px 12px;
     }
  }


  a{
    font-size:13px;
       &:hover,&:focus{
        color:#042e44;
        font-weight:bold;
        background-color:#deedf5; 
      }
      &:active{
        color:#042e44;
        background-color:#deedf5;  
        font-weight:bold;
        
      }
  }


  .tabs-title.is-active{
    background-color:#FFF;
   border-radius: 5px 5px  0 0;
   
    box-shadow: 0 -1px 3px 0 rgba(0,0,0,0.15), 0 -1px 2px 0 rgba(0,0,0 ,0.08);
    >a{
      color:#042e44;
      font-weight:bold;
        &:hover,&:focus{
        color:#000000;
        font-weight:bold;
        background-color:#FFF; 
      }
      &:active{
        color:#000000;
        background-color:#FFF; 
        font-weight:bold;
        border-radius: 5px 5px  0 0;
      }
    }
  }
}
.ninnki-grid{
  border-top:1px solid #CDCDCD;
  padding-top:20px;
  margin:10px;
  @media screen and (max-width:428px) { 
        margin:10px 0;
        .cell{
          margin-left:0 !important;
          margin-right:0 !important;
          padding-left:0 !important;
          padding-right:0 !important;
          width:100%;
        }
  }
}

.odds-ninnki-list{
  display: grid;
  grid-template-columns: 40px 1fr 1fr;
  border-bottom:1px solid #CDCDCD;
  dt{
    color:#333;
    text-align: center;
  }
  dd{
    text-align:center;
  }
  dd.odds{
    text-align:right;
    padding-right:10px;
  }

font-size:16px;
 @media screen and (max-width:428px) { 
   font-size:14px;
   padding-bottom:3px;
 }
}


.odds-view-menu{
  background-color: #f1faff;
  padding:10px;
  .is-active{
    background-color:#039cee;
    color:#fff;
    border-radius: 20px;
  }
}

.odds-table tr th{

}
table.odds-table tbody tr td{
  text-align: right;
  padding:10px;
  border:1px solid #CDCDCD;
}

table.odds-table{
  tr{

    td,th{
      &:not(.waku){
        background-color: #FFF; 
      }
      border:1px solid #CDCDCD;
      &.no-val{
        background-color: rgb(218, 218, 218);
      }
    }
    &.hwaku{
       .waku-1,.waku-2,.waku-3,.waku-4,.waku-5,.waku-6{
        width:16%;
      }
    }
  }
  &.rt2{
    tr{
      &.hwaku{
        .waku-1,.waku-2,.waku-3,.waku-4,.waku-5,.waku-6{
          width:14.2%;
        }
      }
    }
  }
}
.odds-tan-table,
.odds-fuku-table{
  tr{
    th,td{
      padding:10px 20px;
      &.odds{
        text-align:right;
      }
    }

  }
  
}


.odds-update-info{
  padding:10px 5px;
  text-align: right;
  .simekiri-odds{
    display: inline-block;
    color:#f86c59;
    font-weight: bold;
    border:1px solid #f86c59;;
    padding:5px 10px;
    border-radius: 20px;
  }
}

.odds-update-button{
  background-image:url(/images/icon_reload.svg);
  margin-left:10px;
  padding-left:30px;
  padding-right:15px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
  background-size: 20%;
  background-color:#004B72;
 
  color:#FFF;
  font-weight: bold;
  border-radius: 20px;
  margin-bottom:0;
  &:hover,&:focus{
      background-color:#4d9ec9;
  }
  &:active{
      background-color:#8abeda;
  }
}

.select-racer,.select-rank{
  font-size:18px;
  zoom:0.9;
}
.select-racer{
  min-width:70%;
}

h2.tab-content-title.no-data{
  font-size:16px;
  color:#666;
}
</style>