<template>
    <div>
    <div class="hero-section">
      <div class="hero-section-text">
        <h1>データの収集と利用について</h1>
      </div>
    </div>
    <div class="site-description">
        <h2>利用者情報の外部送信について</h2>
<p>&#12288;報知新聞社（以下、「当社」といいます）が運営するボートレース専門サイト「BOATNAVI 報知（ボートナビ報知）」（以下、「当サイト」といいます）では、当サイトをより便利にお使いいただくため外部の事業者のサービス（以下、「外部サービス」といいます）を使用しており、当サイトを利用されるユーザーの方に関する情報（以下、「利用者情報」といいます）をユーザーの端末（パソコンやスマートフォン）からプライバシー保護を尊重した上で送信しています。送信する利用者情報は、それぞれ送信先の外部サービスを提供する事業者のプライバシーポリシー等に基づいて管理・利用されています。</p>

<h3>1. 当サイトでの送信する情報の利用目的</h3>
<p>当サイトでは、以下の目的で情報を送信しています。送信先での利用目的の詳細は、「3.外部送信先一覧」から送信先のプライバシーポリシー等をご確認ください。</p>
<p>（1）自社の広告事業として広告の掲載・配信をするため<br />
&#12288;当サイト上の広告枠に広告を掲載しています。</p>
<p>（2）閲覧の傾向や履歴の分析のため<br />
&#12288;当サイトでの行動履歴情報を収集し、その傾向を分析しています。</p>
<p>（3）広告効果の分析のため<br />
&#12288;当サイト上の広告に対して閲覧した情報、クリックした情報、およびサイト内での行動履歴情報を収集し、広告の効果を確認・計測・分析しています｡</p>
<p>（4）外部送信プログラムの管理のため<br />
&#12288;外部送信プログラムを、ツールを使って管理しています。</p>

<h3>2. 当サービスで送信する情報</h3>
<p>当サービスでは、以下の情報を送信しています。</p>
<p>（1）閲覧した内容についての情報（カテゴリー分類、商品名等）</p>
<p>（2）閲覧履歴（閲覧した日時、URL、リンク元のURL等）</p>
<p>（3）閲覧した機器の位置情報（IPアドレス）</p>
<p>（4）閲覧した人や機器を識別する情報（ユーザーID、ブラウザ識別子、デバイス識別子等）</p>
<p>（5）外部送信プログラムの情報（タグ等）</p>

<h3>3. 外部送信先一覧</h3>
<p>当社が当サイトにおいて利用者情報を送信している外部サービスとその送信先の事業者、利用者情報の内容、利用目的については以下のとおり（外部サービスごとに表示）です。オプトアウトについての記載があるものは、リンクをクリックしてその後の指示に従って操作することで、情報の送信や送信先での利用の停止を行うことができます。</p>
<p class="about__text">対象サイト（当サイト）：BOATNAVI 報知（ボートナビ報知）</p>

<p class="about__text">Googleアナリティクス
<br />&#12288;送信先事業者：<a href="https://policies.google.com/privacy?hl=ja" target="_blank">Google LLC</a>
<br />&#12288;利用目的：閲覧の傾向や履歴の分析のため<br />&#12288;送信する利用者情報の内容：閲覧履歴、閲覧した人や機器を識別する情報
<br />&#12288;オプトアウト（利用の停止）：オプトアウトページ（<a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>）
</p>

<p class="about__text">PIANO
<br />&#12288;送信先事業者：<a href="https://piano.io/privacy-policy/" target="_blank">PIANO Japan株式会社</a>
<br />&#12288;利用目的：利用者による閲覧の傾向や履歴の分析、広告の配信、広告効果の分析、ウェブサイトの利便性を向上させるための各種情報の配信
<br />&#12288;送信する利用者情報の内容：閲覧履歴、閲覧した人や機器を識別する情報
<br />&#12288;オプトアウト（利用の停止）：オプトアウトページ（<a href="https://hochi.news/membership/piano/" target="_blank">https://hochi.news/membership/piano/</a>）
</p>

<p class="about__text">Google AdSense
<br />&#12288;送信先事業者：<a href="https://policies.google.com/privacy?hl=ja" target="_blank">Google LLC</a>
<br />&#12288;利用目的：自社の広告事業として広告の掲載・配信
<br />&#12288;送信する利用者情報の内容：閲覧した内容についての情報、閲覧した人や機器を識別する情報
<br />&#12288;オプトアウト（利用の停止）：オプトアウトページ（<a href="https://policies.google.com/technologies/ads?hl=ja" target="_blank">https://policies.google.com/technologies/ads?hl=ja</a>）
</p>


<div style="border-top:1px solid #EFEFEF;margin-top:40px;padding-top:40px;">
<p>当社は、読売新聞社（読売新聞グループ本社および東京本社、大阪本社、西部本社を指します）と共同で、当社および読売新聞社が運営するウェブサイトやアプリのアクセスデータを収集し、サービス向上のために利用しています。収集するアクセスデータには、住所や氏名など、特定の個人を直接識別する情報は含まれません。 収集しているデータや利用目的などの詳細については、<a href="https://info.yomiuri.co.jp/privacypolicy/datapolicy.html" target="_blank">こちら</a>のページ（クリックすると読売新聞社のサイトに遷移します）をご確認ください。</p>
</div>


    </div>
    </div>
</template>
    <script>
    import pianoSend from '../mixins/PrefsMixin'
    export default {
       name :'UserdataExternalTransmissionPage',
       mixins: [pianoSend],
       head:function(){
        return {
          title: function(){
            return {
              inner: 'データの収集と利用について',
              separator: ' - ',
              complement: 'BOATNAVI報知'
            }
          }
        }
      },
      created:function(){
         this.pianoSend('データの収集と利用について');
    
      },
      mounted () {
        this.$emit('updateHead');
    },
    }
    </script>
    
    <style scoped>
    
    .site-description{
      padding:20px;
      max-width:800px;
      margin:-20px auto 20px auto;
    }
    pre{
      white-space: pre-wrap ;
      line-height: 1.7;
      font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
        font-size:rem-calc(16)
    }
    .hero-section{
        background-image:linear-gradient(180deg, rgba(238,238,238,0.00) 0%, rgba(240,240,240,0.11) 0%, rgba(239,239,239,0.05) 0%, rgba(247,247,247,0.51) 16%, rgba(251,251,251,0.76) 37%, #FFFFFF 100%), url(/images/bg_hero2.jpg);
    }
    .hero-section-text h1{
        color:rgb(6, 21, 88);
    }

    .site-description p{
        font-size:16px;
    }
    .site-description h2{
        font-size:20px;
        font-weight: 700;
        margin-bottom:20px;
    }
    .site-description h3{
        font-size:18px;
        font-weight: 700;
        color:#160133;
        margin-bottom:20px;
        margin-top:20px;
    }
    .site-description a{
      text-decoration: underline;
    }
    </style>