<template>
<nav class="racenav-menu-wrapper">
<ul class="menu racenav-menu">
<li><router-link :class="current == 'shussou' ? 'current':'' " :to="{ name: 'Shussou', params: { date:racedate,jo:joid,race:rno }}">出走表</router-link></li>
<li><router-link :class="current == 'hochi_scope' ? 'current':'' " :to="{ name: 'HochiScope', params: { date:racedate,jo:joid,race:rno }}">報知スコープ</router-link></li>
<li><router-link :class="current == 'before_info' ? 'current':'' " :to="{ name: 'BeforeInfo', params: { date:racedate,jo:joid,race:rno }}">直前データ</router-link></li>
<li><router-link :class="current == 'odds' ? 'current':'' " :to="{ name: 'Odds', params: { date:racedate,jo:joid,race:rno,tabname:'rt3' }}">オッズ</router-link></li>
<li v-if="is_result_available"><router-link :class="current == 'result' ? 'current':'' " :to="{ name: 'Result', params: { date:racedate,jo:joid,race:rno }}">結果</router-link></li>
</ul>
</nav>
</template>
<script>
export default{
  props: {
    current: {
      type: String,
      required: false,
    },
    racedate: {
      required: true,
    },
    joid:{
        required: true,
    },
    rno:{
        required: true,
    },
    is_result_available:{
      required: false,
    }
  },
  methods: {
  }
}
</script>