<template>
<div class="user-register-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> パスワード再設定
    </li>
  </ul>
</nav>
  <div class="register-form">
    <h1>{{ title }}</h1>
     <div v-if="!send_done">
    <p>ご登録のメールアドレス、新しいパスワード、ご登録のメールアドレスにお送りした確認コードを入力してください。</p>
      <div v-show="error_message != ''" class="callout alert">
          <p>{{ error_message }}</p>
      </div>
    <form @submit.prevent="send">

    <label>メールアドレス
    <input type="email" placeholder="メールアドレス" v-model="mail" required>
    </label>

    <label>新パスワード
    <input type="password" placeholder="パスワード" v-model="password" required>
    </label>
    <p class="help-text" id="passwordHelpText">8文字以上で、アルファベット大文字、小文字、数字、次の特殊文字
「= + - ^ $ * . [ ] { } ( ) ? " ! @ # % &amp; / \ , &gt; &lt; ' : ; | _ ~ `」から、それぞれ1文字以上使用してください。</p>

    <label>新パスワード確認
    <input type="password" placeholder="パスワード確認" v-model="password_confirm" required>
    </label>

    <label>確認コード
    <input type="text" placeholder="確認コード" v-model="confirm_code" required>
    </label>

    <button class="button" type="submit">送信</button>
    </form>
    </div>
    <div class="callout success" v-else>
        <p>パスワードを変更しました。</p>
        <p><router-link :to="{name:'UserLogin'}">▶︎ログインページ</router-link></p>
    </div>
  </div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserFChPassword',
  mixins: [pianoSend],
  data:function(){
    return{
      mail:'',
      title:'パスワード再設定',
      password:'',
      password_confirm:'',
      error_message:'',
      confirm_code:'',
      send_done:false
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },

  created:function(){
    this.$emit('updateHead')
    this.pianoSend('ユーザーページ','パスワード再設定');
    
  },
     
  methods: {
    send:function(){
      let loader = this.$loading.show({});
      if(this.$data.password == this.$data.password_confirm){
        
          this.$cognito.resetPassword(this.$data.mail,this.$data.password,this.confirm_code).then((result)=>{
            result;
             // if(result){
                this.$data.send_done = true;
                loader.hide();
              //}
          }).catch((err)=>{ // eslint-disable-line
          loader.hide();
            if(err.name == 'CodeMismatchException' || err.name == 'InvalidParameterException' ){
              this.$data.error_message = '確認コードが正しくありません';
            }else if(err.name == 'InvalidPasswordException'){
              this.$data.error_message = 'パスワードは8文字以上で、アルファベット大文字、小文字、数字、次の特殊文字「= + - ^ $ * . [ ] { } ( ) ? " ! @ # % &; / \\ ; , > < \' : ; | _ ~ `」からそれぞれ1文字以上使用してください。';
            }else if(err.name =='LimitExceededException'){
              this.$data.error_message = '試行回数の制限を超えました。しばらく時間をおいてお試し下さい。';
            }else{
              this.$data.error_message = 'エラーが発生しました。';
            }
            
          })
      }else{
        loader.hide();
         this.$data.error_message = 'パスワードが一致しません。';
      }
    }
  }
}
</script>
