<template>
<div>
<div class="hero-section">
  <div class="hero-section-text">
    <h1>会員規約</h1>
  </div>
</div>
<div class="site-description">

<p>
     株式会社報知新聞社（以下「当社」といいます）が運営するボートレース専門サイト「BOATNAVI
    報知（ボートナビ報知）」（以下「本サイト」といいます）は、当社が第２条に定める会員に対して、勝舟投票券を購入する際の情報を提供することを目的とし、勝舟投票券の購入には関与致しません。提供した情報によって会員が何らかの損害を被っても一切の責任は負いませんので、あらかじめご了承ください。本サイトへのご登録・ご利用の際に、本規約をご確認いただきますようお願い申し上げます。
</p>
<p>
    第１条（本規約の適用）
</p>
<p>
    本規約は、本サイトにおいて、会員その他ご利用の皆様に提供するボートレース情報サービス全般（以下「本サービス」といいます）の利用条件について定めるものです。
</p>
<p>
    第２条（会員）
</p>
<p>
    １．会員とは、第４条に定める方法により本サイトの利用を申し込み、当社がそれを承諾した方とします。当社の承諾をもって、当社と利用者との間に本サイトの利用契約（以下「本契約」といいます）が成立するものとします。
</p>
<p>
    ２．会員が本サイトから入手した情報は、会員自身のボートレース予想の参考等、あくまで個人で使用することを前提とし、その範囲を超えて、情報を第三者に有償無償を問わずに使用させることはできません。本条に違反した利用の結果、当社及び第三者に何らかの損害が生じた場合、会員はそのすべての損害を賠償するものとします。
</p>
<p>
    ３．会員は、本サービスの利用に必要となる通信料金、コンピューター・スマートフォン・携帯電話等の通信機器にかかるすべての費用を自ら負担するものとします。
</p>
<p>
    ４．会員は、当社への届出内容に変更があった場合には、速やかに当社に所定の方法で変更の届出をするものとします。届出がなかったことで会員が不利益を被ったとしても、当社は一切その責任を負いません。
</p>
<p>
    第３条（サービス）
</p>
<p>
    １．本サービスは、会員が勝舟投票券を購入する際の、出走表、データ、結果等の参考情報をインターネットを通じ提供します。
</p>
<p>
    ２．天災、火災、停電などの不可抗力により本サービスの提供が困難な場合や、システムのトラブル、設備等の保守点検の必要が生じた場合は、事前の連絡をすることなく本サービスを中断することがあります。この場合、当社は、会員または第三者が被った損害、不利益について一切の責任を負いません。なお、定期的なサービスの休止は本サイトで随時お知らせします。
</p>
<p>
    第４条（利用申し込み）
</p>
<p>
    １．本サイトの会員となる者は、本サイトに掲載されている当社所定の申し込み方法により利用手続きを行うものとします。
</p>
<p>
    ２．本規約はすべての利用者に適用されます。利用者は利用申し込みの際、本規約に同意し、これを遵守するものとします。
</p>
<p>
    第５条（ＩＤおよびパスワード）
</p>
<p>
    １．会員は、会員自身の責任において、ＩＤおよび自らが設定したパスワードの管理、使用を行うものとします。
</p>
<p>
    ２．当社は、会員のＩＤおよび自らが設定したパスワードが第三者に使用されたことにより、当該会員が被る被害について、当該会員の故意過失の有無にかかわらず一切の責任を負いません。
</p>
<p>
    ３．会員は、自らが設定したパスワードを失念した場合は直ちに当社に申し出るものとし、当社の指示に従うものとします。
</p>
<p>
    第６条（禁止事項）
</p>
<p>
    会員は、本サービスを利用するにあたり、次の各号に該当する行為を行わないものとします。
</p>
<p>
    ①本サイトのシステム・データ・情報提供内容を改ざん、損壊する行為
</p>
<p>
    ②有害なコンピュータープログラム等を送信または書き込む行為
</p>
<p>
    ③パスワードの不正使用、譲渡、貸与、名義変更又は売買する等の行為
</p>
<p>
    ④第三者に本サービスを利用させる行為
</p>
<p>
    ⑤第三者または当社の著作権、その他の知的財産権を侵害する行為、または侵害するおそれのある行為
</p>
<p>
    ⑥第三者または当社の財産、名誉、プライバシー等を侵害する行為、または侵害するおそれのある行為
</p>
<p>
    ⑦他の利用者もしくは第三者を誹謗中傷し、または名誉を傷つける行為
</p>
<p>
    ⑧本サービスの利用または提供を妨げる行為
</p>
<p>
    ⑨法令または公序良俗に反する行為
</p>
<p>
    ⑩その他、当社が不適当と判断する行為
</p>
<p>
    第７条（解約）
</p>
<p>
    会員は、本契約を解約する場合、当社所定の方法にて当社に届け出るものとし、すべての解約手続きが完了した時点で、解約の効力が生じるものとします。
</p>
<p>
    第８条（会員情報）
</p>
<p>
    １．会員は、利用申し込みの際に当社に届け出た事項を、当社のデータベースに登録することに同意します。
</p>
<p>
    ２．当社は、会員情報を機密として管理し、次のいずれかに該当する場合を除き、第三者に提供しないものとします。
</p>
<p>
    （１）会員に利用目的を提示し、会員の同意が得られた場合
</p>
<p>
    （２）法令等により開示が求められた場合
</p>
<p>
    （３）会員識別ができない状態で提供する場合
</p>
<p>
    ３．会員は、本サービスの利用に際して知り得た他の個人会員の情報等を第三者に漏洩してはいけません。
</p>
<p>
    第９条（会員資格の停止・取り消し）
</p>
<p>
    １．当社は、会員が次のいずれかの事項に該当すると判断した場合、会員に事前の通知または催告を要することなく、本サービスの利用の一時停止もしくは会員資格を取り消すことができます。
</p>
<p>
    （１）本規約第６条に定める禁止行為を会員が行った場合
</p>
<p>
    （２）登録の内容に虚偽の事項が含まれていた場合
</p>
<p>
    （３）反社会的勢力の構成員またはその関係者であることが判明した場合
</p>
<p>
    （４）本規約に違反する行為があった場合
</p>
<p>
    （５）その他、合理的事由により会員資格を取り消すことが相当と当社が判断した場合
</p>
<p>
    ２．会員が前項各号のいずれかに該当することで、当社が損害を被った場合、当社は会員資格取り消しの有無にかかわらず、当該会員に損害の賠償を請求できるものとします。
</p>
<p>
    第10条（変更）
</p>
<p>
    当社は、会員の了承を得ることなく、本規約および本サービスの内容等を変更することができ、会員はあらかじめこれを承諾するものとします。本規約および本サービスの内容の変更等は、その旨及び変更後の規約を本サイトに表示した時点より効力を生じるものとし、表示以降の本サイトの利用条件は変更後の規約を適用します。
</p>
<p>
    第11条（免責）
</p>
<p>
    １．当社は、本サイトが提供する情報等について、その完全性、正確性、有用性等に関し、一切責任を負いません。
</p>
<p>
    ２．当社は、本サイトが提供する情報に基づく結果について、何ら保証するものではありません。
</p>
<p>
    ３．当社は、本サイトが提供するサービスの利用により発生した会員の損害（第三者との間で生じたトラブルに起因する損害を含みます）に対し、会員が本規約を遵守したどうかに関係なく、一切責任を負いません。
</p>
<p>
    ４．当社は、すべての利用環境において本サイトが利用可能な状態にあることを保証しているものではなく、また、アクセス過多やその他の予期しない要因で表示速度の低下や障害等が発生し、一時的に本サイトを利用できない状況が生じたとしても、サービス提供の不履行には該当しないものとします。
</p>
<p>
    ５．本規約の一部の効力が法令や確定判決により無効とされた場合でも、その他の条項は引き続き効力を有するものとします。
</p>
<p>
    第12条（著作権）
</p>
<p>
    登録会員の会員情報を除き、本サイトのコンテンツおよび権利は、当社に帰属します
</p>
<p>
    第13条（損害賠償）
</p>
<p>
    １．当社は、会員が本サービスの利用に伴い被った損害に対し、賠償の責任を負いません。
</p>
<p>
    ２．会員が本サービスの利用に伴い他の会員または第三者に対して損害を与えた場合、損害の賠償はすべて当該会員が行うものとし、当社は賠償の責任を負いません。
</p>
<p>
    ３．会員が本規約に違反し、不正または違法な行為によって当社に損害を与えた場合、当社は会員に対して損害賠償の請求ができるものとします。
</p>
<p>
    第14条（サービスの終了）
</p>
<p>
    １．当社は、事前に本サイトで告知した上で、本サイトの運営および本サービスを終了することがあります。
</p>
<p>
    ２．当社は、前項の手続きを経ることで、本サイトの運営および本サービス終了に伴う責任を免れるものとします。
</p>
<p>
    ３．本サイトの終了により、会員の利用にかかる一切の権利は、当社が別段の扱いを定める旨を明示的に公表しない限り、直ちに消滅します。
</p>
<p>
    第15条（個人情報）
</p>
<p>
    １．当社は、会員情報その他会員による本サービスの利用に関連して当社が知り得る会員の情報（以下「個人情報」といいます）を、本規約に基づき適切に取り扱うものとします。
</p>
<p>
    ２．当社は、会員の個人情報を、あらかじめ会員の同意を得た場合、法令で認められた場合を除き、本サービスの提供以外の目的のために利用しないとともに、第三者に開示、提供しないものとします。なお、以下の場合については、本サービスの提供の目的の範囲内で適正に利用させていただきます。
</p>
<p>
    （１）会員に対し、当社、または当社の業務提携先等の広告宣伝のための電子メール等を送付する場合
</p>
<p>
    （２）会員から個人情報の利用に関する同意を求めるための電子メールを送付する場合
</p>
<p>
    （３）個々の会員に合ったサービスの提供およびオンライン広告の配信
</p>
<p>
    （４）個々の会員に有益と思われる当社のサービス（本サイト上のサービスに限りません）、または当社の業務提携先の商品、サービス等の案内の電子メール等による送付
</p>
<p>
    ３．当社は、会員の個人情報の属性の集計、分析を行い、個人が識別・特定できないように加工したもの（以下「統計資料」といいます。）を作成し、新規サービスの開発等の業務の遂行のために利用、処理することがあります。また、当社は、統計資料を業務提携先等に提供することがあります。
</p>
<p>
    ４．当社は、本サービスの利用において「クッキー」を使用し閲覧状況などの情報を自動的に取得しています。詳しくは下記リンクをご覧ください。
</p>
<p>
    クッキー等の使用とターゲティング広告については<a href="https://www.hochi.co.jp/policy/cookie.html" target="_blank">こちらへ</a>
</p>
<p>
    第16条（当社からの通知）
</p>
<p>
    １．当社は、オンライン上の表示その他当社が適当と判断する方法により、会員に対し随時必要な事項を通知します。
</p>
<p>
    ２．前項の通知は、当社が当該通知の内容をオンライン上に表示した時点より効力を生じるものとします。
</p>
<p>
    第17条（準拠法）
</p>
<p>
    本規約に関する準拠法は、日本法とします。
</p>
<p>
    第18条（紛争の解決）
</p>
<p>
    本規約に関する一切の紛争を司法に委ねるときは、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
</p>
<div style="margin-top:20px;text-align:right;"><p>以 上</p></div>
</div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
   name :'KiyakuPage',
   mixins: [pianoSend],
   head:function(){
    return {
      title: function(){
        return {
          inner: '会員規約',
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created:function(){
     this.pianoSend('会員規約');

  },
  mounted () {
    this.$emit('updateHead');
},
}
</script>

<style scoped>

.site-description{
  padding:20px;
  max-width:800px;
  margin:-20px auto 20px auto;
}
pre{
  white-space: pre-wrap ;
  line-height: 1.7;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
    font-size:rem-calc(16)
}
.hero-section{
    background-image:linear-gradient(180deg, rgba(238,238,238,0.00) 0%, rgba(240,240,240,0.11) 0%, rgba(239,239,239,0.05) 0%, rgba(247,247,247,0.51) 16%, rgba(251,251,251,0.76) 37%, #FFFFFF 100%), url(/images/bg_hero2.jpg);
}
.hero-section-text h1{
    color:rgb(6, 21, 88);
}
</style>