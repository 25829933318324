<template>
<div class="article-page special-page special-biwako-page">
<nav class="global-nav" role="navigation">
  <ul>
    <li>
      <router-link class="button dayrace-button" :to="{name:'Index'}">トップ</router-link>
    </li>
    <li>
      <router-link class="button dayrace-button" :to="{name:'DayRaceList',params:{date:today_str}}">本日のレース</router-link>
    </li>
  </ul>
</nav>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> 特集: {{ title }}
    </li>
  </ul>
</nav>
<div class="hero-section">
  <div class="hero-title">
  報知新聞創刊150周年記念 第44回びわこ王座決定戦
  2022/03/19 - 2022/03/24
  </div>
</div>
<div class="article-container">
<div class="article-wrapper" v-html="content"></div>
<div style="text-align:center;margin:40px auto;">
<Adsense
    class="adsbygoogle"
    data-ad-client="ca-pub-3076785709839281"
    data-ad-slot="9132319872"
    data-ad-format="rectangle"
    data-full-width-responsive="true">
</Adsense>
</div>
</div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name :'SpecialBiwako',
  mixins: [pianoSend],
  data:function(){
    return {
      date:'',
      title:'',
      content:'',
      today:'',
      today_str:'',
      id:''
    }
  },
   head:function(){
    return {
      title: function(){
        return {
          inner:  this.$data.title + ' - 報知新聞創刊150周年記念 第44回びわこ王座決定戦',
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created(){
      this.$data.id = this.$route.params['id'];
      this.getData();

      this.$data.today = new Date();
      this.$data.today_str = '' + this.$data.today.getFullYear() + ('0'+(this.$data.today.getMonth()+1)).slice(-2) + ('0'+this.$data.today.getDate()).slice(-2);
  
  },
  beforeRouteUpdate(_to, _from, _next) {
      
        if(_to.name == 'SpecialBiwako' && _from.name == 'SpecialBiwako'){
            this.$data.id =  _to.params['id'];
            this.getData();
        }

      _next();
  },
  methods:{
     getData(){
        let loader = this.$loading.show({});
        let ts = Math.floor(Number(Date.now())/1000/60);
        this.axios.get('/special_contents/'+this.$data.id+'.html?ts='+ts)
          .then((response) => {
             loader.hide();
            this.$data.content = response.data;

            let content_dom = document.createElement('div');
            content_dom.innerHTML = this.$data.content;
            this.$data.title = content_dom.getElementsByTagName('h1')[0].innerText;

            this.$emit('updateHead');
            this.pianoSend('記事',this.$data.title);
       
          })
          .catch((e) => { // eslint-disable-line
           
             loader.hide();
            this.$router.replace({ name:'NotFound'});
          });
     }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@900&display=swap&text=1早野智之１万円作戦耳情報板東宏明李風堂々藤原邦光のブログ舟券  ');
.special-biwako-page{
    .hero-section{
      background-image:linear-gradient(134deg,#00749b 0%, #25759a 16.26%, #077ea8 60%, #e15f5f 90%),url(/special_contents/assets/images/biwako/bg_hero.jpg);
      background-blend-mode: overlay,normal;
      background-position: left top,center center;
      margin-top:-10px;
      min-height:140px;
          justify-content: flex-start;
                 @media screen and (max-width:428px) {
                justify-content: center;
                position: relative;
                height:140px;
                 background-position: left top,center top;

                &::after{
                display:block;
                content:'.';
           
                overflow: hidden;
                text-indent: -99999px;
                position: absolute;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
             
                background-image:url(/special_contents/assets/images/biwako/bg2.svg);
                
                 width:120px;
                 height:120px;
                 left:5px;
                 bottom:-60px;
                
                
              }
             }
       .hero-title{
          background-image:url(/special_contents/assets/images/biwako/title_wide.svg);
          width:840px;
          height:60%;
          min-height:100px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          overflow: hidden;
          text-indent: -9999px;
          margin-left:10px;
          margin-top:-10px;
  

             @media screen and (max-width:428px) {
              background-image:url(/special_contents/assets/images/biwako/title_mobile.svg);
                width:90%;
                height:120px;
                margin-top:-15px;
                margin-left:20px;
             }
          
        }
      
    }


  .article-container{
    // background-image:url(/special_contents/assets/images/biwako/bg1.svg),url(/special_contents/assets/images/biwako/bg1.svg) ,url(/special_contents/assets/images/biwako/bg2.svg) ;
    // background-repeat: no-repeat,no-repeat,no-repeat;
    // background-size:40%,30%,30%;
    // background-position:right -10px,right 300px,left 600px;
    max-width: 1280px;
    overflow-x:hidden;
    
      .article-wrapper{
      max-width:800px;
        .article-header{
              background-image:url(/special_contents/assets/images/biwako/bg_article_title.svg);
              padding-bottom:55px;
              position: relative;
             
              &::after{
                display:block;
                content:'.';
                width:400px;
                height:200px;
                overflow: hidden;
                text-indent: -99999px;
                position: absolute;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                top:-20px;
                right:-250px;
                background-image:url(/special_contents/assets/images/biwako/bg1.svg);
                @media screen and (max-width:428px) {
                 width:120px;
                 height:50px;
                 top:auto;
                 right:-20px;
                 bottom:-20px;
                }
              }

            @media screen and (max-width:428px) {
              margin-left:10px;
              margin-right:10px;
              padding-left:0;
              padding-right:0;
              padding-bottom:20px;
            
                background-image:url(/special_contents/assets/images/biwako/bg_article_title.svg);
                background-size: contain;
                background-position:left bottom;

              h1{
                  line-height: 1.6;
              } 
              
      
            } 
        }



        .article-body{
            padding:20px 10px;
            clear:both;
        }


        p{
            font-size:16px;
              @media screen and (max-width:428px) { 
              font-size:14px;
            }
        }

        .image-grid{
          display:grid;
           grid-template-columns: 1fr 1fr 1fr;
           grid-gap: 10px;
           margin:30px 0 30px 0;
           @media screen and (max-width:428px) {
           grid-template-columns: 1fr 1fr;
            background-image:url(/special_contents/assets/images/biwako/bg1.svg);
            background-size:130%;
            background-repeat: no-repeat;
            background-position: right 0 bottom 90px;
           }
           &.grid2{
              grid-template-columns: 1fr 1fr;
           }
          .image-wrapper{
            margin:0;
            
            figure{
              
              background-color:#f7feff;
              position: relative;
             img{
               max-height:300px;
             }
       
              
            }
          }
        
        }

        .lead{
            p{
              font-weight:bold;
            }
        }

        .r-yoso{
          margin-top:40px;
          clear:both;
          position: relative;
                       &::after{
                display:block;
                content:'.';
                width:400px;
                height:150px;
                overflow: hidden;
                text-indent: -99999px;
                position: absolute;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                top:-70px;
                right:-250px;
                background-image:url(/special_contents/assets/images/biwako/bg1.svg);
                             @media screen and (max-width:428px) {
                               display: none;
             }
              }
          &__title{
            border-bottom:2px solid #0299C6;
            padding-bottom:10px;
            font-size:21px;
            font-weight: bold;
            color: #034F65;
            background-image: url(/special_contents/assets/images/biwako/icon_boat.svg);
            background-position: left center;
            background-repeat: no-repeat;
            padding-left:80px;
             margin-bottom:20px;
              @media screen and (max-width:428px) {
                background-size:46px;
                 padding-left:50px;
                 font-size:18px;
              }
          }
          
        }

        .pit-meian{
          position: relative;
          clear:both;
          margin-top:50px;
          @media screen and (max-width:428px) {
            margin-top:80px;        
          }
          &__title{
               overflow: hidden;
               background-image: url(/special_contents/assets/images/biwako/title_pit.svg),url(/special_contents/assets/images/biwako/icon_meian.svg);
               background-repeat: no-repeat,no-repeat;
               background-position: left bottom 10px,left 150px bottom 10px;
               height:80px;
               text-indent: -9999px;
              border-bottom:2px solid #E15F5F;
              padding-bottom:10px;
              margin-bottom:20px;
              
          }
        &::after{
                display:block;
                content:'.';
                width:300px;
                height:150px;
                overflow: hidden;
                text-indent: -99999px;
                position: absolute;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                top:-40px;
                left:-270px;
                
                background-image:url(/special_contents/assets/images/biwako/bg2.svg);
                @media screen and (max-width:428px) {
                  left:0px;
                  top:-100px;
                  width:180px;
                  height:160px;
                }
              }
        }

        .blog-funaken{
          clear:both;
          border:2px solid #C4ECFF;
          padding:20px 30px 30px 30px;
          margin-top:40px;

          background-image:url(/special_contents/assets/images/biwako/bg_funaken.svg);
          background-position: center bottom 20px;
          background-repeat: no-repeat;
          @media screen and (max-width:428px) {
             padding:30px 10px 20px 10px;
          }

          &__title{
              font-family: 'Noto Serif JP', serif;
              font-feature-settings: "palt" 1;
              font-weight:900;
              overflow: hidden;
              background-image: url(/special_contents/assets/images/biwako/icon_yen.svg);
              background-repeat: no-repeat;
              background-position: left center;
              min-height:60px;
              line-height: 1.7;
              padding-left:60px;
              padding-top:5px;

              margin-bottom:30px;
              @media screen and (max-width:428px) {
              background-image: url(/special_contents/assets/images/biwako/icon_yen.svg);
               background-size:40px;
               padding-left:45px;
               min-height:40px;
               background-position: left center;
              }
          }
        }
      }
      .boatnavi-link{
        margin-top:60px;
                &__title{
            border-bottom:2px solid #F2CB43;
            padding-bottom:10px;
            font-size:21px;
            font-weight: bold;
            color: #034F65;
            background-image: url(/special_contents/assets/images/biwako/icon_boat2.svg);
            background-position: left center;
            background-repeat: no-repeat;
            padding-left:80px;
             margin-bottom:20px;
              @media screen and (max-width:428px) {
                background-size:46px;
                 padding-left:50px;
                 font-size:14px;
              }
          }
        .link-item{
          font-weight: bold;
          word-break: break-all;
          &::before{
            display:inline-block;
            content:'▶︎';
            margin-right:10px;
            color:#E15F5F
;
          }
        }
      }
   }
}

</style>