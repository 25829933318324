<template>
<div class="race-profile-page">
<nav class="global-nav" role="navigation">
  <ul>
    <li>
      <router-link class="button dayrace-button" :to="{name:'Index'}">トップ</router-link>
    </li>
    <li>
      <router-link class="button dayrace-button" :to="{name:'DayRaceList',params:{date:today_str}}">本日のレース</router-link>
    </li>
  </ul>
</nav>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> 選手詳細 - {{ profile.name }}
    </li>
  </ul>
</nav>
<h1 class="racer-name">{{ profile.name }} ({{ tablabel }})</h1>
<div class="racer-kana">{{ profile.kana }}</div>

<div class="grid-x racer-profile-grid-x">
<div class="cell small-12 medium-3 grid-x"> 
<div class="cell small-4 medium-12 racer-photo-cell">
<img :src="RACER_PHOTO_BASE_URL + toban+'.jpg'" alt="" class="racer-photo" />
</div>
<div class="cell small-8 medium-12 base-profile-cell" >
    <table class="base-profile-table">
      <tbody>
        <tr>
          <th>登録番号</th><td>{{toban}}</td>
          </tr>
          <tr>
            <th>生年月日</th><td>{{profile.birthday}}</td>
          </tr>
          <tr>
            <th>身長</th><td>{{profile.stature}}cm</td>
          </tr>
           <tr>
            <th>体重</th><td>{{profile.weight}}kg</td>
          </tr>
          <tr>
            <th>血液型</th><td>{{profile.blood_group}}</td>
          </tr>
          <tr>
            <th>支部</th><td>{{profile.sibu}}</td>
          </tr>
          <tr>
            <th>出身地</th><td>{{profile.syussinchi}}</td>
          </tr>
          <tr>
            <th>登録期</th><td>{{profile.entry_period}}</td>
          </tr>
          <tr>
            <th>級別</th><td>{{profile.kyu}}</td>
          </tr>
          </tbody>
    </table>
</div>
</div>
<!-- タブ別 -->

<div class="cell small-12 medium-9 tab-content-cell"> 
 <ul class="tabs racerprofile-contents-tabs">
  <li class="tabs-title" :class="tabname=='kibetsu' ? 'is-active':''"><router-link :to="{name:'RacerProfile',params:{toban:toban,tabname:'kibetsu'}}">期別成績</router-link></li>
  <li class="tabs-title" :class="tabname=='course' ? 'is-active':''"><router-link :to="{name:'RacerProfile',params:{toban:toban,tabname:'course'}}">コース別</router-link></li>
  <li class="tabs-title" :class="tabname=='kako' ? 'is-active':''"><router-link :to="{name:'RacerProfile',params:{toban:toban,tabname:'kako'}}">過去3節</router-link></li>
  <li class="tabs-title" :class="tabname=='yotei' ? 'is-active':''"><router-link :to="{name:'RacerProfile',params:{toban:toban,tabname:'yotei'}}">出場予定</router-link></li>
  </ul>

  <!-- 期別成績 -->
  <div id="kibetsu" class="tab-content kibetsu" v-if="tabname=='kibetsu' && kibetsu != null">
  <h2 class="tab-content-title">期別成績</h2>
  <div class="kibetsu-term">集計期間:{{ kibetsu.fdate }}〜{{ kibetsu.tdate }}</div>
  <table class="kibetsu-table">
    <tbody>
      <tr>
          <th>勝率</th><td>{{kibetsu.p_syoritu}}</td>
      </tr>
      <tr>
          <th>2連対率</th><td>{{kibetsu.p_2ren}}%</td>
      </tr>
      <tr>
           <th>出走回数</th><td>{{kibetsu.p_syussoukai}}回</td>
      </tr>
      <tr>
           <th>3連対率</th><td>{{kibetsu.p_3ren}}%</td>
      </tr>
      <tr>
           <th>優出回数</th><td>{{kibetsu.p_yusyosyutu}}回</td>
      </tr>
       <tr>
           <th>優勝回数</th><td>{{kibetsu.p_yusyokai}}回</td>
      </tr>
      <tr>
           <th>平均スタートタイミング</th><td>{{kibetsu.p_st}}</td>
      </tr>
      <tr>
           <th>フライング回数</th><td>{{kibetsu.p_f}}回</td>
      </tr>
      <tr>
           <th>出遅れ回数（選手責任）</th><td>{{kibetsu.p_l}}回</td>
      </tr>
      <tr>
           <th>能力指数</th><td>{{kibetsu.p_nouryoku}}</td>
      </tr>
      </tbody>
  </table> 

  <h3 class="tab-table-title">着順別回数</h3>
  <table class="tyakujyun-table">
    <tbody>

      <tr>
           <th>1着</th>
           <td><div class="graph" :style="'width:'+ (kibetsu.p_p1/kibetsu.p_syussoukai*100).toFixed(1)+'%;'"></div></td>
           <td>{{(kibetsu.p_p1/kibetsu.p_syussoukai*100).toFixed(1)}}% ({{kibetsu.p_p1}}回)</td>
      </tr>
      <tr>
           <th>2着</th>
           <td><div class="graph" :style="'width:'+ (kibetsu.p_p2/kibetsu.p_syussoukai*100).toFixed(1)+'%;'"></div></td>
           <td>{{(kibetsu.p_p2/kibetsu.p_syussoukai*100).toFixed(1)}}% ({{kibetsu.p_p2}}回)</td>
      </tr>
      <tr>
           <th>3着</th>
           <td><div class="graph" :style="'width:'+ (kibetsu.p_p3/kibetsu.p_syussoukai*100).toFixed(1)+'%;'"></div></td>
           <td>{{(kibetsu.p_p3/kibetsu.p_syussoukai*100).toFixed(1)}}% ({{kibetsu.p_p3}}回)</td>
      </tr>
      <tr>
           <th>4着</th>
           <td><div class="graph" :style="'width:'+ (kibetsu.p_p4/kibetsu.p_syussoukai*100).toFixed(1)+'%;'"></div></td>
           <td>{{(kibetsu.p_p4/kibetsu.p_syussoukai*100).toFixed(1)}}% ({{kibetsu.p_p4}}回)</td>
      </tr>
      <tr>
           <th>5着</th>
           <td><div class="graph" :style="'width:'+ (kibetsu.p_p5/kibetsu.p_syussoukai*100).toFixed(1)+'%;'"></div></td>
           <td>{{(kibetsu.p_p5/kibetsu.p_syussoukai*100).toFixed(1)}}% ({{kibetsu.p_p5}}回)</td>
      </tr>
      <tr>
           <th>6着</th>
           <td><div class="graph" :style="'width:'+ (kibetsu.p_p6/kibetsu.p_syussoukai*100).toFixed(1)+'%;'"></div></td>
           <td>{{(kibetsu.p_p6/kibetsu.p_syussoukai*100).toFixed(1)}}% ({{kibetsu.p_p6}}回)</td>
      </tr>
             </tbody>
  </table> 
  </div>
  <!-- コース別 -->
  <div id="course-betsu" class="course-betsu" v-if="tabname=='course' && course != null" >
    <div>
    <h2 class="tab-table-title">コース別進入率</h2>
    <table class="course-betsu-table">
      <tbody>
      <tr>
        <th class="waku waku-1">1</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+course.p_sinnyu1+'%;'"></div></td>
        <td>{{ course.p_sinnyu1 }}%</td>
      </tr>
      <tr>
        <th class="waku waku-2">2</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+course.p_sinnyu2+'%;'"></div></td>
        <td>{{ course.p_sinnyu2 }}%</td>
      </tr>
      <tr>
        <th class="waku waku-3">3</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+course.p_sinnyu3+'%;'"></div></td>
        <td>{{ course.p_sinnyu3 }}%</td>
      </tr>
      <tr>
        <th class="waku waku-4">4</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+course.p_sinnyu4+'%;'"></div></td>
        <td>{{ course.p_sinnyu4 }}%</td>
      </tr>
      <tr>
        <th class="waku waku-5">5</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+course.p_sinnyu5+'%;'"></div></td>
        <td>{{ course.p_sinnyu5 }}%</td>
      </tr>
      <tr>
        <th class="waku waku-6">6</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+course.p_sinnyu6+'%;'"></div></td>
        <td>{{ course.p_sinnyu6 }}%</td>
      </tr>
      </tbody>
    </table>  
    </div>
    
    <div>
      <h2 class="tab-table-title">コース別平均スタートタイミング</h2>
      <table class="course-betsu-table">
        <tbody>
       <tr>
        <th class="waku waku-1">1</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+(1.0 - Number(course.p_sttiming1))*100 +'%;'"></div></td>
        <td>{{  Number(course.p_sttiming1).toFixed(2)  }}</td>
      </tr>
       <tr>
        <th class="waku waku-2">2</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+(1.0 - Number(course.p_sttiming2))*100 +'%;'"></div></td>
        <td>{{  Number(course.p_sttiming2).toFixed(2)  }}</td>
      </tr>
       <tr>
        <th class="waku waku-3">3</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+(1.0 - Number(course.p_sttiming3))*100 +'%;'"></div></td>
        <td>{{  Number(course.p_sttiming3).toFixed(2)  }}</td>
      </tr>
      <tr>
        <th class="waku waku-4">4</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+(1.0 - Number(course.p_sttiming4))*100 +'%;'"></div></td>
        <td>{{  Number(course.p_sttiming4).toFixed(2)  }}</td>
      </tr>
      <tr>
        <th class="waku waku-5">5</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+(1.0 - Number(course.p_sttiming5))*100 +'%;'"></div></td>
        <td>{{  Number(course.p_sttiming5).toFixed(2)  }}</td>
      </tr>
      <tr>
        <th class="waku waku-6">6</th>
        <td class="graph-cell"><div class="graph" :style="'width:'+(1.0 - Number(course.p_sttiming6))*100 +'%;'"></div></td>
        <td>{{  Number(course.p_sttiming6).toFixed(2)  }}</td>
      </tr>
      </tbody>
      </table>
    </div>

    <div class="rentairitsu-content">
      <h2 class="tab-table-title">コース別3連対率</h2>
      <div class="tyakuritu">
        <span class="t1">1着率</span><span class="t2">2着率</span><span class="t3">3着率</span>
      </div>
      <table class="course-betsu-table">
        <tbody>
       <tr>
        <th class="waku waku-1">1</th>
        <td  class="graph-cell">
        <div class="graph1" :style="'width:'+ course.p_3ren11 +'%;'">{{ Number(course.p_3ren11).toFixed(1) }}</div>
        <div class="graph2" :style="'width:'+ course.p_3ren12 +'%;'">{{ Number(course.p_3ren12).toFixed(1) }}</div>
        <div class="graph3" :style="'width:'+ course.p_3ren13 +'%;'">{{ Number(course.p_3ren13).toFixed(1) }}</div>
        </td>
        <td>{{  Number(course.p_3ren1).toFixed(1) }}%</td>
      </tr>
       <tr>
        <th class="waku waku-2">2</th>
         <td  class="graph-cell">
        <div class="graph1" :style="'width:'+ course.p_3ren21 +'%;'">{{ Number(course.p_3ren21).toFixed(1) }}</div>
        <div class="graph2" :style="'width:'+ course.p_3ren22 +'%;'">{{ Number(course.p_3ren22).toFixed(1) }}</div>
        <div class="graph3" :style="'width:'+ course.p_3ren23 +'%;'">{{ Number(course.p_3ren23).toFixed(1) }}</div>
        </td>
        <td>{{  Number(course.p_3ren2).toFixed(1) }}%</td>
      </tr>
       <tr>
        <th class="waku waku-3">3</th>
        <td  class="graph-cell">
        <div class="graph1" :style="'width:'+ course.p_3ren31 +'%;'">{{ Number(course.p_3ren31).toFixed(1) }}</div>
        <div class="graph2" :style="'width:'+ course.p_3ren32 +'%;'">{{ Number(course.p_3ren32).toFixed(1) }}</div>
        <div class="graph3" :style="'width:'+ course.p_3ren33 +'%;'">{{ Number(course.p_3ren33).toFixed(1)}}</div>
        </td>
        <td>{{  Number(course.p_3ren3).toFixed(1) }}%</td>
      </tr>
      <tr>
        <th class="waku waku-4">4</th>
        <td  class="graph-cell">
        <div class="graph1" :style="'width:'+ course.p_3ren41 +'%;'">{{  Number(course.p_3ren41).toFixed(1) }}</div>
        <div class="graph2" :style="'width:'+ course.p_3ren42 +'%;'">{{  Number(course.p_3ren42).toFixed(1) }}</div>
        <div class="graph3" :style="'width:'+ course.p_3ren43 +'%;'">{{  Number(course.p_3ren43).toFixed(1) }}</div>
        </td>

        <td>{{  Number(course.p_3ren4).toFixed(1) }}%</td>
      </tr>
      <tr>
        <th class="waku waku-5">5</th>
        <td  class="graph-cell">
        <div class="graph1" :style="'width:'+ course.p_3ren51 +'%;'">{{  Number(course.p_3ren51).toFixed(1) }}</div>
        <div class="graph2" :style="'width:'+ course.p_3ren52 +'%;'">{{  Number(course.p_3ren52).toFixed(1) }}</div>
        <div class="graph3" :style="'width:'+ course.p_3ren53 +'%;'">{{  Number(course.p_3ren53).toFixed(1) }}</div>
        </td>
        <td>{{  Number(course.p_3ren5).toFixed(1) }}%</td>
      </tr>
      <tr>
        <th class="waku waku-6">6</th>
        <td  class="graph-cell">
        <div class="graph1" :style="'width:'+ course.p_3ren61 +'%;'">{{  Number(course.p_3ren61).toFixed(1) }}</div>
        <div class="graph2" :style="'width:'+ course.p_3ren62 +'%;'">{{  Number(course.p_3ren62).toFixed(1) }}</div>
        <div class="graph3" :style="'width:'+ course.p_3ren63 +'%;'">{{  Number(course.p_3ren63).toFixed(1) }}</div>
        </td>
        <td>{{  Number(course.p_3ren6).toFixed(1) }}%</td>
      </tr>
      </tbody>
      </table>
    </div>

    <div>
      <h2 class="tab-table-title">コース別スタート順</h2>
    <table class="course-betsu-table">
        <tbody>
       <tr>
        <th class="waku waku-1">1</th>
         <td class="graph-cell"><div class="graph" :style="'width:'+(6.0 - Number(course.p_stjun1))*20 +'%;'"></div></td>
        <td>{{ course.p_stjun1 }}</td>
      </tr>
       <tr>
        <th class="waku waku-2">2</th>
         <td class="graph-cell"><div class="graph" :style="'width:'+(6.0 - Number(course.p_stjun2))*20 +'%;'"></div></td>
        <td>{{ course.p_stjun2  }}</td>
      </tr>
       <tr>
        <th class="waku waku-3">3</th>
         <td class="graph-cell"><div class="graph" :style="'width:'+(6.0 - Number(course.p_stjun3))*20 +'%;'"></div></td>
        <td>{{ course.p_stjun3 }}</td>
      </tr>
      <tr>
        <th class="waku waku-4">4</th>
         <td class="graph-cell"><div class="graph" :style="'width:'+(6.0 - Number(course.p_stjun4))*20 +'%;'"></div></td>
        <td>{{ course.p_stjun4 }}</td>
      </tr>
      <tr>
        <th class="waku waku-5">5</th>
         <td class="graph-cell"><div class="graph" :style="'width:'+(6.0 - Number(course.p_stjun5))*20 +'%;'"></div></td>
        <td>{{ course.p_stjun5 }}</td>
      </tr>
      <tr>
        <th class="waku waku-6">6</th>
         <td class="graph-cell"><div class="graph" :style="'width:'+(6.0 - Number(course.p_stjun6))*20 +'%;'"></div></td>
        <td>{{ course.p_stjun6 }}</td>
      </tr>
      </tbody>
      </table>
    </div>
</div>
<!-- 過去3節 -->
<div id="kako-3setu" class="kako-3setsu" v-show="tabname=='kako' && setu != null">
  <h2 class="tab-content-title">過去３節成績</h2>
  <div class="table-wrapper">
  <table class="kako-table">
   <thead>
   <tr>
     <th>開催期間</th>
     <th>ボート<br />レース場</th>
     <th class="grade"></th>
     <th></th>
     <th class="seiseki">節間成績</th>
   </tr>
   </thead>

   <tbody>
     <tr v-for="n in 3" v-bind:key="n" v-show="setu['fdate'+n]" >
       <td><span class="date">{{ setu['fdate'+n] }}</span> <br />～<span class="date">{{ setu['tdate'+n]  }}</span></td>
       <td >{{ setu['jname'+n] }}</td>
       <td class="grade">
         <span class="grade-label" :class="'g-'+setu['gcd'+n]">{{ setu['grade'+n] }}</span>
         <span class="f-hearts-icon" v-if="setu['gcd'+n] == '5' || setu['gcd'+n] == '6' || setu['gcd'+n] == '8'">&hearts;</span>
       </td>
       <td class="ktitl">
         <router-link v-if="setu['jcd'+n] != undefined " :to="{ name: 'JoDateIndex', params: {jo:setu['jcd'+n],date:String(setu['fdate'+n]).replace(/\-/g,'')}}" >{{ setu['ktitl'+n] }}</router-link>
         <div class="setukan-seiseki">節間成績：{{ setu['setu'+n] }}</div>
        </td>
       <td class="seiseki">{{ setu['setu'+n] }}</td>
     </tr>
  </tbody>

  </table>
  </div>
</div>
<!-- 出場予定 -->
<div id="yotei" class="yotei" v-show="tabname=='yotei'">
<div v-if="yotei_today">
   <h2 class="tab-content-title">本日出走予定</h2>
<table class="yotei-table">
  <thead>
    <tr>
      <th>日付</th>
      <th>ボート<br />レース場</th>
      <th></th>
      <th></th>
      <th>1走</th>
      <th>2走</th>
    </tr>
  </thead>
  <tbody>
    <tr v-if="yotei_today != undefined && yotei_today.fdate1 != undefined">
      <td><span class="date">{{ yotei_today.sdate1}}</span></td>
      <td>{{ yotei_today.sjname1}}</td>
      <td class="grade">
        <span class="grade-label" :class="'g-'+yotei_today.gcd1">{{ yotei_today.sgrade1}}</span>
        <span class="f-hearts-icon" v-if="yotei_today.gcd1 == '5' || yotei_today.gcd1  == '6' ||yotei_today.gcd1 == '8'">&hearts;</span>
      </td>
      <td class="ktitl">
        <router-link v-if="yotei_today.sjcd1 != undefined "  :to="{ name: 'JoDateIndex', params: {jo:yotei_today.sjcd1,date:String(yotei_today.fdate1).replace(/\-/g,'')}}">{{ yotei_today.ktitl1 }}</router-link>
      </td>
      <td>
        <router-link v-if="yotei_today.srno11 != undefined " :to="{name:'Shussou',params:{date:String(yotei_today.sdate1).replace(/\-/g,''),jo:yotei_today.sjcd1,race:yotei_today.srno11}}">{{ yotei_today.srno11 }}</router-link>
      </td>
      <td>
        <router-link v-if="yotei_today.srno12 != undefined " :to="{name:'Shussou',params:{date:String(yotei_today.sdate1).replace(/\-/g,''),jo:yotei_today.sjcd1,race:yotei_today.srno12}}">{{ yotei_today.srno12}}</router-link>
      </td>
    </tr>
    <tr v-if="yotei_today  != undefined && yotei_today.fdate2 != undefined">
      <td>{{ yotei_today.sdate2}}</td>
      <td>{{ yotei_today.sjname2}}</td>
      <td class="grade">
        <span class="grade-label" :class="'g-'+yotei_today.gcd2">{{ yotei_today.sgrade2}}</span>
        <span class="f-hearts-icon" v-if="yotei_today.gcd2 == '5' || yotei_today.gcd2  == '6' ||yotei_today.gcd2 == '8'">&hearts;</span>
      </td>
      <td class="ktitl">
       <router-link  v-if="yotei_today.sjcd2 != undefined " :to="{name: 'JoDateIndex', params: {jo:yotei_today.sjcd2,date:String(yotei_today.fdate2).replace(/\-/g,'')}}">{{ yotei_today.ktitl2 }}</router-link>
      </td>
      <td>
         <router-link v-if="yotei_today.srno21 != undefined " :to="{name:'Shussou',params:{date:String(yotei_today.sdate2).replace(/\-/g,''),jo:yotei_today.sjcd2,race:yotei_today.srno21}}">{{ yotei_today.srno21 }}</router-link>
      </td>
      <td>
         <router-link v-if="yotei_today.srno22 != undefined " :to="{name:'Shussou',params:{date:String(yotei_today.sdate2).replace(/\-/g,''),jo:yotei_today.sjcd2,race:yotei_today.srno22}}">{{ yotei_today.srno22 }}</router-link>
      </td>
    </tr>
  </tbody>
</table>
</div>

   <h2 class="tab-content-title">出場予定</h2>
  <table class="yotei-table">
    <thead>
      <tr>
      <th>開催期間</th>
      <th>ボート<br />レース場</th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="yt in yotei" v-bind:key="yt.fdate" >
      <td><span class="date">{{ yt.fdate }}</span> <br />〜<span class="date">{{ yt.tdate }}</span></td>
      <td>{{ yt.jname }}</td>
      <td class="grade">
        <span class="grade-label" :class="'g-'+yt.gcd" v-if="yt.gcd == 0">SG</span>
        <span class="grade-label" :class="'g-'+yt.gcd" v-else-if="yt.gcd == 1">GⅠ</span>
        <span class="grade-label" :class="'g-'+yt.gcd" v-else-if="yt.gcd == 2">GⅡ</span>
        <span class="grade-label" :class="'g-'+yt.gcd" v-else-if="yt.gcd == 3">GⅢ</span>
        <span class="grade-label" :class="'g-'+yt.gcd" v-else-if="yt.gcd == 5">GⅢ</span>
        <span class="grade-label" :class="'g-'+yt.gcd" v-else-if="yt.gcd == 6">GⅢ</span>
        <span class="grade-label" :class="'g-'+yt.gcd" v-else-if="yt.gcd == 11">GⅢ</span>
        <span class="grade-label" :class="'g-'+yt.gcd" v-else-if="yt.gcd == 10">PGⅠ</span>
        <span class="grade-label" :class="'g-'+yt.gcd" v-else>一般</span>
        <span class="f-hearts-icon" v-if="yt.gcd == '5' || yt.gcd  == '6' ||yt.gcd == '8'">&hearts;</span>
      </td>
      <td class="ktitl"><router-link v-if="yt.jcd != undefined" :to="{ name: 'JoDateIndex', params: {jo:yt.jcd,date:String(yt.fdate).replace(/\-/g,'')}}" >{{ yt.ktitl }}</router-link></td>
    </tr>
    </tbody>
  </table>
</div>
</div>
</div>
</div>



</template>


<script>
import API_BASE_URL  from '../mixins/PrefsMixin';
import pianoSend from '../mixins/PrefsMixin'

export default {
  mixins: [API_BASE_URL,pianoSend],
  name: 'RacerProfile',
  data:function(){
    return {
        title:'',
        tabname:'kibetsu',
        tablabel:'',
        profile:{},
        kibetsu:{},
        course:{},
        setu:{},
        yotei:[],
        yotei_today:{},
        nama:'',
        yomi:'',
        today_str:'',
        today:{},
        title_labels:{'kibetsu':'期別成績','course':'コース別成績','kako':'過去３節成績','yotei':'出場予定'},
        toban:-1,
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  computed:{
        isMobile:function(){
          if((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
              return true;
          }
          return false;
        }
  },
  created:function(){
      this.$data.toban = this.$route.params['toban'];
      this.$data.tabname = this.$route.params['tabname'];

      this.$data.today = new Date();
      this.$data.today_str = '' + this.$data.today.getFullYear() + ('0'+(this.$data.today.getMonth()+1)).slice(-2) + ('0'+this.$data.today.getDate()).slice(-2);
  

      if(['kibetsu','course','kako','yotei'].includes(this.$data.tabname)){
        this.$data.tablabel = this.$data.title = this.$data.title_labels[this.$data.tabname]
        this.getData();
        return;
      }else{
        this.$router.replace({ name:'NotFound'});
      }      
  },
  beforeRouteUpdate(_to, _from, _next) {
    if(_to.name == 'RacerProfile'){
      this.$data.tabname = _to.params['tabname'];

      if(!['kibetsu','course','kako','yotei'].includes(this.$data.tabname)){
         this.$router.replace({ name:'NotFound'});
         return;
      }
       this.$data.tablabel = this.$data.title = this.$data.title_labels[this.$data.tabname];
     
      

      if( this.$data.toban !=  _to.params['toban']){
        this.$data.toban =  _to.params['toban'];
        this.$data.profile = {};
        this.$data.kibetsu = {};
        this.$data.course = {};
        this.$data.kako = [];
        this.$data.yotei = {};
        this.$data.yotei_today = {};
        
        this.getData();
      }else{
      this.$data.title = this.$data.profile.name +' ('+this.$data.tablabel+')';
      this.$emit('updateHead');
       this.pianoSend('選手詳細', this.$data.profile.name,this.$data.tablabel);
      }
    }
    _next();
  },
  methods: {
    getData() {
          this.axios.get(this.API_BASE_URL + 'racer_profile/'+ this.$data.toban)
          .then((response) => {

            this.$data.profile = response.data.profile;
            this.$data.kibetsu =  response.data.kibetsu;
            this.$data.course = response.data.course;
            this.$data.setu =  response.data.setu3;
            this.$data.yotei=  response.data.yotei;
            
            this.$data.yotei_today=  response.data.yotei_today;
            
            this.$data.title = this.$data.profile.name+' ('+this.$data.tablabel+')'; 
            this.$emit('updateHead');
            this.pianoSend('選手詳細', this.$data.profile.name,this.$data.tablabel);


            //console.log(response.data);

          })
          .catch((e) => { // eslint-disable-line
            
            this.$router.replace({ name:'NotFound'});
          });
      }
  }
}
</script>
<style scoped>

@media screen and (max-width:375px) { 

}
</style>