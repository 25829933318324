<template>
<div class="shinnyuzu">
<div class="course" v-for="(item,index) of st_list" :key="index">
<template v-if="item && item.teiban != null">
<span class="waku" :class="'waku-'+item.teiban">{{ item.teiban }}</span>
<img class="boat-icon" :src="'/images/boat_icons/b'+item.teiban+'.svg'" :alt="'コース'+(index+1)+' 艇番'+item.teiban"   v-bind:style="'left:'+setBoatPos(item.sst)+'%;'" />
<span class="sst" :class="(item.sst < 0 ? 'f-sst':'')+(kimarite != null ? ' has-kimarite':'')" >{{ item.sst > 0 ? item.sst : 'F '+Math.abs(item.sst) }}</span>
<span v-if="kimarite!= null" class="kimarite">{{ kimarite[index] }}</span>
</template>
</div>
</div>
</template>
<script>
export default{
  props: {
    st_list: {
      type: Array,
      required: true,
    },
    kimarite:{
        type: Array,
        required: false,
    }
  },
  methods:{
    setBoatPos:function(sst){
        let pos = 50-(sst*100);
        return pos >5 ? pos : 5;
    }
  },
  mounted () {
      //console.log(this.st_list)
  }
}
</script>
<style lang="scss" scoped>

.course{
    margin:10px 0;
    position: relative;
    height:40px;
    .waku{
        display: block;
        width:40px;
        height:40px;
        text-align:center;
        line-height: 40px;
        position: absolute;
        left:10px;
    }
    .sst{
        display:inline-block;
        position: absolute;
        right:10px;
         line-height: 40px;
         &.f-sst{
            color:#FF0000;
         }
         &.has-kimarite{
             right:80px;
             font-size:13px;
             font-weight: bold;
         }
    }
    .kimarite{
        display:inline-block;
        position: absolute;
        font-size:12px;
        right:10px;
        line-height: 40px;
        font-weight: bold;
    }
}


.boat-icon{
  display:inline-block;
  width:60px;
  height:25px;
  position:absolute;
  margin-top:10px;
}


.shinnyuzu{
  background-image: linear-gradient(180deg, #35C2FF 0%, #A9F1FF 100%);
  //margin-top:-14px;
  min-height:350px;
  padding:15px 0;
  position: relative;;
  overflow: hidden;
  &::before{
      position:absolute;
      display: block;
      content:'';
      width:1px;
      height:100%;
      top:0;
      left:calc(50% + 60px);
      border-left:2px solid #ffc548;
  }
}
</style>