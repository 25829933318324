<template>
<div>
<div class="hero-section">
  <div class="hero-section-text">
       <h1><img src="/images/icon_error.svg" alt="" /><br />情報が見つかりません</h1>
  </div>
</div>
<div class="site-description">
 <p>お探しのページは削除されたか、名前が変更されたか、一時的に利用できない可能性があります。</p>
</div>

</div>




</template>
<script>

export default {
   name :'NotFound',
   head:function(){
    return {
      title: function(){
        return {
          inner: 'Error ページ、または情報見つかりません',
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
}
</script>

<style scoped>
 .hero-section{
    background-image: none;
    background-image: linear-gradient(180deg, #F0F8FF 0%, #FFFFFF 100%);
}
.hero-section h1{
    font-size:rem-calc(14);
}
.hero-section  img{
    height:auto;
    margin:20px;
}
.site-description{
  padding:20px;
  word-break: break-all;
}
</style>