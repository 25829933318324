<template>
<div class="user-my-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> ユーザー {{ $store.getters.user != null ? $store.getters.user.nickname :'' }}
    </li>
  </ul>
</nav>
  <div class="register-form">
  <h1 v-if="$store.getters.user != null">{{ $store.getters.user.nickname }} さん</h1>

  <div class="callout user-info mail">
  <h2 class="user-info__title">メールアドレス</h2>

  {{ $store.getters.user != null ? $store.getters.user.email:'' }}
 </div>

  <div class="callout user-info nickname">
  <h2 class="user-info__title">ニックネーム</h2>
   <router-link :to="{name:'UserChAttr'}" class="button ch-button" aria-label="ニックネーム変更">変更</router-link> 
  {{ $store.getters.user != null ? $store.getters.user.nickname :'' }}
 </div>

 <div class="callout user-info">
  <h2 class="user-info__title">パスワード</h2>
  <router-link :to="{name:'UserChPassword'}" class="button ch-button" aria-label="パスワード変更">変更</router-link> 
  ***********
 </div>

  </div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserMyPage',
  mixins: [pianoSend],
  data:function(){
    return{
      mail:'',
      password:'',
      title:'マイページ'
    }
  },

  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },

  created:function(){
    if(this.$store.getters.user){
      this.$data.title = this.$store.getters.user.nickname 
      this.$emit('updateHead');
    }
    this.pianoSend('ユーザーページ');

  },
 
  methods: {
    
  }
}
</script>