<template>
<div>
<div class="hero-section">
  <div class="hero-section-text">
    <h1>ボートナビの使い方</h1>
  </div>
</div>
<div class="site-description">
 <div class="site-description-container">
<h2>◆このサイトについて</h2>
<p>株式会社報知新聞社（以下「当社」といいます）が運営するボートレース専門サイト「BOATNAVI 報知（ボートナビ報知）」（以下「本サイト」といいます）は、本サイトを利用する皆様（以下「ユーザー」といいます）が勝舟投票券を購入する際の情報を提供することを目的とし、勝舟投票券の購入には関与いたしません。また、提供した情報によってユーザーが何らかの損害を被っても一切の責任は負いません。なお、本サイトに掲載している記事等のコンテンツに関する著作権は当社に帰属し、無断での転載、コピー、公衆送信などの行為は禁止します。</p>
<h2>◆出走表</h2>
<h3>〇出走表データ</h3>
<p>予想に必要なデータが一目で分かる出走表です。ボートナビＡＩが算出したＡＩ予想もご覧頂けます。</p>
<h3>〇直前データ</h3>
<p>ボートの予想は直前データで決まる！展示タイム、進入、エンジンの状態などの直前データをレースの予想に役立たせることができます。前日予想から上昇している選手は「買い」です。</p>
<h3>〇報知スコープ</h3>
<p>「選手」「エンジン」「コース」「展開」の４つのファクターを数値してグラフ化。１目で各選手の総合力が把握できます。レース前日と当日（展示航走後）2回更新。当日のデータのグラフが前日よりも大きくなっている場合、３着以内に入る可能性が高いことを示しています。</p>

<h2>◆推奨環境</h2>
<h3>〇スマートフォン・タブレット</h3>
<h4>☆Android</h4>
<p>OS：Android4.4以降<br />
ブラウザ：Google Chrome（最新バージョン）</p>
<h4>☆iOS</h4>
<p>OS：iOS10.1以降<br />
ブラウザ：Safari</p>
<h3>〇ＰＣ</h3>
<p>☆ブラウザ<br />
Google Chrome（最新バージョン）<br />
FireFox （最新バージョン）<br />
Edge（最新バージョン）<br />
Safari10.1以降<br />
※推奨環境以外でご覧いただいた場合、本サイトがご利用できない、もしくは正しく表示されない可能性がございます。</p>

<p>※正しくご利用いただくため、下記項目を設定していただくことをおすすめいたします。
スタイルシートを有効にする<br />
・JavaScript を有効にする<br />
・Cookie 利用を有効にする<br />
</p>

<p>推奨ブラウザのアップデート / インストールについては、各ブラウザダウンロードサイトにて手順をご確認ください。</p>

<p>Google Chrome ダウンロードサイト<br />
<a href="https://www.google.co.jp/chrome/browser/desktop/index.html" target="_blank">https://www.google.co.jp/chrome/browser/desktop/index.html</a></p>
</div>
</div>



</div>
</template>
<script>

export default {
   name :'HowToPage',
   head:function(){
    return {
      title: function(){
        return {
          inner: 'ボートナビの使い方',
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created:function(){
     this.pianoSend('ボートナビの使い方');

  },
  mounted () {
    this.$emit('updateHead');
},
}
</script>

<style scoped>

.site-description{
  padding:20px;
  background-image: linear-gradient(180deg, #F0F8FF 0%, #FFFFFF 10%);
  word-break: break-all;
}
.site-description-container{
  max-width:800px;
  margin-top:30px;
  margin-left:auto;
  margin-right:auto;
}
.hero-section{
    background-image:linear-gradient(180deg, rgba(238,238,238,0.00) 0%, rgba(240,240,240,0.11) 0%, rgba(239,239,239,0.05) 0%, rgba(247,247,247,0.51) 16%, rgba(251,251,251,0.76) 37%, #F0F8FF 100%), url(/images/bg_hero2.jpg);
}
.hero-section-text h1{
    color:rgb(0, 5, 77);
}

</style>