<template>
<div class="user-register-page">

<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> ログイン
    </li>
  </ul>
</nav>
  <div class="register-form">
  <h1>{{ title }}</h1>
  <div v-show="error_message != ''" class="callout alert">
      <p>{{ error_message }}</p>
  </div>
  <form @submit.prevent="login">
  
  <label>メールアドレス
  <input type="email" placeholder="メールアドレス" v-model="mail" required>
  </label>

  <label>パスワード
  <input type="password" v-model="password" required>
  </label>

  <button class="button" type="submit">ログイン</button>
  </form>
  <p><router-link :to="{name:'UserForgetPassword'}">▶︎パスワードを忘れた方はこちら</router-link><br />
  <router-link :to="{name:'UserRegister'}">▶︎新規登録はこちら</router-link></p>
  </div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name:'UserLogin',
  mixins: [pianoSend],
  data:function(){
    return{
      mail:'',
      password:'',
      title:'ログイン',
      error_message:""
    }
  },
  head:function(){
    return {
      title: function(){
        return {
          inner: this.$data.title,
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
    mounted() {
     this.$emit('updateHead');
    },


  created:function(){
    this.$emit('updateHead');
    this.pianoSend('ログイン');
  },
 
  methods: {
    login:function(){
      let loader = this.$loading.show({});

      this.$cognito.login(this.$data.mail,this.$data.password).then((result)=>{ // eslint-disable-line
          //console.log(result);
          loader.hide();
          let url = this.$route.query.redirect;
          if (url) {
              this.$router.replace(url)
            }else {
              this.$router.replace('/')
           }
      }).catch((err) => { // eslint-disable-line
          
          loader.hide();
          if(err.name == "NotAuthorizedException"){
            this.$data.error_message = 'メールアドレスとパスワードが一致しません。';
          }else{
            this.$data.error_message = 'ログインを失敗しました。';
          }
      })
    }
  }
}
</script>

<style scoped>
.register-form{
  max-width:600px;
  margin:40px auto;
  padding:20px;
}
</style>